export default (data) => {
  const returnData = JSON.parse(JSON.stringify(data))

  // 增加 custom markline 設定
  returnData.settings.editModeData.dashboards.forEach((dashboard) => {
    dashboard.components.forEach((component) => {
      component.displayConfig.customMarkLine = []
    })
  })

  return returnData
}
