import { render, staticRenderFns } from "./AppSideNav.vue?vue&type=template&id=44b68487&scoped=true&"
import script from "./AppSideNav.vue?vue&type=script&lang=js&"
export * from "./AppSideNav.vue?vue&type=script&lang=js&"
import style0 from "./AppSideNav.vue?vue&type=style&index=0&id=44b68487&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44b68487",
  null
  
)

export default component.exports