import { getCurrentInstance, inject, computed } from '@vue/composition-api'

export function useValidator () {
  const vm = getCurrentInstance()
  if (!vm) throw new Error('must be called in setup')

  const validator = inject('$validator')
  vm.proxy.$validator = validator

  return vm.proxy.$validator
}

export function useValidatorWithErrors () {
  const validator = useValidator()
  const errors = computed(() => validator.errors)
  return {
    validator,
    errors
  }
}
