
import Vue from 'vue'
import i18n from './lang/index.js'

import {
  Button,
  DatePicker,
  TimeSelect,
  Popover,
  InputNumber,
  Checkbox,
  Input,
  Select,
  Option,
  Table,
  Autocomplete,
  TableColumn,
  Loading,
  Tabs,
  TabPane,
  Pagination,
  Collapse,
  CollapseItem,
  Tooltip,
  Switch,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Badge,
  Cascader,
  Backtop,
  Progress
} from 'element-ui'
// 針對 on-demand 掛載 element ui 元件情境下設定語系用
import ElementLocale from 'element-ui/lib/locale'

// Element UI components
Vue.use(Button)
Vue.use(DatePicker)
Vue.use(TimeSelect)
Vue.use(Popover)
Vue.use(InputNumber)
Vue.use(Checkbox)
Vue.use(Input)
Vue.use(Select)
Vue.use(Option)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Autocomplete)
Vue.use(Loading)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Pagination)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Tooltip)
Vue.use(Switch)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Badge)
Vue.use(Cascader)
Vue.use(Backtop)
Vue.use(Progress)

// element ui 帶入 i18n 語系
// REF: https://element.eleme.io/#/en-US/component/i18n#custom-i18n-in-on-demand-components
ElementLocale.i18n((key, value) => i18n.t(key, value))
