<template>
  <div
    :class="{ 'simulator__predict': isModelPredict }"
    class="simulator"
  >
    <spinner v-if="isLoading" />
    <empty-info-block
      v-else-if="isFetchInputFailed || isModelDepreciated"
      :msg="isFetchInputFailed ? (failedMessage || $t('message.systemIsError')) : $t('message.modelNotFoundPleaseReset')"
    />
    <section
      v-show="!isLoading && !isFetchInputFailed && !isModelDepreciated"
      class="simulator__content"
    >
      <form
        :data-vv-scope="'simulator-' + simulatorId"
        class="simulator__setting-container"
        @submit.prevent="simulate"
      >
        <div class="simulator__setting-container--top">
          <div class="simulator__setting">
            <div class="simulator__setting-title">
              {{ $t('miniApp.simulationParamSetting') }}
            </div>
            <div class="simulator__setting-content">
              <template v-if="modelSetting.type === 'dataFrame'">
                <div
                  v-for="(dataframeInfo, modelIndex) in modelInfo"
                  :key="modelIndex + '-' + dataframeInfo.dataFrameId"
                >
                  <div class="option__title">
                    {{ dataframeInfo.dataFrameName }}
                  </div>
                  <simulator-input
                    v-for="(columnInfo, columnIndex) in dataframeInfo.columnList"
                    :key="columnIndex + '-' + columnInfo.columnId"
                    :is-processing="isProcessing"
                    :restrictions="restrictions"
                    :column-info="columnInfo"
                    :model-id="modelSetting.modelId"
                    :simulator-id="simulatorId"
                    class="simulator__setting-input option__content"
                    @done="updateColumnInfoState(modelIndex, columnIndex)"
                    @failed="handleFetchInputFailed"
                  />
                </div>
              </template>
              <template v-else>
                <simulator-input
                  v-for="(columnInfo, modelIndex) in modelInfo"
                  :key="modelIndex + '-' + columnInfo.columnId"
                  :is-processing="isProcessing"
                  :restrictions="restrictions"
                  :column-info="columnInfo"
                  :model-id="modelSetting.modelId"
                  :simulator-id="simulatorId"
                  class="simulator__setting-input"
                  @done="updateColumnInfoState(modelIndex)"
                  @failed="handleFetchInputFailed"
                />
              </template>
            </div>
          </div>
        </div>
        <div class="simulator__setting-container--bottom">
          <button
            :disabled="isProcessing"
            type="submit"
            class="btn-m btn-default btn-simulate"
          >
            {{ $t('miniApp.startSimulating') }}
          </button>
        </div>
      </form>
      <div class="simulator__result">
        <div
          v-if="!resultList && !outputFile"
          class="simulator__default-message"
        >
          <span v-if="!isProcessing">{{ $t('miniApp.notYetSimulate') }}</span>
          <spinner
            v-else
            :title="$t('miniApp.simulating')"
          />
        </div>
        <template v-else>
          <el-tabs
            v-model="activeTabName"
            class="simulator__result-tab"
            type="card"
          >
            <el-tab-pane
              :label="$t('miniApp.simulationResult')"
              :name="$t('miniApp.simulationResult')"
            >
              <spinner
                v-if="isProcessing"
                :title="$t('miniApp.simulating')"
              />
              <div
                v-else-if="isSimulateFailed"
                class="simulator__default-message"
              >
                {{ failedMessage || $t('message.systemIsError') }}
              </div>
              <div
                v-else
                class="simulator__result-panel"
              >
                <div
                  v-if="outputFile"
                  class="simulator__default-message"
                >
                  {{ $t('miniApp.dataSimulationDownloadFinish') }}
                </div>
                <div
                  v-else
                  class="simulator__result-card"
                >
                  <div
                    v-for="(result, index) in resultList"
                    :key="index"
                    class="item"
                  >
                    <div class="item__label">
                      {{ result.name }}
                    </div>
                    <div
                      class="item__value"
                      v-html="isNaN(roundNumber(result.value, 3)) ? lineBreak(result.value) : roundNumber(result.value, 3)"
                    />
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <!-- 下次再加 -->
            <!-- <el-tab-pane
              :label="$t('miniApp.savedRecord')"
              :name="$t('miniApp.savedRecord')">
              <div class="simulator__record-panel">
                {{ $t('miniApp.savedRecord') }}
              </div>
            </el-tab-pane> -->
          </el-tabs>
        </template>
      </div>
    </section>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, watch } from '@vue/composition-api'
import { useMapActions } from '@/utils/composable/vuex'
import DefaultSelect from '@/components/select/DefaultSelect'
import EmptyInfoBlock from '@/components/EmptyInfoBlock'
import SimulatorInput from './SimulatorInput'
import { getDataFrameColumnInfoById } from '@/API/DataSource'
import { modelSimulate } from '@/API/Model'
import { v4 as uuidv4 } from 'uuid'
import { useI18n } from '@/utils/composable/i18n'
import { useValidator } from '@/utils/composable/validator'

export default defineComponent({
  inject: ['$validator'],
  name: 'Simulator',
  components: {
    DefaultSelect,
    EmptyInfoBlock,
    SimulatorInput
  },
  props: {
    isEditMode: {
      type: Boolean,
      default: false
    },
    restrictions: {
      type: Array,
      default: () => []
    },
    modelSetting: {
      type: Object,
      default: () => ({})
    },
    isModelPredict: {
      type: Boolean,
      default: false
    },
    dataColumnMap: {
      type: Object,
      default: () => ({})
    }
  },
  setup (props, { emit }) {
    const { t } = useI18n()

    // ----- general -----
    const isLoading = ref(false)
    const isModelDepreciated = ref(false)
    const activeTabName = t('miniApp.simulationResult')

    // ---- modelInfo -----
    const modelInfo = ref(null)

    watch(
      () => modelInfo,
      (newList) => {
        const isAllInit = newList.value.every(column => column.isInit)
        if (!isAllInit) return
        isLoading.value = false
      }, {
        deep: true
      }
    )

    const getModelInfo = () => {
      isLoading.value = true
      if (!props.isModelPredict) {
        modelInfo.value = props.modelSetting.inputList.map(column => ({
          ...column,
          columnId: column.dcId,
          isInit: false,
          userInput: ''
        }))
      } else {
        modelInfo.value = props.modelSetting.ioArgs.input.map(input => ({
          statsType: input.statsType,
          originalName: input.modelColumnName,
          columnId: props.dataColumnMap[Object.keys(props.dataColumnMap).find(key => props.dataColumnMap[key].primary_alias === input.modelColumnName)].id,
          isInit: false,
          userInput: ''
        }))
      }
    }

    const getMultiModelInfo = async () => {
      const hasFeatureColumn = true
      const hasBlockClustering = false
      const hasAliasLimit = false
      isLoading.value = true
      modelInfo.value = await Promise.all(
        props.modelSetting.inputList
          .filter((input) => input.paramType === 'SIMULATE')
          .map(async (input) => {
            const dataColumnList = await getDataFrameColumnInfoById(input.id, hasFeatureColumn, hasAliasLimit, hasBlockClustering)
            return {
              dataFrameId: input.id,
              dataFrameName: input.dataFrameName,
              columnList: dataColumnList.map((column) => ({
                ...column,
                name: `${column.primaryAlias || column.name}（${column.statsType}）`,
                value: column.id,
                originalName: column.primaryAlias || column.name,
                dcId: column.id,
                columnId: column.id
              }))
            }
          })
      )
    }

    const updateColumnInfoState = (modelIndex, columnIndex) => {
      if (columnIndex) {
        modelInfo.value[modelIndex].columnList = modelInfo.value[modelIndex].columnList.map((column, currentIndex) => {
          if (columnIndex !== currentIndex) return column
          return {
            ...column,
            isInit: true
          }
        })
        modelInfo.value[modelIndex].columnList[columnIndex].isInit = true
      } else {
        modelInfo.value = modelInfo.value.map((input, currentIndex) => {
          if (modelIndex !== currentIndex) return input
          return {
            ...input,
            isInit: true
          }
        })
        modelInfo.value[modelIndex].isInit = true
      }
    }

    // ----- hooks -----
    onMounted(() => {
      if (props.modelSetting.modelId) {
        switch (props.modelSetting.type) {
          case 'column':
            getModelInfo()
            break
          case 'dataFrame':
            getMultiModelInfo()
            break
          default:
            // 注意模型預測問句沒有 type
            getModelInfo()
            break
        }
      } else {
        isModelDepreciated.value = true
      }
    })

    // ----- fail handler -----
    const isFetchInputFailed = ref(false)
    const failedMessage = ref(null)
    const handleFetchInputFailed = (message) => {
      isLoading.value = false
      isFetchInputFailed.value = true
      if (!failedMessage.value) failedMessage.value = message
    }

    // ---- simulate -----
    const validator = useValidator()
    const simulatorId = uuidv4()
    const isSimulateFailed = ref(false)
    const isProcessing = ref(false)
    const resultList = ref(null)
    const outputFile = ref(null)

    function base64ToArrayBuffer (base64) {
      let binaryString = window.atob(base64)
      let binaryLen = binaryString.length
      let bytes = new Uint8Array(binaryLen)
      for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i)
        bytes[i] = ascii
      }
      return bytes
    }

    const downloadFile = (file) => {
      if (!file) return
      const bytes = base64ToArrayBuffer(file)
      const blob = new Blob([bytes], { type: 'application/zip' })
      const blobUrl = URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = blobUrl
      link.download = 'output_dataFrames.zip'
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }

    const simulate = () => {
      let columnList
      const isMultiDataFrameModel = props.modelSetting.type === 'dataFrame'
      if (isMultiDataFrameModel) {
        columnList = []
        modelInfo.value.forEach((dataFrame) => {
          columnList = [...columnList, ...dataFrame.columnList]
        })
      }
      validator.validateAll(`simulator-${simulatorId}`).then(result => {
        if (!result) return
        // user tracking
        const { trackPageFunctionClick } = useMapActions('gtm', ['trackPageFunctionClick'])
        trackPageFunctionClick('use-simulator')
        isSimulateFailed.value = false
        isProcessing.value = true
        modelSimulate(props.modelSetting.modelId, {
          inputValues: (columnList || modelInfo.value).map(column => column.userInput),
          algoInputValues: (columnList || modelInfo.value).map(column => column.userInput),
          dataFrameIds: isMultiDataFrameModel ? props.modelSetting.inputList.map(dataFrame => dataFrame.id) : [],
          dataColumnIds: (columnList || modelInfo.value).map(column => column.columnId)
        })
          .then(response => {
            downloadFile(response.outputFile)
            outputFile.value = response.outputFile
            if (response.outputPrimaryAlias) {
              resultList.value = response.outputPrimaryAlias.map((element, index) => {
                return {
                  name: element,
                  value: response.outputValues[index]
                }
              })
            }
          })
          .catch((err) => {
            isSimulateFailed.value = true
            if (!failedMessage.value) failedMessage.value = err?.error?.message || null
          })
          .finally(() => { isProcessing.value = false })
      })
    }

    return {
      // ----- general -----
      isLoading,
      isModelDepreciated,
      activeTabName,
      // ----- modelInfo -----
      modelInfo,
      updateColumnInfoState,
      // ----- fail handler -----
      isFetchInputFailed,
      failedMessage,
      handleFetchInputFailed,
      // ----- simulator -----
      isProcessing,
      resultList,
      outputFile,
      isSimulateFailed,
      simulatorId,
      simulate
    }
  }
})
</script>

<style lang="scss" scoped>
.simulator {
  &__predict {
    height: 100%;
  }

  &__result {
    ::v-deep .spinner-block {
      flex: 1;
    }
  }

  &__result-card {
    align-content: stretch;
    background: #475353;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    padding: 16px;
    width: 100%;
  }

  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 60px;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    &__label {
      color: #aaa;
      font-size: 12px;
      font-weight: 600;
      line-height: 17px;
      text-align: center;
    }

    &__value {
      font-size: 30px;
      line-height: 42px;
      text-align: center;
    }
  }

  .option {
    &__title {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 5px;
    }
    &__content {
      margin-left: 20px;
    }
  }
}
</style>
