export default (data) => {
  const returnData = JSON.parse(JSON.stringify(data))

  // isPublished
  returnData.settings.isPublished = returnData.settings.editModeData.isPublishing
  delete returnData.settings.editModeData.isPublishing

  const settingsArr = ['editModeData', 'viewModeData']
  settingsArr.forEach((key) => {
    if (!returnData.settings?.[key]) return

    // config
    returnData.settings[key].name = returnData.settings[key].displayedName
    delete returnData.settings[key].displayedName

    returnData.settings[key].config = {
      timeZone: returnData.settings[key]?.timeZone ?? 'Asia/Taipei',
      isSideNavPin: returnData.settings[key]?.isSideNavPin ?? false
    }
    delete returnData.settings[key].timeZone
    delete returnData.settings[key].isSideNavPin

    // conditions
    returnData.settings[key].warningModule.conditions.forEach((item) => {
      item.conditionId = item.id
      delete item.id
    })

    // dashboards
    returnData.settings[key].dashboards.forEach((dashboard) => {
      dashboard.uuid = dashboard.id
      delete dashboard.id

      // controlList
      dashboard.controlList = dashboard.controlList.map((controlGroup) => {
        return controlGroup.map((control) => {
          const newObj = {}
          newObj.uuid = control.filterId

          newObj.source = {
            dataSourceId: control.dataSourceId,
            dataFrameId: control.dataFrameId
          }

          newObj.column = {
            columnId: control.columnId,
            name: control.columnName,
            type: control.statsType
          }

          newObj.optionValues = control.dataValues
          return newObj
        })
      })

      // filterList
      dashboard.filterList = dashboard.filterList.map((filterGroup) => {
        const abc = filterGroup.map((filter) => {
          const newObj = {}
          newObj.uuid = filter.filterId

          switch (filter.statsType) {
            case 'RELATIVEDATETIME':
              newObj.column = {
                columnId: filter.columnId,
                name: filter.columnName,
                type: filter.statsType
              }

              newObj.optionValues = filter.dataValues
              break
            case 'CATEGORY':
            case 'BOOLEAN':
              newObj.source = {
                dataSourceId: filter.dataSourceId,
                dataFrameId: filter.dataFrameId
              }

              newObj.column = {
                columnId: filter.columnId,
                name: filter.columnName,
                type: filter.statsType
              }

              newObj.optionValues = filter.dataValues
              break
            case 'NUMERIC':
            case 'DATETIME':
              newObj.source = {
                dataSourceId: filter.dataSourceId,
                dataFrameId: filter.dataFrameId
              }

              newObj.column = {
                columnId: filter.columnId,
                name: filter.columnName,
                type: filter.statsType
              }

              newObj.valueRange = {
                end: filter.end,
                start: filter.start
              }
              break
            default:
              return filter
          }
          return newObj
        })
        return abc
      })

      // yAxisControlList
      dashboard.yAxisControlList = dashboard.yAxisControlList.map((yAxisControlGroup) => {
        return {
          activeColumnId: yAxisControlGroup.filter((yAxisControl) => yAxisControl?.isSelected)?.[0]?.columnId,
          options: yAxisControlGroup.map((yAxisControl) => ({
            column: {
              columnId: yAxisControl.columnId,
              name: yAxisControl.columnName,
              type: yAxisControl.dataType
            },
            source: {
              dataSourceId: yAxisControl.dataSourceId,
              dataFrameId: yAxisControl.dataFrameId
            }
          }))
        }
      })

      // components
      dashboard.components = dashboard.components.map((component) => {
        const newObj = {}

        newObj.uuid = component.id
        newObj.type = component.type
        newObj.diagram = component.diagram
        newObj.updateTime = component.updateTime

        switch (component.type) {
          case 'abnormal-statistics':
            newObj.displayConfig = {
              fontSize: component.config?.fontSize,
              title: {
                isSyncQuestion: component.config.isTitleSyncing,
                name: component.config.diaplayedName
              },
              position: component.position,
              unit: component?.indexInfo?.unit
            }

            newObj.settingConfig = {
              related: {
                dashboard: {
                  active: component.config.hasRelatedDashboard,
                  dashboardId: component.config.relatedDashboard?.id,
                  name: component.config.relatedDashboard?.name
                },
                dateTimeColumn: {
                  columnId: component?.dateTimeColumn?.dataColumnId ?? null,
                  primaryAlias: component?.dateTimeColumn?.dataColumnPrimaryAlias ?? null
                }
              }
            }
            break
          case 'formula':
            newObj.source = {
              dataSourceId: component.formulaSetting?.dataSourceId,
              dataFrameId: component.formulaSetting?.dataFrameId
            }

            newObj.displayConfig = {
              fontSize: component.config?.fontSize,
              title: {
                isSyncQuestion: component.config.isTitleSyncing,
                name: component.config.diaplayedName
              },
              position: component.position,
              unit: component?.indexInfo?.unit
            }

            newObj.settingConfig = {
              refresh: {
                isAuto: component.config?.isAutoRefresh,
                refreshFrequency: component.config?.refreshFrequency
              },
              related: {
                dashboard: {
                  active: component.config.hasRelatedDashboard,
                  dashboardId: component.config.relatedDashboard?.id,
                  name: component.config.relatedDashboard?.name
                }
              },
              dateTimeColumn: {
                columnId: component?.dateTimeColumn?.dataColumnId ?? null,
                primaryAlias: component?.dateTimeColumn?.dataColumnPrimaryAlias ?? null
              }
            }

            newObj.formulaSetting = {
              formulaId: component.formulaSetting?.formulaId,
              inputList: component.formulaSetting?.inputList,
              displayedFormula: component.formulaSetting?.displayedFormula
            }

            newObj.questionConfig = {
              keyResultId: component?.keyResultId ?? null
            }

            break
          case 'simulator':
          case 'parameters-optimized-simulator':
            newObj.source = {
              dataSourceId: component.modelSetting?.dataSourceId,
              dataFrameId: component.modelSetting?.dataFrameId
            }

            newObj.displayConfig = {
              fontSize: component.config?.fontSize,
              title: {
                isSyncQuestion: component.config.isTitleSyncing,
                name: component.config.diaplayedName
              },
              position: component.position,
              unit: component?.indexInfo?.unit
            }

            newObj.settingConfig = {
              related: {
                dashboard: {
                  active: component.config?.hasRelatedDashboard,
                  dashboardId: component.config?.relatedDashboard?.id,
                  name: component.config?.relatedDashboard?.name
                }
              },
              dateTimeColumn: {
                columnId: component?.dateTimeColumn?.dataColumnId ?? null,
                primaryAlias: component?.dateTimeColumn?.dataColumnPrimaryAlias ?? null
              }
            }

            newObj.modelSetting = {
              modelId: component?.modelSetting?.modelId,
              inputList: component?.modelSetting?.inputList
            }
            break
          case 'chart':
          case 'text':
          case 'index':
          case 'monitor-warning-list':
            newObj.source = {
              dataSourceId: component.dataSourceId,
              dataFrameId: component.dataFrameId
            }

            newObj.displayConfig = {
              fontSize: component.config?.fontSize,
              title: {
                isSyncQuestion: component.config.isTitleSyncing,
                name: component.config.diaplayedName
              },
              position: component.position,
              unit: component?.indexInfo?.unit,
              anomalySettings: component.anomalySettings
            }

            newObj.settingConfig = {
              refresh: {
                isAuto: component.config.isAutoRefresh,
                refreshFrequency: component.config.refreshFrequency
              },
              related: {
                dashboard: {
                  active: component.config?.hasRelatedDashboard,
                  dashboardId: component.config?.relatedDashboard?.id ?? null,
                  name: component.config?.relatedDashboard?.name ?? null
                },
                ...((component.diagram === 'table') && {
                  table: {
                    active: component.config?.hasTableRelatedDashboard,
                    triggerTarget: component.config?.tableRelationInfo?.triggerTarget ?? null,
                    dashboardId: component.config?.tableRelationInfo?.triggerTarget === 'row'
                      ? component.config.tableRelationInfo?.rowRelation?.relatedDashboardId ?? null
                      : component.config.tableRelationInfo?.columnRelations?.[0]?.relatedDashboardId ?? null,
                    column: {
                      columnId: component.config?.tableRelationInfo?.columnRelations?.[0].columnInfo?.columnId ?? null,
                      columnAlias: component.config?.tableRelationInfo?.columnRelations?.[0].columnInfo?.columnAlias ?? null
                    }
                  }
                })
              },
              dateTimeColumn: {
                columnId: component?.dateTimeColumn?.dataColumnId ?? null,
                primaryAlias: component?.dateTimeColumn?.dataColumnPrimaryAlias ?? null
              }
            }

            newObj.questionConfig = {
              parserLanguage: component.parserLanguage,
              question: component.question,
              questionId: component.questionId,
              keyResultId: component?.keyResultId ?? null,
              segmentation: component?.segmentation,
              dataColumns: component?.dataColumns,
              resultId: component?.resultId,
              algoConfig: component?.algoConfig,
              advanced: component?.advanced
            }

            newObj.flags = {
              isTextTypeAvailable: component.isTextTypeAvailable ?? false,
              isIndexTypeAvailable: component.isIndexTypeAvailable ?? false
            }
            break
          default:
            return component
        }

        return newObj
      })
    })
  })

  return returnData
}
