import Vue from 'vue'
import Vuex from 'vuex'
import dataSource from './modules/dataSource'
import pinboard from './modules/pinboard'
import dataManagement from './modules/dataManagement'
import modelManagement from './modules/modelManagement'
import modelFlowManagement from './modules/modelFlowManagement'
import result from './modules/result'
import setting from './modules/setting'
import userManagement from './modules/userManagement'
import algorithm from './modules/algorithm'
import validation from './modules/validation'
import previewDataSource from './modules/previewDataSource'
import scheduleSetting from '@/schedule/store/modules/scheduleSetting'
import simulation from '@/schedule/store/modules/simulation'
import credentials from './modules/credentials'
import jobAdjustments from '@/schedule/store/modules/jobAdjustments'
import gtm from './modules/gtm'

// new module
import { ANALYSIS_MODULE_NAMESPACE, analysisModuleStore } from '@/modules/analysis'
import { DATA_MANAGEMENT_MODULE_NAMESPACE, dataManagementModuleStore } from '@/modules/dataManagement'
import { PIN_BOARD_MODULE_NAMESPACE, pinBoardModuleStore } from '@/modules/pinBoard'
import { WAR_ROOM_MODULE_NAMESPACE, warRoomModuleStore } from '@/modules/warRoom'
import { MINI_APP_MODULE_NAMESPACE, miniAppModuleStore } from '@/modules/miniApp'
import { MODEL_MODULE_NAMESPACE, modelModuleStore } from '@/modules/modelManagement'
import { AUTO_ML_MODULE_NAMESPACE, autoMLModuleStore } from '@/modules/autoML'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    dataSource,
    pinboard,
    dataManagement,
    modelManagement,
    modelFlowManagement,
    setting,
    userManagement,
    result,
    algorithm,
    validation,
    previewDataSource,
    scheduleSetting,
    simulation,
    credentials,
    jobAdjustments,
    gtm,
    // new module
    [ANALYSIS_MODULE_NAMESPACE]: analysisModuleStore,
    [DATA_MANAGEMENT_MODULE_NAMESPACE]: dataManagementModuleStore,
    [PIN_BOARD_MODULE_NAMESPACE]: pinBoardModuleStore,
    [MINI_APP_MODULE_NAMESPACE]: miniAppModuleStore,
    [WAR_ROOM_MODULE_NAMESPACE]: warRoomModuleStore,
    [MODEL_MODULE_NAMESPACE]: modelModuleStore,
    [AUTO_ML_MODULE_NAMESPACE]: autoMLModuleStore
  },
  state: {
    isShowAskHelper: false,
    isShowFullSideNav: false,
    isAppLoading: false,
    isRouteLoading: false,
    isGuideMode: false
  },
  mutations: {
    updateAskHelperStatus (state, data) {
      state.isShowAskHelper = data
    },
    updateSideNavStatus (state, data) {
      state.isShowFullSideNav = data
    },
    updateAppLoadingStatus (state, data) {
      state.isAppLoading = data
    },
    updateRouteLoadingStatus (state, data) {
      state.isRouteLoading = data
    },
    SET_IS_GUIDE_MODE (state, data) {
      state.isGuideMode = data
    }
  },
  actions: {
    setIsGuideMode ({ commit }, data) {
      commit('SET_IS_GUIDE_MODE', data)
    }
  }
})

export default store
