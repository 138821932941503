<template>
  <div class="result-board-body">
    <div
      :class="{ 'is-open': isShowSettingBox }"
      class="key-result-container"
    >
      <button
        v-if="$slots.InsightBasicInfo"
        v-show="hasBasicInfo"
        :class="{ active: showBasicInfo }"
        type="button"
        class="btn-m btn-secondary control-btn"
        @click.stop="toggleInfoDialog();trackUserClick('result_chartBasicInfo')"
      >
        {{ $t('resultDescription.basicInfo') }}
      </button>
      <div
        v-if="$slots.multiAnalyPanel"
        class="multi-analysis__block"
      >
        <slot name="multiAnalyPanel" />
      </div>
      <div class="chart-block">
        <slot name="PageResultBoardIndicator" />
        <slot name="PageResultBoardChart" />
        <div
          v-if="$slots.ModelPredict"
          class="model-container"
        >
          <slot name="ModelPredict" />
        </div>

        <div
          v-if="$slots.ModelSimulator"
          class="model-container"
        >
          <slot name="ModelSimulator" />
        </div>
      </div>
      <slot-dialog
        v-if="$slots.InsightBasicInfo"
        v-show="showBasicInfo && hasBasicInfo"
        :show="showBasicInfo"
        class="basic-info-container"
        @close="closeDialog"
      >
        <template slot="SlotDialog">
          <slot name="InsightBasicInfo" />
        </template>
      </slot-dialog>
    </div>
    <div
      v-if="$slots.ModelExplanation"
      class="model-explanation"
    >
      <slot name="ModelExplanation" />
    </div>
    <div
      v-if="$slots.InsightRootCause || $slots.InsightRecommended || $slots.CorrelationAnalysis"
      class="other-result-container"
    >
      <slot name="InsightRootCause" />
      <div
        v-if="$slots.InsightRecommended"
        name="InsightRecommended"
      >
        <div class="insights-info recommended">
          <div class="insights-info-title">
            {{ $t('resultDescription.recommendedInsight') }}
          </div>
          <div class="insights-info__wrapper">
            <slot name="InsightRecommended" />
          </div>
        </div>
      </div>
      <slot name="CorrelationAnalysis" />
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import SlotDialog from '@/components/dialog/SlotDialog'
import { defineComponent } from '@vue/composition-api'
import { useAskingModuleContext } from '@/modules/shared/asking'

export default defineComponent({
  name: 'ResultBoardBody',
  components: {
    SlotDialog
  },
  setup () {
    const { hasBasicInfo, isShowSettingBox } = useAskingModuleContext()
    return {
      hasBasicInfo,
      isShowSettingBox
    }
  },
  data () {
    return {
      showBasicInfo: false
    }
  },
  watch: {
    isShowSettingBox (value, oldValue) {
      this.closeDialog()
    }
  },
  methods: {
    ...mapActions('gtm', ['trackUserClick']),
    toggleInfoDialog () {
      this.showBasicInfo = !this.showBasicInfo
    },
    closeDialog () {
      this.showBasicInfo = false
    }
  }
})
</script>
<style lang="scss" scoped>
.result-board-body {
  .key-result-container {
    border-bottom: 1px solid $theme-border-color;
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    width: 100%;

    & ~ [name='InsightRootCause']:not(:last-child),
    & ~ [name='CorrelationAnalysis']:not(:last-child) {
      margin-bottom: 32px;
    }

    .chart-block {
      flex: 1;
      margin-right: 0;
      min-height: 500px;
      min-width: 0;
      padding: 50px 30px 30px;
      width: 100%;
    }

    .general-result {
      &__histogram {
        position: relative;
        top: -20px;
      }
      &__numeric {
        display: flex;
        .diagram {
          flex: 0.7;
        }
        .model-info {
          flex: 0.3;
          margin: 0 0 16px 16px;
        }
      }
    }
    .model-container {
      height: 70vh;
    }
    .basic-info-container {
      background-color: rgba(24, 24, 24, 0.95);
      border: 1px solid $theme-color-primary;
      border-radius: 8px;
      padding: 20px;
      position: absolute;
      right: 30px;
      top: 50px;
      width: 40%;
      z-index: 10000000; // echart 裡面的 tooltip z-index 給 9999999，逼不得已給這麼大

      &::before {
        border: solid transparent;
        border-bottom-color: $theme-color-primary;
        border-width: 10px;
        content: ' ';
        display: block;
        height: 0;
        pointer-events: none;
        position: absolute;
        right: 30px;
        top: -20px;
        width: 0;
      }

    }

    .control-btn {
      padding: 7px 15px;
      position: absolute;
      right: 30px;
      top: 15px;

      &.active {
        background-color: #42a5b3;
      }
    }
  }
  .model-explanation {
    padding: 0 30px;

    &__title {
      font-size: 20px;
      line-height: 34px;
      font-weight: bold;
      margin: 0 0 16px 10px;

      &::before {
        content: '';
        display: inline-block;
        background: #4BC0C8;
        width: 6px;
        height: 6px;
        margin: 10px;
        vertical-align: middle;
      }
    }

    &__factor {
      margin: 0 0 14px 30px;
    }

    &__wrapper {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 30px;
    }
  }

  .other-result-container {
    padding: 30px;

    .task:first-child {
      padding-top: 0;
    }
  }

  .insights-info.recommended {
    .insights-info__wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  .multi-analysis {
    &__block {
      border-right: 1px solid $theme-border-color;
      flex-basis: 180px;
    }

    &__list {
      list-style: none;
      margin: 0;
      padding-left: 0;
      .model-label {
        color: #A7A7A7;
        font-size: 13px;
        padding: 20px 0 0 20px;
      }
    }
    &__switch {
      position: relative;
      display: flex;
      align-items: center;
      color: #DDDDDD;
      font-size: 14px;
      height: 40px;
      padding: 0 0 10px 20px;
      border-bottom: 1px solid $theme-border-color;
    }

    &__item {
      align-items: center;
      border-bottom: 1px solid $theme-border-color;
      color: #a7a7a7;
      cursor: pointer;
      display: flex;
      height: 54px;
      padding-left: 20px;
      position: relative;

      &:hover {
        color: $theme-color-primary;
      }

      &.is-active {
        color: $theme-color-primary;

        &::before {
          background-color: $theme-color-primary;
          content: '';
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 4px;
        }
      }

      ::v-deep .spinner-block {
        padding: 0;
      }

      &-label {
        .svg-icon {
          margin-right: 6px;
        }
      }

      &-status {
        align-items: center;
        cursor: pointer;
        display: flex;
        flex: 1;
        height: 100%;
        justify-content: flex-end;
        padding-right: 20px;

        &:hover {
          .dropdown {
            visibility: visible;
          }
        }

        .trigger-analysis-icon {
          color: $theme-color-primary;
        }

        .more-icon {
          color: #a7a7a7;
        }

        .exclamation-triangle-icon {
          color: $theme-color-danger;
        }
      }

      &-dropdownlist {
        align-items: center;
        cursor: pointer;
        display: flex;
        position: relative;

        ::v-deep .dropdown {
          visibility: hidden;

          .dropdown-select-box {
            left: -20px;
            top: 25px;
            z-index: 1;

            &::before {
              left: 18px;
            }
          }
        }
      }
    }
  }
}

</style>
