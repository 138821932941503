import request from '@/utils/publicRequest'

/**
 * 獲得 Component 資料
 * @param {formData} modelData - 要上傳的 model
 */
export function postComponentData (componentId) {
  return request({
    url: '/Task/componentData',
    method: 'POST',
    data: {
      id: componentId,
      page: 0
    }
  })
}
