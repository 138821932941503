<template>
  <div class="metrics-container">
    <div class="info-block__content confidence">
      <div class="confidence__content">
        <div>
          {{ $t(`modelPerformance.${confidence.label}`) }}
          <el-popover
            :offset="-60"
            width="548"
            trigger="hover"
            placement="bottom-start"
            popper-class="el-popover--training-reminder"
          >
            <svg-icon
              slot="reference"
              style="color: #A7A7A7"
              icon-class="description"
            />
            <metric-content
              :metrics-type="trainingType"
              :display-metric="confidence.label"
            />
          </el-popover>
          <br>
          <span class="confidence__content-subtitle">({{ trainingType === 'Regression' ? 'R-Squared' : 'AUC' }})</span>
        </div>
        <div class="confidence__content-value">
          {{ confidence.value }}
        </div>
      </div>
    </div>
    <div class="info-block__content metrics">
      <div class="group-title">
        {{ $t('modelPerformance.evaluationIndex') }}
      </div>
      <div
        v-for="(metric, index) in metrics"
        :key="`test-${index}`"
        class="metrics--item"
      >
        <div
          v-if="Array.isArray(metric)"
          class="metrics-content"
        >
          <div
            v-for="(item, index) in metric"
            :key="`test-${index}`"
            class="metrics-content__binary-label"
          >
            <div class="display-inline">
              {{ item.label }}
              <el-popover
                :offset="-60"
                width="548"
                trigger="hover"
                placement="bottom-start"
                popper-class="el-popover--training-reminder"
              >
                <svg-icon
                  slot="reference"
                  style="color: #A7A7A7"
                  icon-class="description"
                />
                <metric-content
                  :metrics-type="trainingType"
                  :display-metric="item.label"
                />
              </el-popover>
            </div>
            <div class="metrics-content__binary-value">
              {{ item.value }}
            </div>
          </div>
        </div>
        <div
          v-else
          class="metrics-content"
        >
          <div class="metrics-content__regression-label">
            {{ metric.label }}
            <el-popover
              :offset="-60"
              width="490"
              trigger="hover"
              placement="bottom-start"
              popper-class="el-popover--training-reminder"
            >
              <svg-icon
                slot="reference"
                style="color: #A7A7A7"
                icon-class="description"
              />
              <metric-content
                :metrics-type="trainingType"
                :display-metric="metric.label"
              />
            </el-popover>
          </div>
          <div class="metrics-content__regression-value">
            {{ metric.value }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MetricContent from '@/pages/modelTraining/components/MetricContent'

export default {
  name: 'DisplayMetrics',
  components: {
    MetricContent
  },
  props: {
    info: {
      type: Object,
      required: true
    },
    trainingType: {
      type: String,
      required: true
    }
  },
  computed: {
    metrics () {
      let metricsArray
      if (this.trainingType === 'Regression') {
        metricsArray = ['MSE', 'RMSE', 'MAE']
        return metricsArray.map(metric => {
          return {
            label: metric,
            value: this.autoRound(this.info.metrics[metric])
          }
        })
      }
      metricsArray = Object.keys(this.info.metrics)
      const tempMetrics = metricsArray.map(metric => {
        return [{
          label: metric,
          value: this.autoRound(this.info.metrics[metric])
        }]
      })
      const binaryMetrics = []
      for (let i = 0; i < tempMetrics.length; i += 2) {
        let tmp = tempMetrics[i].concat(tempMetrics[i + 1])
        binaryMetrics.push(tmp)
      }
      return binaryMetrics
    },
    confidence () {
      let confidence, confidenceValue
      switch (this.trainingType) {
        case 'Regression':
          confidence = 'R2'
          confidenceValue = this.info.metrics.R2
          break
        case 'Binary':
          confidence = 'AUC'
          confidenceValue = this.info.metrics.AUC
          break
      }
      return {
        label: confidence,
        value: `${this.roundNumber(confidenceValue * 100)}%`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.metrics-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  .confidence {
    flex: 0.25;
    margin-bottom: 16px;
    &__content {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-subtitle {
        margin-right: 60px;
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0.01em;
        color: #CCCCCC;
      }
      &-value {
        margin-right: auto;
        font-size: 24px;
        font-weight: 600;
        line-height: 49px;
        letter-spacing: 0.01em;
        color: #44D2FF;
        margin-left: 80px;
      }
    }
  }
  .metrics {
    flex: 0.75;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    &--item {
      &:not(:nth-of-type(2)) {
        border-top: 0.628136px solid #52696A;
      }
      .metrics-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 8px;
        &__regression-label {
          width: 100px;
          margin-right: 100px;
          font-size: 13px;
          font-weight: 600;
          letter-spacing: 0.01em;
          color: #cccccc;
        }
        &__binary-label {
          width: 120px;
          font-size: 13px;
          font-weight: 600;
          letter-spacing: 0.01em;
          color: #cccccc;
          &:last-child {
            margin-right: 50px;
          }
        }
        &__regression-value {
          font-size: 14px;
          margin-right: auto;
        }
        &__binary-value {
          color: #FFFFFF;
          font-size: 24px;
          font-weight: 600;
          line-height: 33px;
          letter-spacing: 0.01em;
        }
      }
      .display-inline {
        display: inline;
        vertical-align: middle;
      }
    }
  }
}
</style>
