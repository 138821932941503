export default function (value) {
  if (typeof value === 'string' || value === null) value = Number(value)
  if (parseInt(value) === 0) return parseFloat(value.toPrecision(4))
  let tmpValue = value
  let digits = 0
  while (tmpValue >= 1000) {
    tmpValue = tmpValue / 10
    digits++
  }
  return parseFloat(value.toPrecision(5 + digits))
}
