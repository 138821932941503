import Vue from 'vue'
import router from '@/router'
import moment from 'moment'

export default {
  trackUserLogin ({ rootState, rootGetters }) {
    const accountId = rootGetters['userManagement/getCurrentAccountId']
    const { userEmail } = rootState.userManagement

    Vue.gtm.trackEvent({
      accountId,
      userEmail,
      event: 'login',
      eventTime: moment().format('YYYY-MM-DD HH:mm:ss')
    })
  },
  resetAskQuestionTracking ({ state, commit }) {
    commit('resetAskEvent')
  },
  trackAskQuestion ({ state, commit, dispatch, rootState, rootGetters }, addedParams) {
    let route = router.app.$route
    let routeQuery = route.query
    const { userEmail } = rootState.userManagement
    const accountId = rootGetters['userManagement/getCurrentAccountId']
    const groupId = rootGetters['userManagement/getCurrentGroupId']

    // 如果參數內帶有 question，檢查舊有 state 內是不是有未完成的資料，有的話就先送出舊有的
    if (addedParams?.question && state.params.askQuestion.question && Object.values(state.params.askQuestion).some(item => item === null)) {
      commit('setEventParams', { event: 'askQuestion', params: { isAskQuestionSuccess: 'cancel' } })
      Vue.gtm.trackEvent({
        accountId,
        groupId,
        userEmail,
        event: 'ask-question',
        dataSourceId: routeQuery?.dataSourceId || null,
        dataFrameId: routeQuery?.dataFrameId || null,
        eventTime: moment().format('YYYY-MM-DD HH:mm:ss'),
        ...state.params.askQuestion
      })
      dispatch('resetAskQuestionTracking')
    }

    // 將要追蹤的 event parameter 塞入
    commit('setEventParams', { event: 'askQuestion', params: addedParams })
    const eventParams = state.params.askQuestion
    // 當要追蹤的 event parameter 到齊後送出trackEvent，並清空 params
    if (Object.values(eventParams).every(item => item !== null)) {
      Vue.gtm.trackEvent({
        accountId,
        groupId,
        userEmail,
        event: 'ask-question',
        dataSourceId: routeQuery?.dataSourceId || null,
        dataFrameId: routeQuery?.dataFrameId || null,
        eventTime: moment().format('YYYY-MM-DD HH:mm:ss'),
        ...eventParams
      })
      dispatch('resetAskQuestionTracking')
    }
  },
  basicTrackEvent ({ rootState, rootGetters }, params) {
    let route = router.app.$route
    const { userEmail } = rootState.userManagement
    const accountId = rootGetters['userManagement/getCurrentAccountId']
    const groupId = rootGetters['userManagement/getCurrentGroupId']

    return Vue.gtm.trackEvent({
      accountId,
      groupId,
      userEmail,
      page: route.name,
      eventTime: moment().format('YYYY-MM-DD HH:mm:ss'),
      ...params
    })
  },
  // for PM
  trackPageFunctionClick ({ dispatch, rootState, rootGetters }, target) {
    let route = router.app.$route
    let routeQuery = route.query

    /**
     * category：事件的分類
     * action：事件被觸發的動作
     * label：事件動作的次要分類
     */

    dispatch('basicTrackEvent', {
      category: 'maintain tracking',
      action: 'click',
      label: target,
      target,
      event: 'page-function-click',
      dataSourceId: routeQuery?.dataSourceId || null,
      dataFrameId: routeQuery?.dataFrameId || null
    })
  },
  // for UI/UX
  trackUserClick ({ dispatch }, target) {
    dispatch('basicTrackEvent', {
      category: 'feature usage record',
      event: 'feature usage record',
      action: 'click',
      label: target,
      target
    })
  },
  // for UI/UX
  trackUserScroll ({ dispatch }, target) {
    dispatch('basicTrackEvent', {
      category: 'feature usage record',
      event: 'feature usage record',
      action: 'scroll',
      label: target,
      target
    })
  }
}
