const generatePosition = (currentX, currentY, rowMaxHeight, componentColumn) => {
  if (currentX === 0 && currentY === 0) {
    return {
      x: 0,
      y: 0
    }
  }

  if ((currentX + componentColumn) <= 12) {
    return {
      x: currentX,
      y: currentY
    }
  } else {
    return {
      x: 0,
      y: currentY + rowMaxHeight
    }
  }
}

export default (returnData) => {
  // add sidenav pin flag
  returnData.settings.editModeData.isSideNavPin = true

  const settingsArr = ['editModeData', 'viewModeData']
  settingsArr.forEach((key) => {
    returnData.settings[key].dashboards.forEach((dashboard) => {
      let currentX = 0
      let currentY = 0
      let rowMaxHeight = 0

      dashboard.components.forEach((component) => {
        const row = component?.config?.size?.row ?? 6
        const column = component?.config?.size?.column ?? 6
        rowMaxHeight = Math.max(column, rowMaxHeight)

        const position = generatePosition(currentX, currentY, rowMaxHeight, column)
        currentX = position.x + column
        currentY = position.y

        component.position = {
          ...position,
          row,
          column
        }
        delete component.config.size // remove component .config.size

        component.config.isTitleSyncing = false // lock current title
        if (component.question) {
          // double check if question is equal to title
          component.config.isTitleSyncing = component.question === component.config.diaplayedName

          if (!component.config.diaplayedName) {
            component.config.isTitleSyncing = true
            component.config.diaplayedName = component.question
          }
        }

        component.advanced = {
          filterList: [],
          selectedColumnList: null
        }
      })
    })
  })

  return returnData
}
