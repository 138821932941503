export default (data) => {
  const returnData = JSON.parse(JSON.stringify(data))

  // 儲存 bin Size 用以調整 histogram 軸距
  returnData.settings.editModeData.dashboards.forEach((dashboard) => {
    dashboard.components.forEach((component) => {
      component.displayConfig.binSize = null
    })
  })

  return returnData
}
