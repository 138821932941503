export default (data) => {
  const returnData = JSON.parse(JSON.stringify(data))

  returnData.settings.editModeData.dashboards.forEach((dashboard) => {
    dashboard.components.forEach((component) => {
      if (['simulator', 'parameters-optimized-simulator'].includes(component.type)) {
        component.modelSetting.type = 'column'
      }
    })
  })

  return returnData
}
