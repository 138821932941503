<template>
  <el-popover
    :content="content"
    :placement="placement"
    :width="width"
    popper-class="el-popover--training-reminder"
    trigger="hover"
  >
    <svg-icon
      slot="reference"
      :style="styles"
      class="svg-style"
      :icon-class="iconName"
    />
  </el-popover>
</template>

<script>
export default {
  name: 'RemindPopover',
  props: {
    content: {
      type: String,
      default: ''
    },
    placement: {
      type: String,
      default: 'bottom'
    },
    width: {
      type: String,
      default: '200'
    },
    iconName: {
      type: String,
      default: 'data-explanation'
    },
    iconColor: {
      type: String,
      default: '#A7A7A7'
    }
  },
  computed: {
    styles () {
      return {
        '--icon-color': this.iconColor
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.svg-style {
  color: var(--icon-color);
  vertical-align: -.2em;
}
</style>
