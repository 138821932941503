<template>
  <div class="display-basic-chart">
    <v-echart
      :style="chartStyle"
      :option="options"
      auto-resize
      @brushselected="brushRegionSelected"
    />
    <selected-region
      v-if="selectedData.length > 0"
      :title="$t('resultDescription.currentChosenData')"
      @save="saveFilter"
    >
      <div slot="selectedFilterRegion">
        <div
          v-for="(singleType, index) in selectedData"
          :key="index"
        >
          <div
            v-if="singleType.type === 'enum'"
            class="filter-description"
          >
            <div class="column-name">
              {{ singleType.properties.display_name }} =
            </div>
            <div
              v-for="(singleData, propertiesIndex) in singleType.properties.datavalues"
              :key="'enum-' + propertiesIndex"
              class="single-filter"
            >
              {{ singleData }}<span v-show="propertiesIndex !== singleType.properties.datavalues.length - 1">、</span>
            </div>
          </div>
          <div
            v-if="singleType.type === 'range'"
            class="region-description"
          >
            <div class="single-area">
              {{ $t('resultDescription.area') + (index + 1) }}:
              {{ singleType.properties.display_name }} {{ $t('resultDescription.between', {start: singleType.properties.start, end: singleType.properties.end }) }}
            </div>
          </div>
        </div>
      </div>
    </selected-region>
  </div>
</template>

<script>
import EchartAddon from './common/addon.js'
import { commonChartOptions } from '@/components/display/common/chart-addon'
import { getDrillDownTool } from '@/components/display/common/addons'
import {
  colorOnly1,
  colorOnly2,
  color5,
  color12,
  gridDefault,
  xAxisDefault,
  yAxisDefault,
  seriesItemLine,
  seriesItemLineStack,
  seriesItemBar,
  seriesItemPie,
  seriesItemDoughnut,
  seriesItemMarkLine,
  seriesItemPieLabelWithValue,
  xAxisScroll
} from './common/addons'
import { defineComponent } from '@vue/composition-api'
import { useAskingModuleContext } from '@/modules/shared/asking'

const echartAddon = new EchartAddon({
  'grid:default': gridDefault(),
  'xAxis:default': xAxisDefault(),
  'yAxis:default': yAxisDefault(),
  'seriesItem:bar': seriesItemBar(),
  'seriesItem:line': seriesItemLine(),
  'seriesItem:lineStack': seriesItemLineStack(),
  'seriesItem:pie': seriesItemPie(),
  'seriesItem:pieLabelWithValue': seriesItemPieLabelWithValue(),
  'seriesItem:doughnut': seriesItemDoughnut(),
  'seriesItem:markLine': seriesItemMarkLine()
})

export default defineComponent({
  name: 'DisplayBasicChart',
  props: {
    dataset: { type: [Object, Array, String], default: () => ([]) },
    title: {
      type: Object,
      default: () => {
        return {
          xAxis: null,
          yAxis: null
        }
      }
    },
    addons: { type: [Object, Array], default: () => ([]) },
    height: { type: String, default: '420px' },
    isParallel: {
      type: Boolean,
      default: false
    },
    canDownloadCsv: {
      type: Boolean,
      default: false
    },
    chartToolboxSetting: {
      type: Object,
      default: undefined
    }
  },
  setup () {
    const { updateFilterListByData, appQuestion } = useAskingModuleContext()
    return {
      updateFilterListByData,
      appQuestion
    }
  },
  data () {
    echartAddon.mapping(this.addons)
    return {
      addonOptions: JSON.parse(JSON.stringify(echartAddon.options)),
      addonSeriesItem: JSON.parse(JSON.stringify(echartAddon.seriesItem)),
      addonSeriesData: JSON.parse(JSON.stringify(echartAddon.seriesData)),
      addonSeriesItems: JSON.parse(JSON.stringify(echartAddon.seriesItems)),
      selectedData: []
    }
  },
  computed: {
    chartStyle () {
      return {
        width: '100%',
        height: this.height
      }
    },
    series () {
      return this.dataset.columns.map((element, colIndex) => {
        return {
          // 如果有 column 經過 Number() 後為數字 ，echart 會畫不出來，所以補個空格給他
          name: isNaN(Number(element)) ? element : ' ' + element,
          ...this.addonSeriesItem,
          ...this.addonSeriesItems[colIndex],
          connectNulls: true
        }
      })
    },
    options () {
      let config = {
        ...this.addonOptions,
        ...getDrillDownTool(this.$route.name, this.title),
        ...JSON.parse(JSON.stringify((commonChartOptions(this.chartToolboxSetting)))),
        dataset: {
          source: this.datasetTransform(this.dataset)
        },
        series: this.series,
        color: this.colorList
      }
      config.toolbox.feature.dataView.optionToContent = (opt) => {
        let dataset = opt.dataset[0].source
        let table = '<div style="text-align: text;padding: 0 16px;position: absolute;width: 100%;"><button style="width: 100%;" class="btn btn-m btn-default" type="button" id="export-btn">' + this.$t('chart.export') + '</button></div><table style="width:100%;padding: 0 16px;white-space:nowrap;margin-top: 48px;"><tbody>'
        for (let i = 0; i < dataset.length; i++) {
          let tableData = dataset[i].reduce((acc, cur) => {
            return acc + `<td style="padding: 4px 12px;">${cur === null ? '' : cur}</td>`
          }, '')
          table += `<tr ${i % 2 === 0 ? (i === 0 ? 'style="background-color:#2B4D51"' : 'style="background-color:rgba(50, 75, 78, 0.6)"') : ''}>${tableData}</tr>`
        }
        table += '</tbody></table>'
        return table
      }

      // 移除 null 值
      config.tooltip.formatter = (datas) => {
        let res = datas[0].name + '<br/>'
        for (let i = 0, length = datas.length; i < length; i++) {
          let componentIndex = datas[i].componentIndex + 1
          if (datas[i].value[componentIndex] === null || datas[i].value[componentIndex] === undefined) continue
          let marker = datas[i].marker ? datas[i].marker : `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${datas[i].color.colorStops[0].color};"></span>`
          res += marker + datas[i].seriesName + '：' + this.formatComma(datas[i].value[componentIndex]) + '<br/>'
        }
        return res
      }

      // 為了讓只有 line chart 跟 bar chart 才顯示，所以加在這邊
      config.toolbox.feature.magicType.show = this.chartToolboxSetting ? this.chartToolboxSetting.magicType : true

      // 圖表是水平或是垂直
      if (this.isParallel) {
        config.xAxis = yAxisDefault()
        config.xAxis.name = this.title.yAxis.length > 0 ? this.title.yAxis[0].display_name : null
        config.yAxis = xAxisDefault()
        config.yAxis.name = this.title.xAxis.length > 0 ? this.title.xAxis[0].display_name.replace(/ /g, '\r\n') : null
      } else {
        config.xAxis.name = this.title.xAxis.length > 0 ? this.title.xAxis[0].display_name.replace(/ /g, '\r\n') : null
        config.yAxis.name = this.title.yAxis.length > 0 ? this.title.yAxis[0].display_name : null
      }

      // 數量大的時候出現 scroll bar
      if (this.dataset.data.length > 30) {
        config.xAxis.axisLabel.interval = 0
        config.dataZoom = xAxisScroll(20 * 100 / this.dataset.data.length)
        config.animation = false
      }

      return config
    },
    colorList () {
      switch (this.series.length) {
        case 1:
          return colorOnly1
        case 2:
          return colorOnly2
        case 3:
        case 4:
        case 5:
          return color5
        default:
          return color12
      }
    }
  },
  mounted () {
    this.exportCSVFile(this.$el, this.appQuestion, this)
  },
  methods: {
    brushRegionSelected (params) {
      switch (this.series[0].type) {
        case 'line':
          if (params.batch[0].areas.length === 0) {
            this.selectedData = []
            break
          }
          this.selectedData = params.batch[0].areas.map(areaElement => {
            let coordRange = areaElement.coordRange
            return {
              type: 'range',
              properties: {
                dc_id: this.title.xAxis[0].dc_id,
                data_type: this.title.xAxis[0].data_type,
                display_name: this.title.xAxis[0].display_name,
                start: this.dataset.index[coordRange[0]],
                end: this.dataset.index[coordRange[1]]
              }
            }
          })
          break
        case 'bar':
          if (params.batch[0].selected[0].dataIndex.length === 0) {
            this.selectedData = []
            break
          }

          // 水平方向 drill down
          if (this.title.yAxis[0].drillable) {
            this.selectedData = params.batch[0].areas.map(areaElement => {
              let coordRange = areaElement.coordRange
              return {
                type: 'range',
                properties: {
                  dc_id: this.title.yAxis[0].dc_id,
                  data_type: this.title.yAxis[0].data_type,
                  display_name: this.title.yAxis[0].display_name,
                  start: this.roundNumber(coordRange[0]),
                  end: this.roundNumber(coordRange[1])
                }
              }
            })
          } else {
            // 垂直方向 drill down
            this.selectedData = [{
              type: 'enum',
              properties: {
                dc_id: this.title.xAxis[0].dc_id,
                data_type: this.title.xAxis[0].data_type,
                display_name: this.title.xAxis[0].display_name,
                datavalues: params.batch[0].selected[0].dataIndex.map(element => {
                  return this.dataset.index[element]
                })
              }
            }]
          }

          break
      }
    },
    saveFilter () {
      this.updateFilterListByData(this.selectedData)
    }
  }
})
</script>
