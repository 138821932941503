// 因為無法順利使用 'vue-i18n-composable'
// 把 vue-i18n-composable 存到本地就可以用了
// https://github.com/intlify/vue-i18n-composable

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { computed, getCurrentInstance } from '@vue/composition-api'
let i18nInstance

export function createI18n (options, vue = Vue) {
  vue.use(VueI18n)
  i18nInstance = new VueI18n(options)
  return i18nInstance
}

export function useI18n () {
  if (!i18nInstance) { throw new Error('vue-i18n not initialized') }
  const i18n = i18nInstance
  const instance = getCurrentInstance() ?? null
  const vm = instance?.proxy || instance || new Vue({})
  const locale = computed({
    get () {
      return i18n.locale
    },
    set (v) {
      i18n.locale = v
    }
  })
  return {
    locale,
    t: vm.$t.bind(vm),
    tc: vm.$tc.bind(vm),
    d: vm.$d.bind(vm),
    te: vm.$te.bind(vm),
    n: vm.$n.bind(vm)
  }
}
