<template>
  <div class="schedule-input">
    <el-input
      v-model.trim="inputValue"
      :placeholder="placeholder"
      :disabled="disabled"
      :type="type"
      size="small"
    >
      <template slot="prepend">
        <slot name="prepend" />
      </template>
      <template slot="append">
        <slot name="append" />
      </template>
    </el-input>
    <div
      v-show="errors.has(name)"
      class="input-error error-text"
    >
      {{ errors.first(name) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScheduleInput',
  inject: ['$validator'],
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default () {
        return this.$t('schedule.base.pleaseInput')
      }
    },
    name: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid var(--color-text);
  border-radius: 0;
  color: var(--color-text-white);
  font-size: 14px;

  &[type='number'] {
    text-align: right;

    &::placeholder {
      text-align: center;
    }
  }

  &[disabled] {
    background-color: transparent;
  }
}

::v-deep .el-input-group__prepend {
  background-color: transparent;
  border: 0;
  color: var(--color-text);
  padding: 0;
}

::v-deep .el-input-group__append {
  background-color: transparent;
  border: 0;
  color: var(--color-text);
}

.schedule-input {
  position: relative;

  .input-error {
    left: 0;
    position: absolute;
    top: calc(100% + 2px);
  }
}
</style>
