import { provide, inject } from '@vue/composition-api'

/**
 * @template Opts, Ctx
 * @typedef {object} DefineOptions
 * @property {string} namespace
 * @property {(options: Opts) => Ctx} createContext
 */

/**
 * @template O, C
 * @param {DefineOptions<O, C>}
 */
export function defineContext ({
  namespace,
  createContext
}) {
  const INJECTION_KEY = Symbol(namespace)

  /**
   * @param {O} options
   * @returns {C}
   */
  function registerContext (options) {
    const moduleContext = createContext(options)
    provide(INJECTION_KEY, moduleContext)
    return moduleContext
  }

  /**
   * @returns {C}
   */
  function useContext () {
    const moduleContext = inject(INJECTION_KEY, null)
    if (moduleContext === null) throw new Error(`Can not inject '${namespace}' before providing it`)
    return moduleContext
  }

  return {
    registerContext,
    useContext
  }
}
