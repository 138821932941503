<template>
  <div class="schedule-select">
    <el-select
      ref="elSelect"
      v-model="selectedValue"
      :placeholder="placeholder || $t('schedule.base.pleaseSelect')"
      :filterable="filterable"
      :disabled="disabled"
      :multiple="multiple"
      :popper-class="['ss-select', popperClass].join(' ')"
    >
      <el-option
        v-for="option in options"
        :key="option.value"
        :label="option.label"
        :value="option.value"
        :disabled="option.disabled"
      />
      <slot />
    </el-select>
    <div
      v-show="errors.has(name)"
      class="input-error error-text"
    >
      {{ errors.first(name) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScheduleSelect',
  inject: ['$validator'],
  props: {
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: [String, Number, Array],
      default: ''
    },
    filterable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    popperClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    selectedValue: {
      get () {
        return this.value
      },
      set (value) {
        if (value === this.value) return
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-input {
  .el-input__inner {
    background: transparent;
    border: 0;
    border-bottom: 1px solid var(--color-text);
    border-radius: 0;
    color: #fff;
    height: 32px;
    line-height: 32px;
    padding-left: 0;

    &::placeholder {
      color: #fff;
    }
  }

  .el-input__icon {
    line-height: 32px;
  }

  &.el-select .el-input .el-select__caret {
    color: #fff;
  }

  &.el-select:hover .el-input__inner,
  &.el-select .el-input__inner:focus,
  &.el-select .el-input.is-focus .el-input__inner {
    border-color: #fff;
  }
}

.schedule-select {
  padding-bottom: 20px;
  position: relative;

  .el-select {
    width: 100%;
  }

  .input-error {
    bottom: 0;
    left: 0;
    position: absolute;
  }
}
</style>
