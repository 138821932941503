import { registerAskingModuleContext } from '@/modules/shared/asking'
import { WAR_ROOM_MODULE_NAMESPACE } from '@/modules/namespace'
import { defineContext } from '@/utils/composable/context'

export { WAR_ROOM_MODULE_NAMESPACE } from '@/modules/namespace'
export { warRoomModuleStore } from './store'

export const {
  registerContext: registerWarRoomModuleContext,
  useContext: useWarRoomModuleContext
} = defineContext({
  namespace: WAR_ROOM_MODULE_NAMESPACE,
  createContext () {
    const askingModuleContext = registerAskingModuleContext({
      extendNamespace: WAR_ROOM_MODULE_NAMESPACE,
      shouldGetParserList: false
    })
    return {
      askingModuleContext
    }
  }
})
