import request from '@/schedule/utils/publicRequest.js'

/**
 * 取得機台列表
 *  @param {Number} projectId - 排程子專案 id
 */
export function getEquipments (projectId) {
  return request({
    url: '/equipment/list',
    method: 'GET',
    params: {
      projectId
    }
  })
}
