<template>
  <div class="multi-result">
    <div class="board-title">
      {{ $t('editing.multiResultTitle') }}
    </div>
    <div class="board-description">
      {{ resultInfo.segmentationList[1].denotation === "MODEL_PREDICTION" ? $t('bot.multiplePredictions') : $t('bot.multiplePossibilities') }}
    </div>
    <div class="question-list">
      <div
        v-for="(singleQuestion, index) in resultInfo.segmentationList"
        :key="index"
        class="question-block"
      >
        <div
          class="single-question"
          @click="askQuestion(singleQuestion, index)"
        >
          <question-name
            :question-segmentation="singleQuestion"
          />
          <svg-icon
            icon-class="go-right"
            class="arrow-icon"
          />
        </div>
        <div class="segmentation-info-block">
          <div
            v-if="singleQuestion.denotation.includes('PREDICTION')"
            class="single-segmentation"
          >
            <div
              v-if="isModelPredict(singleQuestion.sentence)"
              class="single-segmentation__predict"
            >
              <i18n
                path="resultDescription.reminder.modelPredict"
                tag="span"
                class="alias"
              >
                <template #df>
                  <span class="alias__df">[{{ singleQuestion.transcript.dataFrame.dataFrameAlias }}]</span>
                </template>
                <template #model>
                  <span class="alias__model">[{{ getQuestionToken(singleQuestion.sentence, 'PredictedColumnToken').modelName }}]</span>
                </template>
                <template #column>
                  <span class="alias__column">[{{ getQuestionToken(singleQuestion.sentence, 'PredictedColumnToken').columnName }}]</span>
                </template>
              </i18n>
            </div>
            <div
              v-else
              class="single-segmentation__predict"
            >
              <i18n
                path="resultDescription.reminder.trendPredict"
                tag="span"
                class="alias"
              >
                <template #df>
                  <span class="alias__df">[{{ getQuestionToken(singleQuestion.sentence, 'DataColumnToken').dataFramePrimaryAlias }}]</span>
                </template>
                <template #column>
                  <span class="alias__column">[{{ getQuestionToken(singleQuestion.sentence, 'DataColumnToken').dataColumnPrimaryAlias }}]</span>
                </template>
              </i18n>
            </div>
          </div>
          <div
            v-else
            v-for="(segmentation, segmentationIndex) in singleQuestion.sentence"
            :key="index + '-' + segmentationIndex"
            class="single-segmentation"
          >
            <template
              v-if="displayStatusList[segmentationIndex]"
            >
              <template
                v-if="segmentation.dataFrameId"
              >
                <span
                  :class="segmentation.type"
                  class="column-name"
                >[{{ segmentation.word }}]</span>{{ $t('resultDescription.from') }}
                <span class="alias-name">{{ segmentation.dataFramePrimaryAlias }}</span>{{ $t('resultDescription.has') }}
                <span v-if="segmentation.type === 'DATA_VALUE'">
                  <span class="alias-name">{{ segmentation.dataColumnPrimaryAlias }}</span>{{ $t('resultDescription.has') }}{{ $t(`segmentationToken.${segmentation.type}`) }}
                </span>
                <span v-else-if="segmentation.type === 'DATA_COLUMN'">
                  <span class="alias-name">{{ segmentation.dataColumnPrimaryAlias }}<span v-if="segmentation.isFeature">({{ $t('resultDescription.feature') }})</span></span>{{ $t(`segmentationToken.${segmentation.type}`) }}
                </span>
                <span v-else-if="segmentation.type === 'NUMBER_RULE'">
                  <template v-if="segmentation.properties">
                    <span class="alias-name">{{ segmentation.dataColumnPrimaryAlias }}</span>{{ $t(`segmentationToken.${segmentation.type}`) }}{{ $t(`resultDescription.recognize`) }}
                  </template>
                  <template v-else>
                    {{ $t(`segmentationToken.${segmentation.type}`) }}
                  </template>
                </span>
                <span v-else-if="segmentation.type === 'DATA_ROW' || segmentation.type === 'COLUMN_SET'">
                  {{ $t(`segmentationToken.${segmentation.type}`) }}
                </span>
                <el-tooltip
                  v-if="segmentation.properties && segmentation.properties.length > 1"
                  :tabindex="999"
                  placement="bottom"
                >
                  <div slot="content">
                    <span>{{ $t('resultDescription.hasColumn') }}</span>
                    <span
                      v-for="(property, propertyIndex) in segmentation.properties"
                      :key="propertyIndex"
                    >{{ property.dataColumnPrimaryAlias }}<span v-show="propertyIndex < segmentation.properties.length - 1">、</span></span>
                  </div>
                  <b class="question-token">'{{ segmentation.matchedWord }}'</b>
                </el-tooltip>
              </template>
              <template v-else-if="isIntend(segmentation.type)">
                <div>
                  <span
                    :class="{intend: isIntend(segmentation.type)}"
                    class="column-name"
                  >[{{ segmentation.word }}]</span>{{ $t(`segmentationToken.${segmentation.type}`) }}
                </div>
              </template>
              <template v-else-if="isMeaningFul(segmentation.type)">
                <span
                  :class="segmentation.type"
                  class="column-name"
                >[{{ segmentation.word }}]</span>{{ $t(`segmentationToken.${segmentation.type}`) }}
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import QuestionName from './QuestionName'
import { defineComponent } from '@vue/composition-api'
import { useAskingModuleContext } from '@/modules/shared/asking'
import { mapMutations } from 'vuex'

export default defineComponent({
  name: 'MultiResult',
  components: {
    QuestionName
  },
  props: {
    resultInfo: {
      type: Object,
      required: true
    },
    // 因應 Dashboard 問問題後不需要轉址
    redirectOnSelect: {
      type: Boolean,
      default: true
    }
  },
  setup () {
    const {
      setAppQuestion,
      setCurrentQuestionInfo,
      updateResultRouter
    } = useAskingModuleContext()
    return {
      setAppQuestion,
      setCurrentQuestionInfo,
      updateResultRouter
    }
  },
  computed: {
    // 哪些 segmentation 需要顯示
    displayStatusList () {
      let standard = this.resultInfo.segmentationList[0].sentence
      let statusList = []
      for (let i = 0; i < standard.length; i++) {
        statusList.push(false)
      }

      this.resultInfo.segmentationList.forEach(element => {
        element.sentence.forEach((segmentationElement, index) => {
          if (!standard[index]) return
          if (segmentationElement.type === standard[index].type) {
            switch (segmentationElement.type) {
              case 'DATA_VALUE':
                if (segmentationElement.dataFramePrimaryAlias !== standard[index].dataFramePrimaryAlias ||
                  segmentationElement.dataColumnPrimaryAlias !== standard[index].dataColumnPrimaryAlias) {
                  statusList[index] = true
                }
                break
              case 'DATA_COLUMN':
                if (segmentationElement.dataFramePrimaryAlias !== standard[index].dataFramePrimaryAlias ||
                  segmentationElement.dataColumnPrimaryAlias !== standard[index].dataColumnPrimaryAlias ||
                  segmentationElement.dataColumnId !== standard[index].dataColumnId) {
                  statusList[index] = true
                }
                break
              case 'DATA_ROW':
                if (segmentationElement.dataFramePrimaryAlias !== standard[index].dataFramePrimaryAlias) {
                  statusList[index] = true
                }
                break
              case 'PREDICTED_COLUMN':
                if (segmentationElement.dataFrame.dataFrameAlias !== standard[index].dataFrame.dataFrameAlias ||
                  segmentationElement.columnName !== standard[index].columnName ||
                  segmentationElement.modelName !== standard[index].modelName) {
                  statusList[index] = true
                }
                break
              default:
                if (segmentationElement.matchedWord !== standard[index].matchedWord) {
                  statusList[index] = true
                }
            }
          } else {
            statusList[index] = true
          }
        })
      })
      return statusList
    }
  },
  methods: {
    ...mapMutations('dataSource', ['setAlgoConfigModelId', 'setActualColumnId']),
    askQuestion (questionInfo, selectedResultIndex) {
      this.setCurrentQuestionInfo(questionInfo)
      let predictToken = questionInfo.sentence.find((token) => token.type === 'PREDICTED_COLUMN')
      predictToken && this.setAlgoConfigModelId(predictToken.modelId)
      predictToken && this.setActualColumnId(predictToken.actualColumnId)
      if (this.redirectOnSelect) {
        this.setAppQuestion(this.resultInfo.question)
        this.updateResultRouter('multi_result')
      } else {
        this.$emit('select-result', this.resultInfo.segmentationList[selectedResultIndex])
      }
    },
    isMeaningFul (value) {
      switch (value) {
        case 'IGNORE':
          return false
        default:
          return true
      }
    },
    isIntend (value) {
      switch (value) {
        case 'DENOTATION':
          return true
        default:
          return false
      }
    },
    tooltipContent (tokenInfo) {
      switch (tokenInfo.type) {
        case 'DATA_VALUE':
        case 'DATA_COLUMN':
        case 'DATA_ROW':
          return this.$t('resultDescription.recognizeTo', { dataFrame: tokenInfo.dataFramePrimaryAlias, token: this.$t(`segmentationToken.${this.tokenInfo.type}`) }) + tokenInfo.matchedWord
        default:
          return this.$t(`segmentationToken.${this.tokenInfo.type}`)
      }
    },
    getDataColumnTypeWord (sentence) {
      return sentence.filter(word => word.type === 'DATA_COLUMN')[0].word
    },
    isModelPredict (sentence) {
      return sentence.some((column) => column['@type'] === 'PredictedColumnToken')
    },
    getQuestionToken (sentence, type) {
      return sentence.filter((column) => column['@type'] === type)[0]
    }
  }
})
</script>
<style lang="scss" scoped>
.multi-result {
  background-color: var(--color-bg-5);
  border-radius: 8px;
  padding: 16px 24px 24px;

  .board-title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 4px;
  }

  .board-description {
    line-height: 24px;
    margin-bottom: 24px;
  }

  .question-block {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .single-question {
    background: rgba(35, 61, 64, 0.6);
    border: 1px solid #065d68;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 8px;
    padding: 16px;
    position: relative;
    transition: all 0.3s;

    &:hover {
      box-shadow: 0 12px 24px rgba(0, 0, 0, 0.12);
      transform: translate3d(0, -5px, 0);
    }

    .arrow-icon {
      animation: arrowRight 1s infinite;
      bottom: 0;
      margin: auto;
      position: absolute;
      right: 16px;
      top: 0;

      @keyframes arrowRight {
        0% {
          transform: translateX(0);
        }

        50% {
          transform: translateX(5px);
        }

        100% {
          transform: translateX(0);
        }
      }
    }
  }

  .single-segmentation {
    font-size: 14px;
    line-height: 26px;

    .column-name {
      color: #ddd;
      margin-right: 8px;

      &.filter {
        color: #ff9559;
      }

      &.DATA_COLUMN {
        color: #44d2ff;
      }

      &.numeric {
        color: #ca66da;
      }

      &.DATA_VALUE {
        color: #ca66da;
      }

      &.DtToken,
      &.FuzzyDtToken,
      &.TimeScope,
      &.NumRuleToken {
        color: #ff9559;
      }

      &.intend {
        color: #07e8b2;
      }
    }

    .question-token {
      text-decoration: underline;
    }

    .alias-name {
      display: inline-block;
      font-weight: bold;
      margin: 0 8px;
    }

    &__predict {
      color: #ddd;
      .alias {
        font-weight: bold;
        &__df {
          color: #44D2FF;
        }
        &__model {
          color: #FFDF6F;
        }
        &__column {
          color: #FFFFFF;
        }
      }
    }
  }
}
</style>
