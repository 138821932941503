import store from '@/store'
import { DEFAULT_JOB_TABLE_COLUMN } from '@/schedule/utils/enum'

export function getIndexOfSpecificColumn (columnName) {
  return store.state.scheduleSetting.dynamicJobTableColumns.findIndex(item => {
    return item.title === DEFAULT_JOB_TABLE_COLUMN[columnName]
  })
}

export const acceptCSVFileTypes = {
  data () {
    return {
      acceptFileTypes: [
        '.csv',
        'text/csv',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel'
      ]
    }
  }
}

export function convertTimeStamp (timeStamp = new Date().getTime()) {
  if (!timeStamp) return '-'
  const date = new Date(timeStamp)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours().toString().padStart(2, '0')
  const minute = date.getMinutes().toString().padStart(2, '0')
  return `${year}/${month}/${day} ${hour}:${minute}`
}

export function dashToCamel (string) {
  if (!string) return ''
  return string.toLowerCase().replace(/(\w)(-)(\w)/g, (match, $1, $2, $3) => `${$1}${$3.toUpperCase()}`)
}

export function snakeToCamel (string) {
  if (!string) return ''
  return string.toLowerCase().replace(/(\w)(_)(\w)/g, (match, $1, $2, $3) => `${$1}${$3.toUpperCase()}`)
}

export function snakeToPascal (string) {
  if (!string) return ''
  let s = snakeToCamel(string)
  return `${s[0].toUpperCase()}${s.slice(1)}`
}

export function pascalToCamel (string) {
  if (!string) return ''
  return `${string[0].toLowerCase()}${string.slice(1)}`
}

export function pascalToSnake (string) {
  if (!string) return ''
  return string.split(/(?=[A-Z])/).join('_').toLowerCase()
}

export function stringToFloat (string) {
  const parsedValue = parseFloat(string)
  return isNaN(parsedValue) ? null : parsedValue
}
