<template>
  <div
    v-if="messageList && messageList.length > 0"
    class="insight-description-block description"
  >
    <div class="description__header">
      <span
        :class="{[`description__title--${messageType}`]: messageType}"
        class="description__title"
      >
        <svg-icon
          v-if="iconName"
          :icon-class="iconName"
        />
        {{ `${title} ${messageList.length > 1 ? pageInfo : ''}` }}
      </span>
      <span v-if="messageList.length > 1">
        <span @click="setDisplayDescription(displayLineIndex - 1)">
          <svg-icon
            class="arrow-icon arrow-icon--up"
            icon-class="arrow-down2"
          />
        </span>
        <span @click="setDisplayDescription(displayLineIndex + 1)">
          <svg-icon
            class="arrow-icon"
            icon-class="arrow-down2"
          />
        </span>
      </span>
    </div>
    <template v-for="(description, index) in describeListedMessages">
      <el-tooltip
        :key="index"
        v-show="index === displayLineIndex"
        class="beginner-guide"
        :enterable="true"
        :content="description"
        popper-class="beginner-guide__menu"
        :force-show="true"
        :disabled="!isInMiniApp"
      >
        <span
          :class="{[`description__item--${messageType}`]: messageType, 'description__item--no-wrap': isInMiniApp}"
          class="description__item"
        >
          {{ description }}
          <template v-if="subMessageList && subMessageList.length > 0">
            <remind-popover
              :content="remindDifference"
              icon-name="data-explanation"
              icon-color="#FFDF6F"
              width="100"
            />
            {{ $t('resultDescription.moreEvaluationData') }}
          </template>
        </span>
      </el-tooltip>
    </template>
  </div>
</template>
<script>
import RemindPopover from '@/components/popover/RemindPopover.vue'
export default {
  name: 'InsightDescriptionBlock',
  components: {
    RemindPopover
  },
  props: {
    title: {
      type: String,
      default: null
    },
    iconName: {
      type: String,
      default: null
    },
    messageType: {
      type: String,
      default: null
    },
    messageList: {
      type: Array,
      default: () => []
    },
    subMessageList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      displayLineIndex: 0
    }
  },
  inject: {
    isInMiniApp: {
      default: false
    }
  },
  computed: {
    remindDifference () {
      return this.subMessageList[0].split(',').join('\n')
    },
    pageInfo () {
      return `(${this.displayLineIndex + 1}/${this.messageList.length})`
    },
    describeListedMessages () {
      return this.messageList.map((description, index) => (this.messageList.length > 1 && this.messageList[0].charAt(0) != 1) ? `${index + 1}. ${description}` : description)
    }
  },
  methods: {
    setDisplayDescription (index) {
      if (index >= this.messageList.length) {
        this.displayLineIndex = 0
        return
      }
      this.displayLineIndex = index < 0 ? this.messageList.length - 1 : index
    }
  }
}
</script>
<style lang="scss" scoped>
.description {
  width: 100%;
  margin: 16px 0;
  background: #141C1D;
  border-radius: 8px;
  padding: 10px 20px;

  &--warning {
    background: rgba(255, 223, 111, 0.08);
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    margin-bottom: 4px;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: #eee;

    &--warning {
      color: #eedf6f;
    }
  }

  &__item {
    font-size: 14px;
    letter-spacing: 0.1em;
    display: block;
    line-height: 24px;
    color: #ccc;

    &--no-wrap {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &--warning {
      color: #eedf6f;
    }
  }
}

.arrow-icon {
  fill: #A7A7A7;
  margin-left: 14px;
  cursor: pointer;
  &--up {
    transform: rotate(180deg);
  }
}
</style>
