<template>
  <div>
    <template v-if="metricsType === 'Binary'">
      <div
        class="popover-content"
        v-show="metricSelectedGroup(displayMetric) === 'curve'"
      >
        <div class="popover-content__text">
          {{ $t(`model${descriptionType}.reminder.${displayMetric}Explain`) }}
        </div>
        <div class="popover-content__formula">
          <svg-icon
            :icon-class="`${displayMetric}_chart`"
            class="chart"
          />
          <katex-element :expression="curveFormula(displayMetric)" />
          <svg-icon
            icon-class="confusion_matrix"
            class="chart"
          />
        </div>
      </div>
      <div
        class="popover-content"
        v-show="metricSelectedGroup(displayMetric) === 'confusion'"
      >
        <div class="popover-content__text">
          {{ $t(`modelPerformance.reminder.${displayMetric}Explain`) }}
        </div>
        <div class="popover-content__formula">
          <div class="main-container">
            <katex-element
              :expression="binaryFormula(displayMetric)"
              class="main-container__formula"
            />
          </div>
          <katex-element :expression="confusionFactorExplanation" />
          <svg-icon
            icon-class="confusion_matrix"
            class="chart"
          />
        </div>
      </div>
      <div
        class="popover-content"
        v-show="metricSelectedGroup(displayMetric) === 'matrix'"
      >
        <div class="popover-content__text">
          {{ $t(`modelPerformance.reminder.${displayMetric}Explain`) }}
        </div>
        <div class="popover-content__formula">
          <svg-icon
            icon-class="confusion_matrix"
            class="chart"
          />
          <katex-element :expression="confusionFactorExplanation" />
        </div>
      </div>
    </template>
    <div
      v-else-if="metricsType === 'Regression'"
      class="popover-content"
    >
      <div class="popover-content__text">
        {{ $t(`modelPerformance.reminder.${displayMetric}Explain`) }}
      </div>
      <div class="popover-content__formula">
        <div class="main-container">
          <katex-element
            :expression="regressionFormula(displayMetric)"
            class="main-container__formula"
          />
        </div>
        <katex-element :expression="regressionExplanetion" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MetricContent',
  props: {
    metricsType: {
      type: String,
      default: ''
    },
    displayMetric: {
      type: String,
      default: ''
    },
    descriptionType: {
      type: String,
      default: 'Performance'
    }
  },
  data () {
    return {
      confusionFactorExplanation: `
        \\newline\\mathrm{TP}\\textsf{${this.$t('modelPerformance.reminder.TP')}}
        \\newline\\mathrm{TN}\\textsf{${this.$t('modelPerformance.reminder.TN')}}
        \\newline\\mathrm{FP}\\textsf{${this.$t('modelPerformance.reminder.FP')}}
        \\newline\\mathrm{FN}\\textsf{${this.$t('modelPerformance.reminder.FN')}}\\newline`
    }
  },
  computed: {
    regressionExplanetion () {
      return this.displayMetric === 'R2' ? `
        \\newline {R^2}\\textsf{${this.$t('modelPerformance.reminder.R_Squared')}}
        \\newline {RSS}\\textsf{${this.$t('modelPerformance.reminder.RSS')}}
        \\newline {TSS}\\textsf{${this.$t('modelPerformance.reminder.TSS')}}
      ` : `\\newline\\mathrm{${this.displayMetric}}\\textsf{${this.$t(`modelPerformance.reminder.${this.displayMetric}`)}}
        \\newline {N}\\textsf{${this.$t(`modelPerformance.reminder.${this.displayMetric}_N`)}}
        \\newline Y_{i}\\textsf{${this.$t(`modelPerformance.reminder.${this.displayMetric}_Yi`)}}
        \\newline\\hat{Y}_{i}\\textsf{${this.$t(`modelPerformance.reminder.${this.displayMetric}_Y^i`)}}`
    }
  },
  methods: {
    metricSelectedGroup (metricSelected) {
      switch (metricSelected) {
        case 'AUC':
        case 'AUCPR':
        case 'ROC':
        case 'PR':
          return 'curve'
        case 'Accuracy':
        case 'Precision':
        case 'Recall':
        case 'F1':
          return 'confusion'
        case 'matrix':
          return 'matrix'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.popover-content {
  display: flex;
  &__text {
    line-height: 24px;
    flex: 1 1 280px;
    border-right: 0.628136px solid #52696A;
    padding-right: 16px;
  }
  &__formula {
    flex: 1 1 280px;
    padding-left: 16px;

    ::v-deep .katex .base{
      white-space: pre-wrap;
      width: 100%;
    }

    .main-container {
      width: 100%;
      &__formula {
        ::v-deep .katex {
          display: flex;
          justify-content: center;
          margin: 20px 0;
        }
      }
    }
  }
  .chart {
    margin-top: 20px;
    width: 200px;
    height: 120px;
  }
}
</style>
