<template>
  <div
    ref="root"
    class="display-3d-chart"
  >
    <v-echart
      :style="chartStyle"
      :option="options"
    />
    <arrow-button
      v-show="showPagination"
      v-if="hasPagination"
      :right="arrowBtnRight"
    >
      <template #dropdown>
        <custom-dropdown-select
          :data-list="pageList"
          trigger="hover"
          @select="(_id) => pageList.find(({ id }) => id === _id).action()"
        />
      </template>
    </arrow-button>
  </div>
</template>

<script>
import CustomDropdownSelect from '@/components/select/CustomDropdownSelect'
import EchartAddon from './common/addon.js'
import formatComma from '@/utils/formatComma'
import autoRound from '@/utils/autoRound'
import { toolboxSetting, tooltipSetting } from '@/components/display/common/chart-addon'
import { defineComponent, ref, computed } from '@vue/composition-api'
import { useI18n } from '@/utils/composable/i18n'
import { useAskingModuleContext } from '@/modules/shared/asking'
import {
  grid3D,
  xAxis3D,
  yAxis3D,
  zAxis3D,
  seriesItemBar3D,
  visual3dMapDefault
} from './common/addons'
import 'echarts-gl'

export default defineComponent({
  name: 'Display3DBarChart',
  components: {
    CustomDropdownSelect
  },
  props: {
    dataset: { type: [Object, Array, String], default: () => ([]) },
    componentId: {
      type: Number,
      default: null
    },
    title: {
      type: Object,
      default: () => {
        return {
          xAxis: null,
          yAxis: null,
          zAxis: null
        }
      }
    },
    height: { type: String, default: '520px' },
    customChartStyle: {
      type: Object,
      default: () => ({})
    },
    arrowBtnRight: {
      type: Number,
      default: 80
    },
    canDownloadCsv: {
      type: Boolean,
      default: false
    },
    hasPagination: {
      type: Boolean,
      default: false
    },
    chartToolboxSetting: {
      type: Object,
      default: undefined
    }
  },
  setup (props, { emit, refs }) {
    const { t } = useI18n()
    /* ---Echarts 初始設定項--- */
    const color = ['#309CFF', '#C7FF4D', '#FFDE52', '#DE636E']

    const echartAddon = new EchartAddon({
      'grid3D:default': grid3D(),
      'xAxis3D:bar': xAxis3D(),
      'yAxis3D:bar': yAxis3D(),
      'zAxis3D:bar': zAxis3D(),
      'seriesItem:bar3D': seriesItemBar3D(),
      'visualMap:default': visual3dMapDefault(color)
    })

    echartAddon.mapping({
      'grid3D:default': {},
      'xAxis3D:bar': {},
      'yAxis3D:bar': {},
      'zAxis3D:bar': {},
      'visualMap:default': {},
      'seriesItem:bar3D': {}
    })

    const root = ref(null)

    const addonOptions = ref(echartAddon.options)

    const addonSeriesItem = ref(echartAddon.seriesItem)

    const chartToolboxSetting = ref({
      show: true,
      dataZoom: false,
      magicType: false,
      restore: false,
      dataView: true,
      brush: false,
      saveAsImage: true,
      myShowLabel: false
    })

    /* ---分頁處理--- */
    const showPagination = ref(true)

    const pageList = computed(() => [
      {
        id: 'extendXPage',
        name: t('chart.loadMoreData', { axisName: props.title.xAxis[0].display_name }),
        disabled: props.dataset.isXSinglePage,
        action: () => emit('next', 'xPage')
      },
      {
        id: 'extendYPage',
        name: t('chart.loadMoreData', { axisName: props.title.yAxis[0].display_name }),
        disabled: props.dataset.isYSinglePage,
        action: () => emit('next', 'yPage')
      }
    ])

    function controlPagination () {
      let exportBtn = document.getElementById('export-btn')
      if (exportBtn) {
        showPagination.value = false
      } else {
        showPagination.value = true
        refs.root.removeEventListener('click', controlPagination, false)
      }
    }

    /* ---圖表資料--- */
    const chartStyle = computed(() => ({
      width: '100%',
      height: props.height,
      ...props.customChartStyle
    }))

    const indexList = computed(() => Object.values(props.title).map((data) => data[0].display_name))

    const transformedDataViewData = computed(() => {
      const dataset = props.dataset.index.map((data, index) => {
        return data.concat(props.dataset.data[index])
      })
      return [indexList.value, ...dataset]
    })

    const transformedChartData = computed(() => {
      const dataset = props.dataset.index.map((data, index) => {
        let transformedData
        if (props.dataset.data[index][0] === null) {
          // 把null轉成比min小一點的資料，目的是避免Echart遇到null值導致bar顏色錯誤的問題
          transformedData = getSmaller(getExtremeData(props.dataset.data).min)
        } else {
          transformedData = props.dataset.data[index]
        }
        return data.concat(transformedData)
      })
      return [indexList.value, ...dataset]
    })

    const rectBox = computed(() => {
      const yAxisCurrentPageLength = props.dataset.display_columns.length
      const xAxisList = new Set()
      props.dataset.display_index.forEach((valueList) => {
        xAxisList.add(valueList[0])
      })
      const depth = (yAxisCurrentPageLength + props.dataset.yPage * 30) * 10
      return { width: xAxisList.size * 10, depth }
    })

    function getExtremeData (dataList) {
      let min = Infinity
      let max = -Infinity
      dataList.forEach((data) => {
        if (data[0] === null) return
        if (data[0] > max) {
          max = data[0]
        }
        if (data[0] < min) {
          min = data[0]
        }
      })
      // 當資料全是null時
      if (min === Infinity && max === -Infinity) {
        [min, max] = [null, null]
      }
      return { min, max }
    }

    function getSmaller (min) {
      if (min > 1) {
        return min - 1
      } else if (min <= 1 && min > 0) {
        return min - (+min)
      } else {
        return 1 + min > 0 ? min + min : min - 1
      }
    }

    const options = computed(() => {
      let config = {
        ...addonOptions.value,
        ...tooltipSetting('item'),
        ...toolboxSetting(props.chartToolboxSetting ?? chartToolboxSetting.value),
        dataset: {
          source: transformedChartData.value
        },
        series: {
          ...addonSeriesItem.value
        }
      }

      config.grid3D.boxWidth = rectBox.value.width
      config.grid3D.boxDepth = rectBox.value.depth

      Object.keys(props.title).forEach((axis) => {
        config[`${axis}3D`].name = props.title[axis][0].display_name
      })

      config.yAxis3D.axisLabel.formatter = (data) => {
        return data.length > 6 ? `${data.slice(0, 6)} ...` : data
      }

      config.visualMap.dimension = props.title.zAxis[0].display_name

      const { min, max } = getExtremeData(props.dataset.data)

      // visualMap.min 在type是連續型時要為getSmaller(min)
      // 資料全null時給一個虛假的區間1 ~ 2過濾空值
      config.visualMap.min = min === null ? min + 1 : min
      config.visualMap.max = max === null ? max + 2 : max

      // visualMap type picewise 不需還要設range讓他掉出範圍外
      // config.visualMap.range = [min, max]

      config.visualMap.formatter = (minOfInterval, maxOfInterval) => {
        return `${autoRound(minOfInterval)} - ${autoRound(maxOfInterval)}`
      }

      config.visualMap.show = min !== null

      config.toolbox.feature.dataView.optionToContent = () => {
        if (props.hasPagination) {
          refs.root.addEventListener('click', controlPagination, false)
        }
        let dataset = transformedDataViewData.value
        let table = '<div style="text-align: text;padding: 0 16px;position: absolute;width: 100%;"><button style="width: 100%;" class="btn btn-m btn-default" type="button" id="export-btn">' + t('chart.export') + '</button></div><table style="width:100%;padding: 0 16px;white-space:nowrap;margin-top: 48px;"><tbody>'
        for (let i = 0; i < dataset.length; i++) {
          let tableData = dataset[i].reduce((acc, cur, idx) => {
            return acc + `<td style="padding: 4px 12px;white-space:nowrap;">${formatComma(cur)}</td>`
          }, '')
          table += `<tr ${i % 2 !== 0 ? 'style="background-color:rgba(50, 75, 78, 0.6)"' : ''}>${tableData}</tr>`
        }
        table += '</tbody></table>'
        return table
      }

      config.tooltip.formatter = (datas) => {
        let res = ''
        for (let i = 0; i < datas.data.length; i++) {
          if (datas.value[i] === null || datas.value[i] === undefined) continue
          let marker = datas.marker
          res += `${marker}${transformedChartData.value[0][i]} : ${formatComma(datas.value[i])}<br/>`
        }
        return res
      }

      return config
    })

    const { appQuestion } = useAskingModuleContext()
    return {
      root,
      chartStyle,
      pageList,
      showPagination,
      options,
      appQuestion
    }
  },
  mounted () {
    this.exportCSVFile(this.$el, this.appQuestion, this)
  }
})
</script>

<style lang="scss" scoped>
::v-deep .dropdown {
  position: absolute;
  width: 100%;
  height: 100%;

  &__list-container {
    left: -110px;
    top: 100%;
    width: 160px;
    font-size: 14px;

    ::before {
      background-color: transparent;
      bottom: 100%;
      content: '';
      height: 12px;
      left: 0;
      position: absolute;
      width: 100%;
    }

    ::after {
      border-bottom: 8px solid #2b3839;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      bottom: 100%;
      content: '';
      left: 76%;
      position: absolute;
    }
  }

  &__link {
    line-height: 40px;
  }
}
</style>
