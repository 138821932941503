import { camelCase } from 'lodash'
/**
 * @typedef {import('vuex').MutationMethod} MutationMethod
 * @typedef {import('vuex').ActionMethod} ActionMethod
 */

/**
 * @template {string} S
 * @typedef {S extends Lowercase<S> ? S extends `${infer F}_${infer RF}${infer R}` ? `${F}${Uppercase<RF>}${CamelCase<R>}` : S : CamelCase<Lowercase<S>>} CamelCase
 */

/**
 * @type {<K extends string>(mutations: ({ [ key in K ]: MutationMethod })) => ({ [ key in CamelCase<K> ]: ActionMethod })}
 */
export const mapMutationsToActions = (mutations) => {
  const keys = Object.keys(mutations)
  return Object.fromEntries(
    keys.map((key) => [
      camelCase(key),
      ({ commit }, data) => commit(key, data)
    ])
  )
}
