import { render, staticRenderFns } from "./RootCauseResult.vue?vue&type=template&id=77db2980&scoped=true&"
import script from "./RootCauseResult.vue?vue&type=script&lang=js&"
export * from "./RootCauseResult.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77db2980",
  null
  
)

export default component.exports