<template>
  <div class="model-data-info">
    <div class="info-head">
      {{ info.title }}
    </div>
    <div class="info-body">
      <div
        class="insight-info-container"
        v-for="(value, key, index) in modelInfo"
        :key="`${key}-${index}`"
      >
        <div class="insight-info-title">
          {{ $t(`resultDescription.${key}`) }}
        </div>
        <div class="insight-info-content">
          {{ value }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DisplayModelInfo',
  props: {
    info: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    modelInfo () {
      return {
        totalDataRows: this.formatComma(this.nullCheck(this.info.dataTotalCount)),
        average: this.formatComma(this.nullCheck(this.info.avg)),
        sum: this.formatComma(this.nullCheck(this.info.sum)),
        max: this.formatComma(this.nullCheck(this.info.max)),
        min: this.formatComma(this.nullCheck(this.info.min)),
        median: this.formatComma(this.nullCheck(this.info.median)),
        std: this.formatComma(this.nullCheck(this.info.std))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.model-data-info {
	height: 100%;
	padding: 16px;
  background: #141C1D;
	box-sizing: border-box;
	border-radius: 8px;

	.info-head {
		font-weight: 700;
		color: #FFFFFF;
		line-height: 40px;
		padding: 10px 0 10px 15px;
	}

	.info-body {
		background: #141C1D;
		color: #CCCCCC;
		padding: 10px 15px;
		height: 85%;
		overflow: auto;

		.insight-info-container {
			line-height: 25px;
			width: 100%;
			margin-bottom: 10px;
			display: flex;

			.insight-info-title {
				min-width: 100px;
				margin-right: 20px;
				font-weight: 700;
			}

			.insight-info-content {
				font-weight: 400;
			}
		}
	}
}
</style>
