export default {
  // dataSource
  SET_APP_QUESTION (state, data) {
    state.appQuestion = data
  },
  SET_IS_MANUALLY_TRIGGERED_ASK_QUESTION (state, data) {
    state.isManuallyTriggeredAskQuestion = data
  },
  SET_HISTORY_QUESTION_LIST (state, data) {
    state.historyQuestionList = data
  },
  SET_CURRENT_QUESTION_INFO (state, data) {
    state.currentQuestionInfo = data
  },
  SET_CURRENT_QUESTION_ID (state, data) {
    state.currentQuestionId = data
  },
  SET_CURRENT_QUESTION_DATA_FRAME_ID (state, data) {
    state.currentQuestionDataFrameId = data
  },
  // chatBot
  SET_IS_USE_ALGORITHM (state, data) {
    state.isUseAlgorithm = data
  },
  SET_DO_DRILL_DOWN (state, data) {
    state.doDrillDown = data
  },
  SET_DO_CLICK_CORRELATION (state, data) {
    state.doClickCorrelation = data
  },
  SET_PARSER_LANGUAGE_PROMISE (state, data) {
    state.parserLanguagePromise = data
  },
  SET_PARSER_LANGUAGE_LIST (state, data) {
    state.parserLanguageList = data
  },
  SET_PARSER_LANGUAGE (state, data) {
    state.parserLanguage = data
  },
  SET_HAS_BASIC_INFO (state, data) {
    state.hasBasicInfo = data
  },
  SET_COPIED_COLUMN_NAME (state, data) {
    state.copiedColumnName = data
  },
  // dataFrameAdvanceSetting
  SET_IS_INIT (state, data) {
    state.isInit = data
  },
  SET_IS_SHOW_SETTING_BOX (state, data) {
    state.isShowSettingBox = data
  },
  SET_COLUMN_LIST (state, data) {
    state.columnList = data
  },
  SET_LAST_TIME_SELECTED_COLUMN_LIST (state, data) {
    state.lastTimeSelectedColumnList = data
  },
  SET_FILTER_LIST (state, data) {
    state.filterList = data
  },
  SET_STATUS_LIST (state, data) {
    state.filterList.forEach((filter, index) => {
      state.filterList[index].status = data[index]
    })
  },
  SET_DISPLAY_SECTION (state, data) {
    state.displaySection = data
  },
  SET_SHOULD_ADVANCE_DATA_FRAME_SETTING_REFETCH_DATA_COLUMN (state, value) {
    state.shouldAdvanceDataFrameSettingRefetchDataColumn = value
  }
}
