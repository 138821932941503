
import { compileMiniApp } from '@/utils/backwardCompatibilityCompiler.js'
import v1_0_1 from './v1-0-1.js'
import v2_0_0 from './v2-0-0.js'
import v2_0_1 from './v2-0-1.js'
import v2_0_2 from './v2-0-2.js'
import v2_0_3 from './v2-0-3.js'
import v2_0_4 from './v2-0-4.js'
import v2_0_5 from './v2-0-5.js'
import v2_0_6 from './v2-0-6.js'
import v2_0_7 from './v2-0-7.js'

const versionList = ['1.0.0', '1.0.1', '2.0.0', '2.0.1', '2.0.2', '2.0.3', '2.0.4', '2.0.5', '2.0.6', '2.0.7']
/*
  NOTICE:
  1. miniAppList/Index 的 createMiniAppInfo 需要改 version
  2. miniAppList/Index 的 createMiniAppInfo 需要調整
  3. miniApp/miniApp/Index 的 componentTemplateFactory 需要調整
*/

const updateAppSettingVersion = (appInfo) => {
  let returnData = appInfo

  if (!returnData.settings.version) {
    const { updatedAppData, isDataChanged } = compileMiniApp(returnData)
    if (isDataChanged) returnData = updatedAppData

    returnData.settings.version = '1.0.0'
  }

  while (returnData.settings.version !== versionList[versionList.length - 1]) {
    const currentVersionIndex = versionList.indexOf(returnData.settings.version)
    const nextVersion = versionList[currentVersionIndex + 1]

    switch (nextVersion) {
      case '1.0.1':
        returnData = v1_0_1(returnData)
        break
      case '2.0.0':
        returnData = v2_0_0(returnData)
        break
      case '2.0.1':
        returnData = v2_0_1(returnData)
        break
      case '2.0.2':
        returnData = v2_0_2(returnData)
        break
      case '2.0.3':
        returnData = v2_0_3(returnData)
        break
      case '2.0.4':
        returnData = v2_0_4(returnData)
        break
      case '2.0.5':
        returnData = v2_0_5(returnData)
        break
      case '2.0.6':
        returnData = v2_0_6(returnData)
        break
      case '2.0.7':
        returnData = v2_0_7(returnData)
        break
    }

    returnData.settings.version = nextVersion
  }

  // returnData.settings.version = '1.0.1'
  return returnData
}

export { versionList, updateAppSettingVersion }
