import mutations from './mutations'

export default {
  namespaced: true,
  state: {
    paginationInfo: {
      currentPage: 0,
      totalPages: 0,
      totalItems: 0,
      itemPerPage: 20
    }
  },
  mutations
}
