import { getModelInfo } from '@/API/Model'
import { getModelList } from '@/API/NewAsk'

export default {
  getModelInfo ({ commit }, modelId) {
    return getModelInfo(modelId)
      .then(({ name, inUse, modelGeneratedType }) => {
        commit('setModelInfo', { modelId, name, modelGeneratedType, inUse: inUse })
      })
  },
  getModelList ({ commit, state, rootState }, { dataFrameId, modelId }) {
    return getModelList(dataFrameId, modelId)
      .then(modelList => {
        commit('setModelList', modelList)
        if (!rootState.dataSource.algoConfig.modelPrediction.modelId) {
          commit('dataSource/setAlgoConfigModelId', modelList[0].id, { root: true })
        }
      })
  }
}
