<template>
  <div
    class="dropdown"
    @mouseenter.self="toggleDropdownList('hover')"
    @mouseleave.self="toggleDropdownList('hover')"
  >
    <div
      class="dropdown__select"
      @click="toggleDropdownList('click')"
    >
      <slot name="display" />
    </div>
    <div
      :class="{
        'dropdown__list-container--show': isShowDropdownList,
        'dropdown__list-container--wider': isShowId
      }"
      class="dropdown__list-container"
    >
      <div
        v-if="$slots.inputBlock"
        class="input-block"
      >
        <slot name="inputBlock" />
      </div>
      <ul
        v-if="dataList.length"
        class="dropdown__list"
      >
        <li
          v-for="item in dataList"
          :key="item.id"
          :class="{
            'dropdown__item--loading': isLoading,
            'dropdown__item--disabled': item.disabled
          }"
          class="dropdown__item"
        >
          <a
            :class="{
              'dropdown__link--selected': isSelectedItem(item.id),
              'dropdown__link--point': hasBulletPoint,
              'dropdown__link--arrow': item.children
            }"
            href="javascript:void(0);"
            class="dropdown__link"
            @click="selectItem(item)"
          >
            <svg-icon
              v-if="item.icon"
              :icon-class="item.icon"
            />
            {{ item.name }}
            <span
              v-if="isShowId"
              class="id-block"
            > (ID:{{ item.id }})</span>
          </a>
          <div
            v-if="item.children && !item.disabled"
            class="dropdown__list-children"
            :class="{'dropdown__list-children--left': item.childrenPosition === 'left'}"
          >
            <ul class="dropdown__list">
              <li
                v-for="children in item.children"
                :key="children.id"
                :class="{ 'dropdown__item--loading': isLoading }"
                class="dropdown__item"
              >
                <a
                  :class="{
                    'dropdown__link--selected': isSelectedItem(children.id),
                    'dropdown__link--point': hasBulletPoint
                  }"
                  href="javascript:void(0);"
                  class="dropdown__link"
                  @click="selectItem(children)"
                >
                  {{ children.name }}
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
      <div
        v-show="!dataList.length"
        class="empty-data"
      >
        {{ $t('editing.emptyKey') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomDropdownSelect',
  props: {
    // hover 或 click
    trigger: {
      type: String,
      default: 'hover'
    },
    dataList: {
      type: Array,
      required: true
    },
    selectedId: {
      type: [Number, String],
      default: null
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    hasBulletPoint: {
      type: Boolean,
      default: true
    },
    isShowId: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isShowDropdownList: false
    }
  },
  mounted () {
    if (this.trigger === 'click') {
      document.addEventListener('click', this.autoCloseDropdownList)
    }
  },
  destroyed () {
    if (this.trigger === 'click') {
      document.removeEventListener('click', this.autoCloseDropdownList)
    }
  },
  methods: {
    toggleDropdownList (trigger) {
      if (trigger !== this.trigger) return
      this.isShowDropdownList = !this.isShowDropdownList
    },
    isSelectedItem (id) {
      return id === this.selectedId
    },
    selectItem (item) {
      if (this.isLoading || this.selectedId === item.id || item.disabled) return
      if (this.trigger === 'click') this.toggleDropdownList('click')
      this.$emit('select', item.id)
    },
    autoCloseDropdownList (event) {
      if (this.$el.contains(event.target) || !this.isShowDropdownList) return
      this.toggleDropdownList('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;

  &__list-container,
  &__list-children {
    background: #2b3839;
    border-radius: 5px;
    box-shadow: 0 4px 10px rgba(58, 178, 189, 0.5);
    left: 100%;
    position: absolute;
    top: 0;
    visibility: hidden;
    width: 207px;

    &--show {
      visibility: visible;
    }

    &--wider {
      width: 227px;
    }
  }

  &__list-children {
    top: 0;
    width: auto;

    &--left {
      transform: translate3d(-100%, 0, 0);
      left: 0;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  &__item {
    list-style-type: none;
    position: relative;

    &:not(:last-of-type) {
      border-bottom: 1px solid #394045;
    }

    &--loading {
      cursor: wait;
      opacity: 0.3;
      a {
        cursor: unset;
      }
    }

    &--disabled {
      cursor: not-allowed;
      opacity: 0.25;
      a {
        cursor: unset;
      }
    }

    &:hover {
      .dropdown__list-children {
        visibility: visible;
      }
    }

    .id-block {
      white-space: pre-wrap;
    }
  }

  &__link {
    align-items: center;
    color: #ccc;
    cursor: pointer;
    display: flex;
    line-height: 44px;
    overflow: hidden;
    padding: 0 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    .svg-icon {
      color: $theme-color-primary;
      height: 20px;
      margin-right: 0.5rem;
      width: 20px;
    }

    &--point {
      &::before {
        color: #687072;
        content: '\2022';
        display: inline-block;
        font-weight: bold;
        padding: 0 6px;
      }
    }

    &--arrow {
      &::after {
        border-bottom: 4px solid transparent;
        border-left: 4px solid #ccc;
        border-top: 4px solid transparent;
        content: '';
        position: absolute;
        right: 8px;
        top: calc(50% - 4px);
      }
    }

    &:hover,
    &--selected {
      color: #fff;

      &::before {
        color: #2ad2e2;
      }
    }
  }

  .input-block {
    padding: 0 12px;

    ::v-deep .input {
      background-color: #141c1d;
      border-bottom: none;
      border-radius: 5px;
      font-size: 14px;
      height: 30px;
      padding-bottom: 0;
      padding-left: 12px;

      &::placeholder {
        color: #888;
      }
    }
  }

  .empty-data {
    color: #aaa;
    font-size: 14px;
    line-height: 6;
    text-align: center;
  }
}
</style>
