import i18n from '@/lang/index.js'

export default {
  required () {
    return i18n.t('message.formColumnEmpty')
  },
  email () {
    return i18n.t('message.formMailWrong')
  },
  numeric () {
    return i18n.t('message.formNumberEmpty')
  },
  max (field, length) {
    return i18n.t('message.formCharacterOverMax', { max: length })
  },
  min (field, length) {
    return i18n.t('message.formCharacterOverMin', { min: length })
  },
  min_value (field, value) {
    return i18n.t('message.formValueOverMin', { min: value })
  },
  max_value (field, value) {
    return i18n.t('message.formValueUnderMax', { max: value })
  },
  requireOneNumeric (field) {
    return i18n.t('message.formNumericeOverOne')
  },
  confirmed () {
    return i18n.t('message.pleaseConfirmPassword')
  },
  letterSpace () {
    return i18n.t('message.formLetterSpaceEmpty')
  },
  letterDashUnderscore () {
    return i18n.t('message.formLetterDashUnderscore')
  },
  validUpperBound () {
    return i18n.t('message.upperBoundShouldBeLargerThanLowerBound')
  },
  validLowerBound () {
    return i18n.t('message.lowerBoundShouldBeSmallerThanUpperBound')
  },
  validDatetimeUpperBound () {
    return i18n.t('message.endTimeShouldBeLargerThanStartTime')
  },
  validDatetimeLowerBound () {
    return i18n.t('message.StartTimeShouldBeSmallerThanEndTime')
  },
  eitherOneIsRequired (field, params) {
    return i18n.t(`message.${field}`) + i18n.t('message.and') + i18n.t(`message.${params}`) + i18n.t('message.eitherOneIsRequired')
  },
  decimal (field, params) {
    if (params.length === 0) return i18n.t('message.formDecimal')
    return i18n.t('message.formDecimalWithMaxDecimalPointNumbers', { max: params[0] })
  },
  between (field, params) {
    return i18n.t('message.numericShouldBeBetween', { min: params[0], max: params[1] })
  },
  positiveInt () {
    return i18n.t('message.formPositiveInt')
  }
}
