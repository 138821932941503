import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import { ASKING_MODULE_NAMESPACE } from '@/modules/namespace'
import { mapMutationsToActions } from '@/store/helpers'
import { createUseStore } from '@/utils/composable/vuex'

export const askingModuleStore = {
  namespaced: true,
  state () {
    return {
      // dataSource
      appQuestion: null,
      currentQuestionInfo: null,
      currentQuestionId: null,
      currentQuestionDataFrameId: null,
      isManuallyTriggeredAskQuestion: false,
      historyQuestionList: [],
      // chatBot
      isUseAlgorithm: false,
      doDrillDown: false,
      doClickCorrelation: false,
      parserLanguagePromise: null,
      parserLanguage: null,
      parserLanguageList: [],
      hasBasicInfo: false,
      askCancelTokenList: [],
      copiedColumnName: null,
      // dataFrameAdvanceSetting
      isInit: false,
      isShowSettingBox: false,
      displaySection: 'column',
      columnList: null,
      lastTimeSelectedColumnList: null,
      filterList: [],
      shouldAdvanceDataFrameSettingRefetchDataColumn: false
    }
  },
  getters,
  mutations,
  actions: {
    ...mapMutationsToActions(mutations),
    ...actions
  }
}

export function useAskingModuleStore (extendNamespace, shouldGetParserList = true) {
  const useStore = createUseStore(
    `${extendNamespace}/${ASKING_MODULE_NAMESPACE}`,
    askingModuleStore
  )
  const storeModule = useStore()
  if (storeModule.parserLanguage.value === null && shouldGetParserList) {
    storeModule.getParserList()
  }

  return storeModule
}
