export default (data) => {
  const returnData = JSON.parse(JSON.stringify(data))

  // 2.0.0 版本 migrate 的時候沒注意，導致舊有 app 元件從非 table 轉換成 table 的時候會缺了 related 底下的 table 物件
  returnData.settings.editModeData.dashboards.forEach((dashboard) => {
    dashboard.components.forEach((component) => {
      if (['chart', 'text', 'index'].includes(component.type)) {
        if (component.settingConfig.related.table) return
        component.settingConfig.related.table = {
          active: false,
          triggerTarget: 'column',
          dashboardId: null,
          column: {
            columnId: null,
            columnAlias: null
          }
        }
      }
    })
  })

  return returnData
}
