
import '@/composition-api'
import Vue from 'vue'
import Rollbar from 'rollbar'
import VueGtm from '@gtm-support/vue2-gtm'
import VueKatex from 'vue-katex'
import { createApp } from '@vue/composition-api'

import router from '@/router'
import store from '@/store'
import i18n from '@/lang/index.js'
import mixin from '@/utils/mixins'

import { provideVuexStore } from '@/utils/composable/vuex'
import '@/vue-filters'
import '@/utils/globalEmitter'
import '@/icons'
import '@/element-ui'
import '@/vee-validate'
import '@/echarts'
import '@/global-components'
import '@/schedule-components'
import '@/global-directives'

import App from '@/App'

import '@/styles/App.scss' // 放後面，避免被覆蓋
import 'katex/dist/katex.min.css'

const PRODUCT_ENV = ['sygps.sis.ai', 'jarvix.sis.ai', 'jarvix.synergiesai.cn', 'sygps.synergiesai.cn']
const IS_PRODUCT_ENV = PRODUCT_ENV.some(envName => window.location.hostname === envName)

// ===== rollbar error tracking =====
Vue.prototype.$rollbar = new Rollbar({
  accessToken: 'f90f60f1fb114062a224d0a4d0be677d',
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: IS_PRODUCT_ENV,
  environment: window.location.hostname,
  payload: {
    client: {
      javascript: {
        code_version: '1.0',
        source_map_enabled: true,
        guess_uncaught_frames: true
      }
    },
    server: {
      host: window.location.host
    }
  }
})

Vue.config.productionTip = false
if (process.env.NODE_ENV === 'production') {
  Vue.config.errorHandler = (err, vm, info) => {
    vm.$rollbar.error(err)
  }
}

Vue.mixin(mixin)

// ==== 公式顯示 ====
Vue.use(VueKatex)

// ===== 掛載vue2-gtm =====
Vue.use(VueGtm, {
  id: IS_PRODUCT_ENV ? 'GTM-5VNRQ3X' : 'GTM-KVHDMC3', // Your GTM single container ID
  defer: false, // Defaults to false, so the script is loaded `async` by default
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  enabled: true,
  debug: true, // display console logs debugs
  loadScript: true, // load the GTM Script
  vueRouter: router, // Pass the router instance to automatically sync with router
  trackOnNextTick: false // call trackView in Vue.nextTick
})

// ===== Vue mount =====
/* eslint-disable no-new */
const app = createApp({
  i18n,
  store,
  router,
  setup () {
    provideVuexStore()
  },
  render: h => h(App)
})

app.mount('#app')
