import { getMiniAppInfo } from '@/API/MiniApp'
import { versionList } from '@/utils/miniAppSchemaMigration'

const migrationConfirm = async (miniAppId) => {
  if (!miniAppId) return false
  if (process.env.NODE_ENV !== 'production') {
    const res = await getMiniAppInfo(miniAppId)
    const isCurrentVersion = versionList.indexOf(res.settings.version) === (versionList.length - 1)
    if (!isCurrentVersion) {
      if (!window.confirm(`setting版本為 ${res.settings.version}，最新setting版本為 ${versionList[versionList.length - 1]}，請確認 public API 環境及 codebase，是否進行升版？`)) {
        return false
      }
    }
  }
  return true
}

export { migrationConfirm }
