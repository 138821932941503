export default (data) => {
  const returnData = JSON.parse(JSON.stringify(data))

  // 儲存 magicType 用以儲存圖表顯示方式
  returnData.settings.editModeData.dashboards.forEach((dashboard) => {
    dashboard.components.forEach((component) => {
      component.displayConfig.showLabelData = false
    })
  })

  return returnData
}
