import getters from './getters'
import mutations from './mutations'
import actions from './actions'

import { ASKING_MODULE_NAMESPACE, askingModuleStore } from '@/modules/shared/asking'
import { MINI_APP_MODULE_NAMESPACE } from '@/modules/namespace'
import { mapMutationsToActions } from '@/store/helpers'
import { createUseStore } from '@/utils/composable/vuex'

export const miniAppModuleStore = {
  namespaced: true,
  modules: {
    [ASKING_MODULE_NAMESPACE]: askingModuleStore
  },
  state () {
    return {
      isInitialized: false,
      miniAppList: {
        appBasicDtoList: [],
        appLimit: null,
        dashboardLimit: null
      },
      /**
       * @type {
          {
            id: number;
            type: 'import' | 'copy' | 'update'
          }[]
       * }
       */
      pendingAppInfoList: [],
      pollingTimeoutId: -1
    }
  },
  getters,
  mutations,
  actions: {
    ...mapMutationsToActions(mutations),
    ...actions
  }
}

export function useMiniAppModuleStore () {
  const useStore = createUseStore(
    MINI_APP_MODULE_NAMESPACE,
    miniAppModuleStore
  )
  return useStore()
}
