import { Message } from 'element-ui'
import { getMiniAppInfo, updateAppSetting, exportApp } from '@/API/MiniApp'
import { versionList, updateAppSettingVersion as miniAppSchemaMigration } from '@/utils/miniAppSchemaMigration'
import { migrationConfirm } from '@/modules/miniApp/composable/migrationConfirm'
import { toEnum } from '@/utils/general'
import i18n from '@/lang'

export async function migrateSettingVersion (id, version) {
  if (version && (versionList.indexOf(version) === versionList.length - 1)) return

  const miniAppInfo = await getMiniAppInfo(id)
  try {
    await updateAppSetting(id, miniAppSchemaMigration(miniAppInfo))
  } catch (err) {
    await updateAppSetting(id, miniAppInfo)
  }
}

export async function downloadExportedApp (id, name) {
  const res = await migrationConfirm(id)

  if (res) {
    Message({
      message: i18n.t('miniApp.notificationMessage.startExport', { appName: name }),
      type: 'success',
      duration: 3 * 1000,
      showClose: true
    })
    try {
      // update app version
      const miniAppInfo = await getMiniAppInfo(id)
      const version = miniAppInfo.settings?.version
      await migrateSettingVersion(id, version)

      // export app
      const { data } = await exportApp(id)
      const blob = new Blob([JSON.stringify(data)], { type: 'text/plain' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `${name}.jarvix`
      link.click()
    } catch (error) {
      console.log(error)
    }
  }
}

export const MiniAppStatus = toEnum({
  Enable: 'Enable',
  Process: 'Process',
  Fail: 'Fail'
})
