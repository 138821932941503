import { registerAskingModuleContext } from '@/modules/shared/asking'
import { MODEL_MODULE_NAMESPACE } from '@/modules/namespace'
import { defineContext } from '@/utils/composable/context'

export { MODEL_MODULE_NAMESPACE } from '@/modules/namespace'
export { modelModuleStore } from './store'

export const {
  registerContext: registerModelModuleContext,
  useContext: useModelModuleContext
} = defineContext({
  namespace: MODEL_MODULE_NAMESPACE,
  createContext () {
    const askingModuleContext = registerAskingModuleContext({
      extendNamespace: MODEL_MODULE_NAMESPACE
    })
    return {
      askingModuleContext
    }
  }
})
