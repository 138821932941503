import i18n from '@/lang/index.js'

export default {
  getCurrentProject (state) {
    return state.scheduleProjects.find(item => item.id === state.scheduleProjectId)
  },
  // 鈺齊排程
  isYKSchedule (state, getters, rootState, rootGetters) {
    const devEnv = ['jarvix.sp1.sis.ai', 'jarvix.qa2.sis.ai', 'jarvix.staging.sis.ai']
    return devEnv.some(env => env === window.location.hostname) || (window.location.hostname === 'jarvix.synergiesai.cn' && rootGetters['userManagement/getCurrentAccountId'] === 8)
  },
  // 惠州demo，待動模擬結果態欄位實作後要拔除
  isHuiZhouDemo (state) {
    return (window.location.hostname === 'jarvix.sp2.sis.ai' || window.location.hostname === 'localhost') && state.scheduleProjectId === 65
  },
  isDefaultProfile (state) {
    return state.profile.isDefault
  },
  isAccessListSchedule (state, getters, rootGetters) {
    const hostname = window.location.hostname
    const accountId = rootGetters['userManagement/getCurrentAccountId']
    return hostname === 'sygps.synergiesai.cn' && accountId === 6
  },
  priorityOptions () {
    return [
      { value: '1', label: i18n.t('schedule.job.highPriority') },
      { value: '2', label: i18n.t('schedule.job.secondPriority') },
      { value: '3', label: i18n.t('schedule.job.lowPriority') }
    ]
  },
  deliveryTableHeaderList (state, getters) {
    return [
      { title: 'order', name: i18n.t('schedule.simulation.orderResult.order'), width: '160' },
      { title: 'job', name: i18n.t('schedule.simulation.orderResult.job'), width: '160' },
      { title: 'targetDelivery', name: i18n.t('schedule.simulation.orderResult.targetDelivery') },
      { title: 'completeDate', name: i18n.t('schedule.simulation.orderResult.completeDate') },
      { title: 'startDate', name: i18n.t('schedule.simulation.orderResult.startDate') },
      { title: 'material', name: i18n.t('schedule.simulation.orderResult.material'), width: '220' },
      {
        title: 'product',
        name: getters.isHuiZhouDemo ? '機種' : i18n.t('schedule.simulation.orderResult.product'),
        width: '220'
      },
      { title: 'priority', name: i18n.t('schedule.simulation.orderResult.priority') },
      { title: 'quantity', name: i18n.t('schedule.simulation.orderResult.quantity'), align: 'right' },
      { title: 'cycleTime', name: i18n.t('schedule.simulation.orderResult.cycleTime'), width: '140', align: 'right' },
      { title: 'complete', name: i18n.t('schedule.simulation.orderResult.complete') }
    ]
  },
  detailTableHeaderList (state, getters) {
    return [
      { title: 'arriveTime', name: i18n.t('schedule.simulation.machineResult.arriveTime'), width: '160' },
      { title: 'checkinTime', name: i18n.t('schedule.simulation.machineResult.checkinTime'), width: '160' },
      { title: 'startTime', name: i18n.t('schedule.simulation.machineResult.startTime'), width: '160' },
      { title: 'endTime', name: i18n.t('schedule.simulation.machineResult.endTime'), width: '160' },
      { title: 'checkoutTime', name: i18n.t('schedule.simulation.machineResult.checkoutTime'), width: '160' },
      {
        title: 'equipment',
        name: getters.isHuiZhouDemo ? '線別' : i18n.t('schedule.simulation.machineResult.equipment')
      },
      { title: 'factorySite', name: i18n.t('schedule.simulation.machineResult.factorySite') },
      { title: 'job', name: i18n.t('schedule.simulation.machineResult.job'), width: '160' },
      { title: 'material', name: i18n.t('schedule.simulation.machineResult.material'), width: '220' },
      {
        title: 'operation',
        name: getters.isHuiZhouDemo ? '工段' : i18n.t('schedule.simulation.machineResult.operation')
      },
      { title: 'order', name: i18n.t('schedule.simulation.machineResult.order'), width: '160' },
      {
        title: 'product',
        name: getters.isHuiZhouDemo ? '機種' : i18n.t('schedule.simulation.machineResult.product'),
        width: '220'
      },
      { title: 'stage', name: i18n.t('schedule.simulation.machineResult.stage') },
      { title: 'task', name: i18n.t('schedule.simulation.machineResult.task') }
    ]
  },
  jobTableHeaderList (state, getters) {
    return getters.isYKSchedule ? [
      { title: 'generalNumber', name: i18n.t('schedule.simulation.yukiTable.generalNumber'), width: '' },
      { title: 'shortName', name: i18n.t('schedule.simulation.yukiTable.shortName'), width: '' },
      { title: 'subJob', name: i18n.t('schedule.simulation.yukiTable.subJob'), width: '150' },
      { title: 'deadline', name: i18n.t('schedule.simulation.yukiTable.deadline'), width: '' },
      { title: 'confirmDate', name: i18n.t('schedule.simulation.yukiTable.confirmDate'), width: '' },
      { title: 'delayDate', name: i18n.t('schedule.simulation.yukiTable.delayDate'), width: '' },
      { title: 'deadlineDate', name: i18n.t('schedule.simulation.yukiTable.deadlineDate'), width: '' },
      { title: 'size', name: i18n.t('schedule.simulation.yukiTable.size'), width: '' },
      { title: 'sizeCode', name: i18n.t('schedule.simulation.yukiTable.sizeCode'), width: '' },
      { title: 'quantity', name: i18n.t('schedule.simulation.yukiTable.quantity'), width: '' },
      { title: 'factory', name: i18n.t('schedule.simulation.yukiTable.factory'), width: '' },
      { title: 'depart', name: i18n.t('schedule.simulation.yukiTable.depart'), width: '' },
      { title: 'owner', name: i18n.t('schedule.simulation.yukiTable.owner'), width: '' },
      { title: 'acceptDate', name: i18n.t('schedule.simulation.yukiTable.acceptDate'), width: '' },
      { title: 'customerName', name: i18n.t('schedule.simulation.yukiTable.customerName'), width: '' },
      { title: 'shoeName', name: i18n.t('schedule.simulation.yukiTable.shoeName'), width: '180' },
      { title: 'codeNo', name: i18n.t('schedule.simulation.yukiTable.codeNo'), width: '180' },
      { title: 'head', name: i18n.t('schedule.simulation.yukiTable.head'), width: '' },
      { title: 'fullMold', name: i18n.t('schedule.simulation.yukiTable.fullMold'), width: '' },
      { title: 'mold', name: i18n.t('schedule.simulation.yukiTable.mold'), width: '' },
      { title: 'bottom', name: i18n.t('schedule.simulation.yukiTable.bottom'), width: '' },
      { title: 'cutter', name: i18n.t('schedule.simulation.yukiTable.cutter'), width: '' },
      { title: 'seasonCode', name: i18n.t('schedule.simulation.yukiTable.seasonCode'), width: '' },
      { title: 'deliveryNote', name: i18n.t('schedule.simulation.yukiTable.deliveryNote'), width: '' },
      { title: 'customerPo', name: i18n.t('schedule.simulation.yukiTable.customerPo'), width: '' },
      { title: 'color', name: i18n.t('schedule.simulation.yukiTable.color'), width: '180' },
      { title: 'paymentCompany', name: i18n.t('schedule.simulation.yukiTable.paymentCompany'), width: '' },
      { title: 'poCustomer', name: i18n.t('schedule.simulation.yukiTable.poCustomer'), width: '' },
      { title: 'planFactory', name: i18n.t('schedule.simulation.yukiTable.planFactory'), width: '' },
      { title: 'planStart', name: i18n.t('schedule.simulation.yukiTable.planStart'), width: '' },
      { title: 'planEnd', name: i18n.t('schedule.simulation.yukiTable.planEnd'), width: '' },
      { title: 'sum', name: i18n.t('schedule.simulation.yukiTable.sum'), width: '' },
      { title: 'cutQuantity', name: i18n.t('schedule.simulation.yukiTable.cutQuantity'), width: '' },
      { title: 'boxes', name: i18n.t('schedule.simulation.yukiTable.boxes'), width: '' },
      { title: 'receiptPlace', name: i18n.t('schedule.simulation.yukiTable.receiptPlace'), width: '' },
      { title: 'deliveryPlace', name: i18n.t('schedule.simulation.yukiTable.deliveryPlace'), width: '' },
      { title: 'productType', name: i18n.t('schedule.simulation.yukiTable.productType'), width: '' },
      { title: 'brand', name: i18n.t('schedule.simulation.yukiTable.brand'), width: '' },
      { title: 'bDeliveryDate', name: i18n.t('schedule.simulation.yukiTable.bDeliveryDate'), width: '' },
      { title: 'aDeliveryDate', name: i18n.t('schedule.simulation.yukiTable.aDeliveryDate'), width: '' },
      { title: 'bDate', name: i18n.t('schedule.simulation.yukiTable.bDate'), width: '210' },
      { title: 'aDate', name: i18n.t('schedule.simulation.yukiTable.aDate'), width: '210' },
      { title: 'sampleSize', name: i18n.t('schedule.simulation.yukiTable.sampleSize'), width: '' },
      { title: 't4Date', name: i18n.t('schedule.simulation.yukiTable.t4Date'), width: '' },
      { title: 'shortJob', name: i18n.t('schedule.simulation.yukiTable.shortJob'), width: '' }
    ] : state.dynamicJobTableColumns
  }
}
