<template>
  <result-insight-wrapper>
    <template #insight>
      <bar-chart-insight
        :dataset="dataset"
        :title="title"
        :is-show-legend="isShowLegend"
        :is-show-toolbox="isShowToolbox"
        :height="height"
      />
    </template>
  </result-insight-wrapper>
</template>
<script>
import ResultInsightWrapper from '@/components/display/ResultInsightWrapper'
import BarChartInsight from '@/components/display/BarChartInsight'

export default {
  name: 'DisplayDifferenceInsight',
  components: {
    ResultInsightWrapper,
    BarChartInsight
  },
  props: {
    dataset: {
      type: Object,
      default: () => {}
    },
    title: {
      type: Object,
      default: () => {}
    },
    isShowLegend: {
      type: Boolean,
      default: true
    },
    isShowToolbox: {
      type: Boolean,
      default: true
    },
    height: { type: String, default: '300px' }
  }
}
</script>
