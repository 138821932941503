import Vue from 'vue'
// import * as echarts from 'echarts' // 全部引入
import * as echarts from 'echarts/core'
import { BarChart, LineChart, PieChart, ScatterChart, BoxplotChart, MapChart, CustomChart, HeatmapChart, SankeyChart, LinesChart } from 'echarts/charts'
import { TooltipComponent, MarkLineComponent, LegendComponent, VisualMapComponent, DatasetComponent, ToolboxComponent, GeoComponent, BrushComponent, DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent } from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'
import { debounce } from 'lodash'
import {
  defineComponent, ref, watch, onMounted, onBeforeUnmount, onUnmounted, nextTick
} from '@vue/composition-api'
import diff from 'microdiff'

echarts.use([
  BarChart,
  LineChart,
  PieChart,
  ScatterChart,
  BoxplotChart,
  MapChart,
  CustomChart,
  HeatmapChart,
  SankeyChart,
  LinesChart,
  TooltipComponent,
  MarkLineComponent,
  LegendComponent,
  VisualMapComponent,
  DatasetComponent,
  ToolboxComponent,
  GeoComponent,
  BrushComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
  CanvasRenderer,
  MarkPointComponent
])

const ECharts = defineComponent({
  name: 'ECharts',
  props: {
    theme: {
      type: [String, Object],
      default: null
    },
    initOptions: {
      type: Object,
      default: null
    },
    option: {
      type: Object,
      default: null
    },
    updateOptions: {
      type: Object,
      default: null
    }
  },
  setup (props, { listeners }) {
    const echartsInstance = ref(null)
    const echartsRef = ref(null)

    const resize = debounce(() => {
      echartsInstance.value?.resize({ animation: { duration: 500 } })
    }, 300)

    const refresh = debounce(() => {
      // NOTICE: 短解，需要改進
      const chartOptions = { ...props.option }
      if (chartOptions?.toolbox?.tooltip) {
        chartOptions.toolbox.tooltip.formatter = function (param) {
          return '<div>' + param.title + '</div>' // 自定义的 DOM 结构
        }
      }

      echartsInstance.value?.setOption(chartOptions ?? {}, props.updateOptions)
    }, 300)

    let animationFrameId = -1
    const lastContainerSize = {
      width: 0,
      height: 0
    }
    function autoResize () {
      const { width, height } = echartsRef.value?.getBoundingClientRect()
      if (width !== lastContainerSize.width || height !== lastContainerSize.height) {
        resize()
        lastContainerSize.width = width
        lastContainerSize.height = height
      }
      animationFrameId = window.requestAnimationFrame(autoResize)
    }
    function startAutoResize () {
      if (animationFrameId === -1) {
        animationFrameId = window.requestAnimationFrame(autoResize)
      }
    }

    function stopAutoResize () {
      window.cancelAnimationFrame(animationFrameId)
      animationFrameId = -1
    }

    onMounted(async () => {
      echartsInstance.value = echarts.init(
        echartsRef.value,
        props.theme,
        {
          renderer: 'canvas',
          ...(props.initOptions ?? {})
        }
      )
      Object.entries(listeners).forEach(([event, callback]) => {
        echartsInstance.value.on(event, callback)
      })

      await nextTick()
      resize()
      refresh()
      startAutoResize()
    })

    onBeforeUnmount(() => {
      echartsInstance.value?.clear()
    })

    onUnmounted(() => {
      stopAutoResize()
      echartsInstance.value?.isDisposed() && echartsInstance.value?.dispose()
      echartsInstance.value = null
    })

    // auto setOption
    watch(
      [
        () => props.option,
        () => props.updateOptions
      ],
      (values, oldValues) => {
        if (diff(values, oldValues).length === 0) return
        resize()
        refresh()
      }
    )

    return {
      echartsInstance,
      echartsRef
    }
  },
  render (createElement) {
    return createElement(
      'div',
      {
        style: {
          width: '100%',
          height: '100%'
        }
      },
      [
        createElement(
          'div',
          {
            ref: 'echartsRef',
            style: {
              width: '100%',
              height: '100%'
            }
          }
        )
      ]
    )
  }
})

Vue.component('VEchart', ECharts)
