<template>
  <div class="model-task">
    <spinner
      v-if="loading"
      class="task-spinner"
    />
    <no-result
      v-else-if="isError"
      :message="errorMessage"
    />
    <template v-else-if="diagram">
      <component
        :is="componentName"
        :dataset="componentData.dataset"
        :info="componentData"
        :title="componentData.title"
        :coefficients="componentData.coeffs"
        :training-type="trainingType"
        :height="height"
        :width="width"
        :direction="direction"
        :is-show-toolbox="isShowToolbox"
        :is-warning-enable="isWarningEnable"
        :is-show-legend="isShowLegend"
        :is-smooth="isSmooth"
        :is-show-formula="isShowFormula"
        :is-show-label-data="isShowLabelData"
        :handler-function="handlerFunction"
      />
    </template>
  </div>
</template>

<script>
import { postComponentData } from '@/API/Task'

export default {
  name: 'ModelTask',
  props: {
    intend: {
      type: String,
      default: null
    },
    componentId: {
      type: Number,
      default: null
    },
    trainingType: {
      type: String,
      default: null
    },
    isShowToolbox: {
      type: Boolean,
      default: true
    },
    isWarningEnable: {
      type: Boolean,
      default: true
    },
    isShowLegend: {
      type: Boolean,
      default: true
    },
    isSmooth: {
      type: Boolean,
      default: false
    },
    isShowCoefficients: {
      type: Boolean,
      default: false
    },
    isShowFormula: {
      type: Boolean,
      default: true
    },
    isShowLabelData: {
      type: Boolean,
      default: false
    },
    height: {
      type: [String, Number],
      default: '420px'
    },
    width: {
      type: String,
      default: '100%'
    },
    direction: {
      type: String,
      default: 'vertical'
    }
  },
  data () {
    return {
      loading: true,
      isError: false,
      errorMessage: null,
      timeoutFunction: null,
      diagram: null,
      componentName: null,
      componentData: null,
      handlerFunction: null
    }
  },
  mounted () {
    this.fetchData()
  },
  destroyed () {
    if (this.timeoutFunction) window.clearTimeout(this.timeoutFunction)
  },
  methods: {
    fetchData () {
      window.clearTimeout(this.timeoutFunction)
      postComponentData(this.componentId)
        .then(response => {
          switch (response.status) {
            case 'Process':
            case 'Ready':
              this.timeoutFunction = window.setTimeout(() => { this.fetchData() }, 1000)
              break
            case 'Complete':
              window.clearTimeout(this.timeoutFunction)
              this.diagram = response.diagram
              this.componentName = this.getChartTemplate(this.diagram)
              this.componentData = response.data
              this.handlerFunction = response.handlerFunction
              this.loading = false

              if (response.handlerFunction === 'MODEL_PERFORMANCE_PREDICT_EXPLANATION_CHART' && !this.componentData.dataset.data.length) {
                this.isError = true
                this.errorMessage = this.$t('modelExplanation.noModelSupported')
              }
              break
            case 'Disable':
            case 'Delete':
            case 'Warn':
            case 'Fail':
              window.clearTimeout(this.timeoutFunction)
              this.loading = false
              this.isError = true
              this.errorMessage = response.errorMessage
              break
          }
        }).catch(() => {
          this.loading = false
          this.isError = true
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.model-task {
  position: relative;
  height: 100%;
  width: 100%;
  .task-spinner {
    height: 340px;
  }
}
</style>
