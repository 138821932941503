<template>
  <div
    :class="{'task-mask': isGetPagination}"
    :data-component-id="componentId"
    :data-result-id="resultId"
    :data-diagram-type="diagram"
    :data-intend="intend"
    class="task"
  >
    <spinner
      v-if="loading"
      :class="{'key-result-spinner': intend === 'key_result'}"
      class="task-spinner"
    />
    <no-result
      v-else-if="isError"
      :message="errorMessage"
    />
    <template v-else-if="diagram">
      <button
        v-if="isPinboardPage &&
          componentData.dataset &&
          componentData.dataset.allowMonitorConfig"
        type="button"
        class="btn-m btn-secondary btn-monitor"
        @click="openMonitorSettingDialog"
      >
        {{ $t('button.monitorSetting') }}
      </button>
      <div class="task__guide">
        <BeginnerGuide
          v-if="isDrillDownGuideShow"
          :content="$t('beginnerGuide.chartDrillDown')"
        />
      </div>
      <!-- TODO: 調整寫法 -->
      <component
        :is="componentName"
        v-bind="componentInfo"
        :component-id="componentId"
        :can-download-csv="canDownloadCsv"
        :diagram="diagram"
        :has-pagination="hasNextPage"
        :handler-function="handlerFunction"
        :is-sorted="isSorted"
        :key="componentId"
        :is-show-toolbox="isShowToolbox"
        :custom-chart-style="customChartStyle"
        :arrow-btn-right="arrowBtnRight"
        :is-show-label-data="showLabelData"
        :is-show-description="isShowDescription"
        :is-show-coefficients="isShowCoefficients"
        :is-show-model-table="isShowModelTable"
        :is-show-download-btn="isShowDownloadBtn"
        :custom-cell-class-name="customCellClassName"
        :is-hoverable="isHoverable"
        :is-show-legend="isShowLegend"
        :custom-mark-line="customMarkLine"
        :chart-toolbox-setting="chartToolboxSetting"
        :is-series-stack="isSeriesStack"
        class="task-component"
        @next="nextPage($event)"
        @toggleLabel="toggleLabel"
        @clickCell="$emit('clickCell', $event)"
        @clickRow="$emit('clickRow', $event)"
        @clickChart="$emit('clickChart', $event)"
        @updateStackStatus="$emit('updateStackStatus', $event)"
        @magicTypeChanged="$emit('magicTypeChanged', $event)"
        @sort="sortData"
      />
      <div
        v-for="(note, index) in notes"
        :key="index"
        class="task-note"
      >
        {{ note }}
      </div>
    </template>
    <monitor-setting-dialog
      v-if="isShowMonitorSettingDialog"
      :component-id="componentId"
      :data-frame-id="dataFrameId"
      @confirm="updateMonitorSetting"
      @close="closeMonitorSettingDialog"
    />
  </div>
</template>
<script>
import MonitorSettingDialog from '@/pages/pinboard/components/MonitorSettingDialog'
import { formatComponentTitle } from '@/components/display/common/addons'
import { mapMutations, mapActions, mapState } from 'vuex'
import { defineComponent } from '@vue/composition-api'
import { useAskingModuleContext } from '@/modules/shared/asking'
import globalEmitter from '@/utils/globalEmitter'
import BeginnerGuide from '@/components/BeginnerGuide'

export default defineComponent({
  name: 'Task',
  components: {
    MonitorSettingDialog,
    BeginnerGuide
  },
  props: {
    dataFrameId: {
      type: Number,
      default: null
    },
    componentId: {
      type: Number,
      default: null
    },
    uuid: {
      type: String,
      default: null
    },
    // 目前暫時只有加在 app 用到的 DashboardComponent / DashboardTask
    segmentation: {
      type: Object,
      default: () => ({})
    },
    customMarkLine: {
      type: Array,
      default: null
    },
    intend: {
      type: String,
      default: null
    },
    isShowToolbox: {
      type: Boolean,
      default: true
    },
    isShowLegend: {
      type: Boolean,
      default: true
    },
    isShowDescription: {
      type: Boolean,
      default: true
    },
    isShowCoefficients: {
      type: Boolean,
      default: true
    },
    isShowModelTable: {
      type: Boolean,
      default: false
    },
    isShowDownloadBtn: {
      type: Boolean,
      default: true
    },
    customChartStyle: {
      type: Object,
      default: () => ({})
    },
    arrowBtnRight: {
      type: Number,
      default: 80
    },
    convertedType: {
      type: String,
      default: null
    },
    customCellClassName: {
      type: Array,
      default: () => []
    },
    isHoverable: {
      type: Boolean,
      default: false
    },
    anomalySetting: {
      type: Object,
      default: () => ({
        xAxis: {
          upperLimit: null,
          lowerLimit: null
        },
        yAxis: {
          upperLimit: null,
          lowerLimit: null
        }
      })
    },
    chartToolboxSetting: {
      type: Object,
      default: undefined
    },
    isSeriesStack: {
      type: Boolean,
      default: false
    },
    isShowLabelData: {
      type: Boolean,
      default: false
    },
    isMiniappOrderDesc: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const { getComponentData } = useAskingModuleContext()
    return {
      getComponentData
    }
  },
  data () {
    return {
      resultId: null,
      diagram: null,
      originDiagram: null,
      loading: true,
      canDownloadCsv: false,
      componentName: null,
      componentData: null,
      isError: false,
      errorMessage: '',
      notes: [],
      timeoutFunction: null,
      autoRefreshFunction: null,
      displayConfig: null,
      currentPage: 0,
      isGetPagination: false,
      // 是否有下一頁資料
      hasNextPage: false,
      maxDataLengthPerPage: 200,
      isShowMonitorSettingDialog: false,
      totalCount: null,
      handlerFunction: null,
      isSorted: false,
      showLabelData: this.isShowLabelData,
      isFirst3DPage: null
    }
  },
  computed: {
    ...mapState(['isGuideMode']),
    isFirstPage () {
      return this.handlerFunction.includes('THREE_DIMENSION') ? this.isFirst3DPage : this.currentPage === 0
    },
    isPinboardPage () {
      return this.$route.name === 'PersonalPagePinboard' || this.$route.name === 'ProjectPagePinboard'
    },
    isDrillDownGuideShow () {
      // NOTICE: 已經沒用到的：DisplayBasicChart, displayGroupLineChart, displayHistogramChart, displayStackBarChart, DisplayStackLineChart
      return [
        'DisplayBarChart',
        'DisplayBubbleScatterChart',
        'DisplayCompositionLineChart',
        'DisplayComputedHistogramChart',
        'DisplayLineChart',
        'DisplayLineConfidentialIntervalChart',
        'DisplayMultiAxisBarChart',
        'DisplayMultiAxisLineChart',
        'DisplayParallelBarChart',
        'DisplayPredictChart',
        'DisplayScatterChart',
        'DisplayScatterProbabilityDensityChart',
        'DisplayScatterClusterChart'
      ].includes(this.componentName) && this.isGuideMode && this.$route.name === 'PageResult'
    },
    componentInfo () {
      const componentData = this.componentData
      return {
        dataset: this.componentDataDataset,
        title: componentData.title,
        message: componentData.message,
        segmentation: componentData.segmentation,
        info: componentData.info || componentData.data || componentData,
        features: componentData.features,
        confidence: componentData.confidence,
        formula: componentData.displayCoefficients,
        coefficients: componentData.coeffs,
        'coefficient-line-type': componentData.coefficientLineType,
        text: componentData.text,
        'chart-data': componentData.chart_data,
        notes: componentData.notes,
        causes: componentData.causes,
        description: componentData.description,
        total: componentData.total,
        'item-count': componentData.item_count,
        'cluster-infos': componentData.clusterInfos,
        'sub-components': componentData.subComponents
      }
    },
    componentDataDataset () {
      const warnings = this.componentData.dataset?.warnings ?? []
      // 解決當詢問 2N1C 例:每日 N_int_null_01 n_int c_nchar 筆數可能小於200
      // 分為 dataLength用來判斷比數 以及 rawDataLength計算所有非null的數值用來顯示筆數
      let dataLength = 0
      let rawDataLength = 0

      // 計算資料筆數
      const baseDataType = [
        'line_chart',
        'parallel_bar_chart',
        'predict_line_chart',
        'bar_chart',
        'bar_3d',
        'multi_axis_line_chart'
      ]
      if (baseDataType.includes(this.diagram)) {
        // 直條、折線、平行直條、3D直條 分析
        const { data, columns, display_columns } = this.componentData.dataset
        const columnLength = display_columns ? display_columns.length : columns.length
        dataLength = data.length * columnLength
        // 非null的筆數
        data.forEach(arr => arr.forEach(node => {
          if (node !== null) {
            rawDataLength++
          }
        }))
      } else if (this.diagram === 'periodic_line_charts') {
        // 週期性分析
        dataLength = this.componentData.subComponents.reduce((pre, curr) => {
          return pre += curr.dataset.data.length
        }, 0)
        rawDataLength = dataLength
      } else if (this.diagram === 'line_confidential_interval_chart') {
        // 異常分析
        dataLength = this.componentData.dataset.data.length
        rawDataLength = dataLength
      }
      // numeric 欄位數量，為了多 N 趨勢每個 N 200 筆的限制，要注意有可能 denotation 是 TREND 結果出 bar chart，所以要確保最小為 1
      const numericColumnCount = this.segmentation?.sentence && this.isMiniappOrderDesc ? (this.segmentation?.sentence.reduce((acc, cur) => cur?.statsType === 'NUMERIC' ? acc += 1 : acc, 0) || 1) : 1
      // 判斷顯示提示類型
      if (this.hasNextPage) {
        return {
          ...this.componentData.dataset,
          warnings: [this.$t('notification.dataAmountLimitLoadMoreNotification', { num: rawDataLength }), ...warnings]
        }
      } else if (this.isMiniappOrderDesc && this.isFirstPage && dataLength >= (200 * numericColumnCount)) {
        // 為了判斷在miniApp趨勢圖比數被限制的情況
        return {
          ...this.componentData.dataset,
          warnings: [this.$t('notification.dataAmountLimitNotification', { num: rawDataLength }), ...warnings]
        }
      }
      return this.componentData.dataset
    }
  },
  mounted () {
    this.handleTaskInitData()
  },
  destroyed () {
    if (this.timeoutFunction) window.clearTimeout(this.timeoutFunction)
    if (this.autoRefreshFunction) window.clearTimeout(this.autoRefreshFunction)
  },
  methods: {
    ...mapActions('gtm', ['trackAskQuestion']),
    ...mapMutations('dataSource', ['setClusteringInfo', 'resetClusteringInfo']),
    fetchData () {
      return new Promise((resolve, reject) => {
        window.clearTimeout(this.timeoutFunction)
        this.getComponentData({
          id: this.componentId,
          page: this.currentPage,
          displayConfig: this.displayConfig
        }).then(response => {
          // 是否有下一頁，singlePage 為 false 代表還有下一頁
          this.hasNextPage = !response.singlePage
          if (response.isAutoRefresh) {
            this.hasNextPage = false
          }
          switch (response.status) {
            case 'Process':
            case 'Ready':
              this.timeoutFunction = window.setTimeout(() => {
                this.fetchData().then(task => {
                  resolve(task)
                })
              }, 1000)
              break
            case 'Complete': {
              window.clearTimeout(this.timeoutFunction)
              this.handlerFunction = response.handlerFunction
              this.diagram = response.diagram
              this.originDiagram = response.diagram
              this.hasNextPage = !this.handlerFunction.includes('THREE_DIMENSION')
                ? !response.singlePage
                : !(response.data.dataset.isXSinglePage && response.data.dataset.isYSinglePage)

              this.resultId = response.resultId
              this.canDownloadCsv = response.supportedFunction ? response.supportedFunction.canDownloadCsv : response.canDownloadCsv
              this.componentName = this.getChartTemplate(this.convertedType || this.diagram)
              this.displayConfig = response.displayConfig
              let responseData = response.data
              this.isFirst3DPage = this.handlerFunction.includes('THREE_DIMENSION') ? this.displayConfig.pageInfo.xPage === 0 && this.displayConfig.pageInfo.yPage === 0 : false

              // 元件屬於 key_result 且有事件要被追蹤時，才指派對應的track function 蒐集 params
              if (this.intend === 'key_result') {
                this.trackAskQuestion({ diagram: this.diagram })
              }
              // 推薦洞察 需要將 question 傳給外層組件顯示用
              if (responseData.question) {
                this.$emit('setQuestion', responseData.question)
              }
              // miniApp 需要將 diagram 傳給外層以顯示不同新增元件設定項
              this.$emit('setDiagram', response.diagram)

              // component 設定資訊
              // 舊資料內可能不含 supportedFunction，這邊做上下兼容處理
              let enableAlert = response.supportedFunction ? response.supportedFunction.analysisInfo.enableAlert : (response.enableAlert || false)
              this.$emit('setConfig', {
                enableAlert,
                // 2N 異常設定示警需要 x 軸欄位資訊
                ...((enableAlert && responseData.title) && {
                  xAxis: responseData.title.xAxis && responseData.title.xAxis.filter(xAxis => xAxis.dc_id),
                  yAxis: responseData.title.yAxis
                }),
                // 元件設定資訊集
                supportedFunction: response.supportedFunction
              })

              // 圖表異常標記設定和轉換
              // model info會不小心走進這裡 先多加一個中位數做排除
              if (responseData.title && !responseData.columnData && !responseData.median) {
                responseData.title.xAxis = formatComponentTitle(responseData.title.xAxis, this.anomalySetting.xAxis)
                responseData.title.yAxis = formatComponentTitle(responseData.title.yAxis, this.anomalySetting.yAxis)
              }

              let isAutoRefresh = response.isAutoRefresh
              if (isAutoRefresh && this.isPinboardPage) {
                this.autoRefreshFunction = window.setTimeout(() => {
                  this.autoRefreshData()
                }, 60 * 1000)
              }
              // 取樣
              if (responseData.sampling) {
                this.appendNote(this.genSamplingNote(responseData.sampling))
              }
              // 取前 n 筆
              if (responseData.group_limit) {
                this.appendNote(this.genGroupLimitNote(responseData.group_limit))
              }
              // 判斷是否為 圖表
              if (responseData.dataset) {
                const chartDataset = responseData.dataset
                // 分群分析
                if (chartDataset.columns && chartDataset.outliersBuckets) {
                  this.setClusteringInfo({
                    clusterList: chartDataset.columns,
                    hasOutlier: chartDataset.outliersBuckets.length > 0
                  })
                }

                // 如果拿到的資料為空陣列 表示這一頁已經是最後一頁了。或是 total 為 0 代表 pie chart 沒有資料
                if (
                  chartDataset.data === null ||
                  (
                    chartDataset.data &&
                    (
                      chartDataset.data.length === 0 ||
                      responseData.total === 0
                    )
                  ) ||
                  (
                    // 當 sankey chart
                    chartDataset?.data?.constructor === Object && !this.getDeepObjectValue(chartDataset?.data).length
                  )
                ) {
                  this.loading = false
                  this.hasNextPage = false
                  this.isGetPagination = false
                  // 第一次進來空資料的處理
                  if (this.isFirst3DPage || this.currentPage === 0) {
                    this.isError = true
                    this.errorMessage = this.$t('message.emptyResult')
                    this.$emit('isEmpty')
                  }
                } else {
                  resolve(responseData)
                }
              } else {
                // 圖表以外的 task
                this.componentData = responseData
                this.hasNextPage = false
                this.loading = false
              }
              break
            }
            case 'Disable':
            case 'Delete':
            case 'Warn':
            case 'Fail':
              window.clearTimeout(this.timeoutFunction)
              this.loading = false
              this.isGetPagination = false
              // 推薦洞察 若 component Fail 要從外層將組件隱藏起來
              this.$emit('failed')
              // 如果取分頁資料 fail，當作無資料來處理
              if ((this.handlerFunction?.includes('THREE_DIMENSION') && !this.isFirst3DPage) || this.currentPage > 0) {
                this.hasNextPage = false
                this.isGetPagination = false
              } else {
                if (this.intend === 'key_result') {
                  this.isError = true
                  this.errorMessage = response.errorMessage
                  // getComponeneResponse出狀況，不算問成功、diagram渲染失敗
                  this.trackAskQuestion({ diagram: 'fail', isAskQuestionSuccess: false })
                }
              }
              break
          }
        }).catch((err) => {
          this.errorMessage = err?.error?.message || null
          this.loading = false
          this.$emit('failed')
          if (this.intend === 'key_result') {
            if (this.isFirst3DPage || this.currentPage === 0) {
              this.isError = true
            }
            this.isGetPagination = false
            this.hasNextPage = false
            // getComponeneResponse失敗，不算問成功、diagram 渲染失敗
            this.trackAskQuestion({ diagram: 'fail', isAskQuestionSuccess: false })
          }
        })
          .finally(() => this.$emit('finished'))
      })
    },
    handleTaskInitData () {
      this.fetchData()
        .then(taskData => {
          this.componentData = taskData
        })
        .finally(() => {
          this.isGetPagination = false
          this.loading = false
        })
    },
    nextPage (axisPage = null) {
      let count = 0
      let anotherAxis
      if (!axisPage) {
        this.currentPage += 1
      } else {
        this.displayConfig.pageInfo[axisPage] += 1
        anotherAxis = Array.from(['xPage', 'yPage']).find((axis) => axis !== axisPage)
        count = this.displayConfig.pageInfo[anotherAxis]
      }
      this.getNextPageData(count, anotherAxis)
    },
    async getNextPageData (pageCount, anotherAxis = null) {
      this.isGetPagination = true
      try {
        for (let i = 0; i <= pageCount; i++) {
          if (anotherAxis) {
            this.displayConfig.pageInfo[anotherAxis] = i
          }
          const taskData = await this.fetchData()
          // 將下一頁的資料塞進去
          this.updateChartData(taskData)
        }
        this.isGetPagination = false
      } catch (e) {
        console.log(e)
      }
    },
    updateChartData (taskData) {
      // 分頁的資料 push 進去
      if (this.diagram === 'line_chart') {
        let indexLength = this.componentData.dataset.index.length

        // 合併 columns
        let concatColumns = [...new Set([...this.componentData.dataset.columns, ...taskData.dataset.columns])]

        // 檢查舊有資料需不需要補值
        if (concatColumns.length > this.componentData.dataset.columns.length) {
          let lengthDiff = concatColumns.length - this.componentData.dataset.columns.length
          this.componentData.dataset.data.forEach(element => {
            for (let i = 0; i < lengthDiff; i++) {
              element.push(null)
            }
          })
        }
        // 更新 columns
        this.componentData.dataset.columns = concatColumns

        // 處理 display_columns
        if (taskData.dataset.display_columns) {
          // 合併 columns
          let concatDisplayColumns = [...new Set([...this.componentData.dataset.display_columns, ...taskData.dataset.display_columns])]
          // 更新 columns
          this.componentData.dataset.display_columns = concatDisplayColumns
        }

        /**
         * 判斷需不需要銜接資料，舊的最後一筆跟新的第一筆一樣時間的話
         **/
        if (this.componentData.dataset.index[this.componentData.dataset.index.length - 1] === taskData.dataset.index[0]) {
          /**
           * 需要銜接資料
           * 可能前一頁的最後一列資料沒有拿完，因此要補最後一行的資料
           **/
          // 針對前一頁最後一個陣列的資料補上新資料第一個陣列的值
          let firstColumnData = taskData.dataset.data[0]
          // 舊資料最後一列
          let lastColumnData = this.componentData.dataset.data[indexLength - 1]
          // 新資料的 column 對應 index
          let columnIndexArray = []
          for (let i = 0; i < firstColumnData.length; i++) {
            let columnIndex = this.componentData.dataset.columns.findIndex(element => element === taskData.dataset.columns[i])
            columnIndexArray.push(columnIndex)
            if (firstColumnData[i] !== null) {
              this.$set(lastColumnData, columnIndex, firstColumnData[i])
            }
          }
          /**
           * TODO: 這邊待整理，這邊怕整理了之後失去了原本的邏輯
           */
          if (taskData.dataset.data.length > 1) {
            // 新資料移除第一列
            taskData.dataset.data.shift()
            // 接上新資料
            let newDatasetData = [...Array(this.componentData.dataset.columns.length)].map(element => null)
            taskData.dataset.data = taskData.dataset.data.map(element => {
              let arrayData = JSON.parse(JSON.stringify(newDatasetData))
              for (let i = 0; i < element.length; i++) {
                this.$set(arrayData, columnIndexArray[i], element[i])
              }
              return arrayData
            })

            this.componentData.dataset.data = this.componentData.dataset.data.concat(taskData.dataset.data)
          }

          if (taskData.dataset.index.length !== 1) {
            // index 更新
            taskData.dataset.index.shift()
            this.componentData.dataset.index = this.componentData.dataset.index.concat(taskData.dataset.index)
            if (taskData.dataset.display_index) {
              taskData.dataset.display_index.shift()
              this.componentData.dataset.display_index = this.componentData.dataset.display_index.concat(taskData.dataset.display_index)
            }

            if (taskData.dataset.timeStampList) {
              taskData.dataset.timeStampList.shift()
              this.componentData.dataset.timeStampList = this.componentData.dataset.timeStampList.concat(taskData.dataset.timeStampList)
            }
          }
        } else {
          /**
           * 不用銜接
           **/
          // 找出新 column 對應到舊 column 的 index
          let columnIndexArray = []
          for (let i = 0; i < taskData.dataset.columns.length; i++) {
            let columnIndex = this.componentData.dataset.columns.findIndex(element => element === taskData.dataset.columns[i])
            columnIndexArray.push(columnIndex)
          }
          // 新資料的空值矩陣，處理新 column 可能長度不一致的問題
          let newDatasetData = [...Array(this.componentData.dataset.columns.length)].map(element => null)
          // 接上新資料
          taskData.dataset.data = taskData.dataset.data.map(element => {
            let arrayData = JSON.parse(JSON.stringify(newDatasetData))
            for (let i = 0; i < element.length; i++) {
              this.$set(arrayData, columnIndexArray[i], element[i])
            }
            return arrayData
          })
          this.componentData.dataset.data = this.componentData.dataset.data.concat(taskData.dataset.data)

          // index 更新
          this.componentData.dataset.index = this.componentData.dataset.index.concat(taskData.dataset.index)
          if (this.componentData.dataset.timeStampList) {
            this.componentData.dataset.timeStampList = this.componentData.dataset.timeStampList.concat(taskData.dataset.timeStampList)
          }
          if (taskData.dataset.display_index) {
            this.componentData.dataset.display_index = this.componentData.dataset.display_index.concat(taskData.dataset.display_index)
          }
        }
      } else {
        this.componentData.dataset.data = this.componentData.dataset.data.concat(taskData.dataset.data)
        this.componentData.dataset.index = this.componentData.dataset.index.concat(taskData.dataset.index)
        if (this.componentData.dataset.timeStampList) {
          this.componentData.dataset.timeStampList = this.componentData.dataset.timeStampList.concat(taskData.dataset.timeStampList)
        }
        if (this.componentData.dataset.display_index) {
          this.componentData.dataset.display_index = this.componentData.dataset.display_index.concat(taskData.dataset.display_index)
        }
        if (this.componentData.dataset.predictDataList) {
          this.componentData.dataset.predictDataList = this.componentData.dataset.predictDataList.concat(taskData.dataset.predictDataList)
        }
        // 3d圖取分頁時，若任一軸有分頁的情況下要儲存狀態來決定xy擴展按鈕是否被disabled
        if (typeof taskData.dataset.isXSinglePage === 'boolean') {
          this.componentData.dataset.isXSinglePage = taskData.dataset.isXSinglePage
          this.componentData.dataset.isYSinglePage = taskData.dataset.isYSinglePage
          this.componentData.dataset.xPage = taskData.dataset.xPage
          this.componentData.dataset.yPage = taskData.dataset.yPage
        }
      }
    },
    appendNote (note) {
      this.notes.push(note)
    },
    genSamplingNote (randomLimit) {
      return this.$t('resultNote.samplingNote', { randomLimit })
    },
    genGroupLimitNote (randomLimit) {
      return this.$t('resultNote.groupLimitNote', { randomLimit })
    },
    openMonitorSettingDialog () {
      this.isShowMonitorSettingDialog = true
    },
    updateMonitorSetting () {
      this.closeMonitorSettingDialog()
      this.autoRefreshData()
    },
    autoRefreshData () {
      this.loading = true
      this.fetchData().then(taskData => {
        this.componentData = taskData
        this.loading = false
      })
    },
    closeMonitorSettingDialog () {
      this.isShowMonitorSettingDialog = false
    },
    toggleLabel () {
      this.showLabelData = !this.showLabelData
      this.$emit('toggleLabel', this.showLabelData)
    },
    getDeepObjectValue (val) {
      let value = Object.values(val)[0]
      if (!Array.isArray(value)) {
        return this.getDeepObjectValue(value)
      }
      return value
    },
    sortData (sortColumn) {
      this.displayConfig = sortColumn.sortType ? {
        sortOrders: [sortColumn]
      } : null

      this.isSorted = true
      this.currentPage = 0
      this.fetchData()
        .then(taskData => {
          this.componentData = taskData
          globalEmitter.emit('resetTablePagination')
        })
        .finally(() => {
          this.isGetPagination = false
          this.isSorted = false
        })
    }
  }
})
</script>
<style lang="scss" scoped>
.task {
  height: 100%;
  position: relative;
  width: 100%;

  .task-spinner.key-result-spinner {
    height: 420px;
  }

  .task-note {
    color: #a7a7a7;
    font-size: 12px;

    &::before {
      content: '#';
    }
  }

  &-component {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .btn-monitor {
    position: absolute;
    right: 0;
    top: 0;
  }

  // pagination 遮罩
  &.task-mask {
    animation: gradient 1s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    background: repeating-linear-gradient(to right, rgba(34, 64, 68, 0) 0%, rgba(34, 64, 68, 1) 50%, rgba(34, 64, 68, 0) 100%);
    background-position: 0 100%;
    background-size: 200% auto;
    position: relative;
    width: 100%;

    @keyframes gradient {
      0% { background-position: 0 0; }
      100% { background-position: -200% 0; }
    }

    // 遮罩避免使用者點擊到看更多
    &::before {
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 10;
    }
  }

  &__guide {
    padding-right: 5px;

    .beginner-guide {
      display: block;
      left: auto;
      margin-left: auto;
      position: relative;
      top: auto;
      transform: none;
    }
  }
}
</style>
