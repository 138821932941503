<template>
  <div class="schedule-radio-group">
    <div
      v-for="option in options"
      :key="option.key"
      :class="[optionDisplay === 'block' && 'is-block']"
      class="input-radio-group"
    >
      <div>
        <input
          :id="option.key"
          v-model="innerValue"
          :checked="option.key === innerValue"
          :value="option.key"
          class="input-radio"
          type="radio"
        >
        <label
          :for="option.key"
          class="input-radio-label"
        >
          <span>{{ option.label }}</span>
        </label>
      </div>
      <!-- custom content of the radio option -->
      <div class="input-radio-content">
        <slot :name="option.key" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScheduleRadioGroup',
  props: {
    options: {
      type: Array,
      default: () => [
        {
          value: [String, Number],
          label: String
        }
      ]
    },
    value: {
      type: [String, Number],
      default: null
    },
    optionDisplay: {
      type: String,
      default: 'inline-block'
    },
    onlyShowActiveOptionContent: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    innerValue: {
      get () {
        return this.value
      },
      set (newVal) {
        this.$emit('input', newVal)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.schedule-radio-group {
  .input-radio {
    &-group {
      &.is-block {
        display: block;
        margin-right: 0;
      }
    }

    &-content {
      padding-left: 28px;
    }
  }
}
</style>
