import { registerAskingModuleContext } from '@/modules/shared/asking'
import { PIN_BOARD_MODULE_NAMESPACE } from '@/modules/namespace'
import { defineContext } from '@/utils/composable/context'

export { PIN_BOARD_MODULE_NAMESPACE } from '@/modules/namespace'
export { pinBoardModuleStore } from './store'

export const {
  registerContext: registerPinBoardModuleContext,
  useContext: usePinBoardModuleContext
} = defineContext({
  namespace: PIN_BOARD_MODULE_NAMESPACE,
  createContext () {
    const askingModuleContext = registerAskingModuleContext({
      extendNamespace: PIN_BOARD_MODULE_NAMESPACE
    })
    return {
      askingModuleContext
    }
  }
})
