import Vue from 'vue'

import ModelTask from '@/pages/modelManagement/components/ModelTask'
import EmptyResult from '@/pages/result/components/EmptyResult'
import ParameterComparisonTable from '@/pages/miniApp/miniApp/components/dashboard-components/ParameterComparisonTable'
import Layout from '@/components/Layout.vue'
import Spinner from '@/components/Spinner'
import SyTable from '@/components/table/SyTable.vue'
import PreviewDataSource from '@/components/PreviewDataSource.vue'
import ResultBoard from '@/components/resultBoard/ResultBoard'
import ResultBoardBody from '@/components/resultBoard/ResultBoardBody'
import GeneralResult from '@/components/resultBoard/GeneralResult'
import PredictResult from '@/components/resultBoard/PredictResult'
import CorrelationExplorationResult from '@/components/resultBoard/CorrelationExplorationResult'
import RootCauseResult from '@/components/resultBoard/RootCauseResult'
import MultiResult from '@/components/resultBoard/MultiResult'
import DataPreviewLayout from '@/components/resultBoard/DataPreviewLayout'
import RootCauseBoardBody from '@/components/resultBoard/RootCauseBoardBody'
import ArrowButton from '@/components/resultBoard/ArrowButton'
import Task from '@/components/display/Task.vue'
import RootCauseInfo from '@/components/display/RootCauseInfo'
import InsightTable from '@/components/display/InsightTable'
import SelectedRegion from '@/components/display/SelectedRegion'
import NoResult from '@/components/display/NoResult'
import TrendRootCause from '@/components/display/TrendRootCause'
import RootCauseItem from '@/components/display/RootCauseItem'
import CorrelationInsight from '@/components/display/CorrelationInsight'
import RootCauseDescription from '@/components/display/RootCauseDescription'
import DisplayNoAnswerInfo from '@/components/display/DisplayNoAnswerInfo'
import DisplayIndexInfo from '@/components/display/DisplayIndexInfo'
import DisplayTextInfo from '@/components/display/DisplayTextInfo'
import DisplayDifferenceInsight from '@/components/display/DisplayDifferenceInsight'
import DisplayCorrelationFeatures from '@/components/display/DisplayCorrelationFeatures'
import DisplayModelInfo from '@/components/display/DisplayModelInfo'
// import DisplayPivot from '@/components/display/DisplayPivot'
import DisplayMetrics from '@/components/display/DisplayMetrics'
import DisplayConfusionMatrix from '@/components/display/DisplayConfusionMatrix'

Vue.component(ModelTask.name, ModelTask)
Vue.component(EmptyResult.name, EmptyResult)
Vue.component(ParameterComparisonTable.name, ParameterComparisonTable)
Vue.component(Layout.name, Layout)
Vue.component(Spinner.name, Spinner)
Vue.component(SyTable.name, SyTable)
Vue.component(PreviewDataSource.name, PreviewDataSource)
Vue.component(ResultBoard.name, ResultBoard)
Vue.component(ResultBoardBody.name, ResultBoardBody)
Vue.component(GeneralResult.name, GeneralResult)
Vue.component(PredictResult.name, PredictResult)
Vue.component(CorrelationExplorationResult.name, CorrelationExplorationResult)
Vue.component(Task.name, Task)
Vue.component(RootCauseResult.name, RootCauseResult)
Vue.component(MultiResult.name, MultiResult)
Vue.component(DataPreviewLayout.name, DataPreviewLayout)
Vue.component(RootCauseBoardBody.name, RootCauseBoardBody)
Vue.component(ArrowButton.name, ArrowButton)
Vue.component(RootCauseInfo.name, RootCauseInfo)
Vue.component(InsightTable.name, InsightTable)
Vue.component(SelectedRegion.name, SelectedRegion)
Vue.component(NoResult.name, NoResult)
Vue.component(TrendRootCause.name, TrendRootCause)
Vue.component(RootCauseItem.name, RootCauseItem)
Vue.component(CorrelationInsight.name, CorrelationInsight)
Vue.component(RootCauseDescription.name, RootCauseDescription)
Vue.component(DisplayNoAnswerInfo.name, DisplayNoAnswerInfo)
Vue.component(DisplayIndexInfo.name, DisplayIndexInfo)
Vue.component(DisplayTextInfo.name, DisplayTextInfo)
Vue.component(DisplayDifferenceInsight.name, DisplayDifferenceInsight)
Vue.component(DisplayCorrelationFeatures.name, DisplayCorrelationFeatures)
Vue.component(DisplayModelInfo.name, DisplayModelInfo)
// Vue.component(DisplayPivot.name, DisplayPivot)
Vue.component(DisplayMetrics.name, DisplayMetrics)
Vue.component(DisplayConfusionMatrix.name, DisplayConfusionMatrix)
