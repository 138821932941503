import Vue from 'vue'
import 'echarts-stat'

import '@/components/global/echarts.js'

import DisplayBasicChart from './components/display/DisplayBasicChart.vue'
import DisplayScatterChart from '@/components/display/DisplayScatterChart'
import DisplayScatterProbabilityDensityChart from '@/components/display/DisplayScatterProbabilityDensityChart'
import DisplayScatterClusterChart from '@/components/display/DisplayScatterClusterChart'
import DisplayComputedHistogramChart from '@/components/display/DisplayComputedHistogramChart'
import DisplayComputedBoxPlotChart from '@/components/display/DisplayComputedBoxPlotChart'
import DisplayBubbleScatterChart from '@/components/display/DisplayBubbleScatterChart'
import DisplayLineChart from '@/components/display/DisplayLineChart'
import DisplayGroupLineChart from '@/components/display/DisplayGroupLineChart'
import DisplayPieChart from '@/components/display/DisplayPieChart'
import DisplayBarChart from '@/components/display/DisplayBarChart'
import DisplayStackBarChart from '@/components/display/DisplayStackBarChart'
// 多 Y 軸
import DisplayMultiAxisBarChart from '@/components/display/DisplayMultiAxisBarChart'
import DisplayMultiAxisLineChart from '@/components/display/DisplayMultiAxisLineChart'
// 多 X 軸
import DisplayMultiXAxisLineChart from '@/components/display/DisplayMultiXAxisLineChart'
// 水平方向 bar chart
import DisplayParallelBarChart from '@/components/display/DisplayParallelBarChart'
// import DisplayChinaMap from '@/components/display/DisplayChinaMap'
import DisplayPredictChart from '@/components/display/DisplayPredictChart'
import DisplayLineConfidentialIntervalChart from '@/components/display/DisplayLineConfidentialIntervalChart'
import DisplayPeriodicLineCharts from '@/components/display/DisplayPeriodicLineCharts'
import DisplayHeatMapChart from '@/components/display/DisplayHeatMapChart'
import DisplaySankeyChart from '@/components/display/DisplaySankeyChart'

// 3D
import Display3DBarChart from '@/components/display/Display3dBarChart'

// AutoML
import DisplayDistributionLineChart from '@/components/display/DisplayDistributionLineChart'

Vue.component(DisplayBasicChart.name, DisplayBasicChart)
Vue.component(DisplayScatterChart.name, DisplayScatterChart)
Vue.component(DisplayScatterProbabilityDensityChart.name, DisplayScatterProbabilityDensityChart)
Vue.component(DisplayScatterClusterChart.name, DisplayScatterClusterChart)
Vue.component(DisplayComputedHistogramChart.name, DisplayComputedHistogramChart)
Vue.component(DisplayComputedBoxPlotChart.name, DisplayComputedBoxPlotChart)
Vue.component(DisplayBubbleScatterChart.name, DisplayBubbleScatterChart)
Vue.component(DisplayLineChart.name, DisplayLineChart)
Vue.component(DisplayGroupLineChart.name, DisplayGroupLineChart)
Vue.component(DisplayStackBarChart.name, DisplayStackBarChart)
Vue.component(DisplayPieChart.name, DisplayPieChart)
Vue.component(DisplayBarChart.name, DisplayBarChart)
Vue.component(DisplayMultiAxisBarChart.name, DisplayMultiAxisBarChart)
Vue.component(DisplayMultiXAxisLineChart.name, DisplayMultiXAxisLineChart)
Vue.component(DisplayMultiAxisLineChart.name, DisplayMultiAxisLineChart)
Vue.component(DisplayParallelBarChart.name, DisplayParallelBarChart)
// Vue.component(DisplayChinaMap.name, DisplayChinaMap)
Vue.component(DisplayPredictChart.name, DisplayPredictChart)
Vue.component(DisplayLineConfidentialIntervalChart.name, DisplayLineConfidentialIntervalChart)
Vue.component(DisplayPeriodicLineCharts.name, DisplayPeriodicLineCharts)
Vue.component(DisplayHeatMapChart.name, DisplayHeatMapChart)
Vue.component(DisplaySankeyChart.name, DisplaySankeyChart)
Vue.component(DisplayDistributionLineChart.name, DisplayDistributionLineChart)
Vue.component(Display3DBarChart.name, Display3DBarChart)
