import { registerAskingModuleContext } from '@/modules/shared/asking'
import { ANALYSIS_MODULE_NAMESPACE } from '@/modules/namespace'
import { defineContext } from '@/utils/composable/context'

export { ANALYSIS_MODULE_NAMESPACE } from '@/modules/namespace'
export { analysisModuleStore } from './store'

export const {
  registerContext: registerAnalysisModuleContext,
  useContext: useAnalysisModuleContext
} = defineContext({
  namespace: ANALYSIS_MODULE_NAMESPACE,
  createContext () {
    const askingModuleContext = registerAskingModuleContext({
      extendNamespace: ANALYSIS_MODULE_NAMESPACE
    })
    return {
      askingModuleContext
    }
  }
})
