import { MiniAppStatus } from '../utils'

export default {
  appMap (state) {
    return Object.fromEntries(
      state.miniAppList.appBasicDtoList
        .map((app) => [
          app.id,
          app
        ])
    )
  },
  processingAppIdList (state, getters) {
    return state.miniAppList.appBasicDtoList
      .filter((app) => app.status === MiniAppStatus.Process)
      .map((app) => app.id)
  },
  pendingAppTypeMap (state) {
    return Object.fromEntries(
      state.pendingAppInfoList
        .map((info) => [
          info.id,
          info.type
        ])
    )
  },
  pendingAppIdList (state) {
    return state.pendingAppInfoList.map((info) => info.id)
  }
}
