<template>
  <div class="selected-region">
    <div class="region-title-block">
      <div class="region-title">
        <svg-icon
          icon-class="filter"
          class="filter-icon"
        />{{ title }}：
      </div>
      <div class="region-reminder">
        <span class="warning">
          <svg-icon icon-class="lamp" />
          {{ $t('resultDescription.prompt') }}:
        </span>
        <span>{{ $t('resultDescription.saveFilterReminding') }}</span>
      </div>
      <button
        class="btn-m btn-outline"
        @click="save"
      >
        {{ $t('resultDescription.saveFilterCondition') }}
      </button>
    </div>
    <slot name="selectedFilterRegion" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { defineComponent } from '@vue/composition-api'
import { useAskingModuleContext } from '@/modules/shared/asking'

export default defineComponent({
  name: 'SelectedRegion',
  props: {
    title: {
      type: String,
      default: null
    }
  },
  setup () {
    const {
      isShowSettingBox,
      setIsShowSettingBox,
      setDisplaySection,
      currentQuestionDataFrameId
    } = useAskingModuleContext()

    return {
      isShowSettingBox,
      setIsShowSettingBox,
      setDisplaySection,
      currentQuestionDataFrameId
    }
  },
  computed: {
    ...mapState('dataSource', ['dataFrameId'])
  },
  methods: {
    openSettingBox () {
      if (this.$route.name === 'PageResult') {
        this.setDisplaySection('filter')
        if (!this.isShowSettingBox) this.setIsShowSettingBox(true)
      }
    },
    async save () {
      // 如果 store 中的 dataframe id 與當前結果的 dataframe 不同須先切換
      if (this.currentQuestionDataFrameId !== this.dataFrameId) {
        await this.$store.dispatch('dataSource/changeDataFrameById', this.currentQuestionDataFrameId)
        // 更新 URL 中的 dataframe id
        return this.$router.replace({
          name: 'PageResult',
          params: this.$route.params,
          query: {
            ...this.$route.query,
            dataFrameId: String(this.currentQuestionDataFrameId)
          }
        }, () => {
          // 確保 URL 更新完成才開啟，避免開啟時 dataFrameId 仍為 all
          this.openSettingBox()
          this.$emit('save')
        })
      }
      this.openSettingBox()
      this.$emit('save')
    }
  }
})
</script>
<style lang="scss" scoped>
.selected-region {
  background: linear-gradient(270deg, #4bcbf1 0%, $filter-color 100%);
  border-radius: 8px;
  margin-bottom: 16px;
  margin-top: 16px;
  padding: 14px 20px;

  .region-title-block {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .region-title {
    font-size: 14px;
  }

  .region-reminder {
    flex: 1;
    font-size: 14px;
    line-height: 1.2;
    margin-right: 12px;
    padding-left: 20px;
    text-align: right;

    .warning {
      color: $theme-color-warning;
    }
  }

  .filter-icon {
    margin-right: 4px;
  }

  .filter-description {
    display: flex;
    flex-wrap: wrap;
    font-size: 13px;

    .column-name {
      margin-right: 4px;
    }
  }
}
</style>
