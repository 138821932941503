import {
  askQuestion,
  askResult,
  askSpecificType,
  getComponentList,
  getComponentData,
  addTableToMemory,
  getParserLanguageList,
  getHistoryQuestionList
} from '@/API/NewAsk'
import axios from 'axios'
import moment from 'moment-timezone'
import { Message } from 'element-ui'
import i18n from '@/lang/index.js'
import router from '@/router'

export default {
  // dataSource
  clearCurrentQuestionId ({ commit }) {
    commit('SET_CURRENT_QUESTION_ID', null)
  },
  updateResultRouter ({ commit, state, rootState }, sourceTag) {
    /**
     * 這邊的 DataSource 需要轉成字串的原因是：
     * 今天如果直接在結果頁重新整理，我如果直接從 router 進來
     * 從 $route 拿的 query 資訊會是字串形式，為了避免資料的格式不同觸發了 Result 頁 watch 的 function
     * 所以需要做一次型別的轉換
     **/
    router.push({
      name: 'PageResult',
      query: {
        question: state.appQuestion,
        stamp: new Date().getTime(),
        dataSourceId: String(rootState.dataSource.dataSourceId),
        dataFrameId: String(rootState.dataSource.dataFrameId),
        source: sourceTag
        // 暫時用來判定使用者是否在當前的群組問問題
        // groupId: rootGetters['userManagement/getCurrentGroupId']
      }
    })
  },
  async getHistoryQuestionList ({ commit, rootState, rootGetters }, dataSourceIdData) {
    const dataSourceId = rootState.dataSource.dataSourceId || dataSourceIdData
    const dataFrameId = rootGetters['dataSource/currentDataFrameId']

    const res = await getHistoryQuestionList(dataSourceId, dataFrameId)
    commit('SET_HISTORY_QUESTION_LIST', res)
  },
  // chatBot
  async askQuestion ({ dispatch, state, rootState, getters }, data) {
    if (state.parserLanguage === null) {
      await state.parserLanguagePromise
    }

    let cancelToken = null
    // 當同時問多個問題時，不去 cancel 前一個問題的 request
    if (data.shouldCancelToken) {
      await dispatch('cancelAllToken')
      state.askCancelTokenList[0] = axios.CancelToken.source()
      cancelToken = state.askCancelTokenList[0].token
    }

    const dataFrameId = data.dataFrameId || rootState.dataSource.dataFrameId
    let askCondition = {
      question: data.question === state.appQuestion ? state.appQuestion : data.question,
      dataSourceId: data.dataSourceId || rootState.dataSource.dataSourceId,
      previewQuestionId: data.previewQuestionId || getters.drillDownQuestionId,
      domain: 'GENERAL',
      isIgnoreAlgorithm: state.isUseAlgorithm ? !state.isUseAlgorithm : null,
      dataFrameId: dataFrameId === 'all' ? '' : dataFrameId,
      selectedColumnList: getters.selectedColumnList,
      language: data.language || state.parserLanguage,
      timeZone: moment.tz.guess()
    }

    return await askQuestion(askCondition, cancelToken)
  },
  async askResult ({ state }, data) {
    let cancelToken = state.askCancelTokenList?.[data.index || 0]?.token || null
    return await askResult(data, cancelToken)
  },
  async askSpecificType ({ state, commit }, data) {
    let cancelToken = state.askCancelTokenList?.[data.index || 0]?.token || null
    commit('result/updateCurrentResultId', data.resultId, { root: true })
    return await askSpecificType(data, cancelToken)
  },
  async getComponentList ({ state }, data) {
    let cancelToken = state.askCancelTokenList?.[data.index || 0]?.token || null
    return await getComponentList(data, cancelToken)
  },
  async getComponentData ({ state }, data) {
    let cancelToken = state.askCancelTokenList?.[data.index || 0]?.token || null
    return await getComponentData(data, cancelToken)
  },
  async openAskInMemory ({ rootGetters, rootState }) {
    if (!rootGetters['userManagement/hasPermission']('in_memory')) return
    await addTableToMemory(
      rootGetters['userManagement/getCurrentAccountId'],
      rootGetters['dataSource/currentDataFrameId'],
      rootState.dataSource.dataSourceId
    )
  },
  async getParserList ({ commit }) {
    let promise, done
    promise = new Promise((resolve) => {
      done = resolve
    })
    commit('SET_PARSER_LANGUAGE_PROMISE', promise)

    try {
      const res = await getParserLanguageList()
      let currentLanguage = 'ZH_TW'
      let languageParser = res.some(element => element.language === currentLanguage)
        ? currentLanguage
        : res[0]
      commit('SET_PARSER_LANGUAGE_LIST', res)
      commit('SET_PARSER_LANGUAGE', languageParser)
    } catch (err) {
      console.log(err)
    } finally {
      done()
      commit('SET_PARSER_LANGUAGE_PROMISE', null)
    }
  },
  clearCopiedColumnName ({ commit }) {
    commit('SET_COPIED_COLUMN_NAME', null)
  },
  // dataFrameAdvanceSetting
  clearFilterList ({ commit }) {
    commit('SET_FILTER_LIST', [])
  },
  updateFilterListByData ({ state, commit }, data) {
    if (data.length === 0) return false
    // 判斷要從哪邊開始取代新的
    let newRestriction = {
      status: true,
      restriction: data,
      questionId: state.currentQuestionId
    }
    let closeFilterIndex = -1
    for (let i = 0; i < state.filterList.length; i++) {
      if (!state.filterList[i].status) {
        closeFilterIndex = i
        break
      }
    }
    let filterList = []
    if (closeFilterIndex > -1) {
      filterList = state.filterList.slice(0, closeFilterIndex)
    }

    filterList = [...state.filterList, newRestriction]
    commit('SET_FILTER_LIST', filterList)
    Message({
      message: i18n.t('message.addFilter'),
      type: 'success',
      duration: 3 * 1000,
      showClose: true
    })
  },
  clearColumnList ({ commit }) {
    commit('SET_COLUMN_LIST', null)
    commit('SET_LAST_TIME_SELECTED_COLUMN_LIST', null)
  },
  saveSelectedColumnList ({ commit }, columnList) {
    if (columnList === null) {
      commit('SET_LAST_TIME_SELECTED_COLUMN_LIST', null)
      return
    }

    const selectedColumnList = columnList
      .filter((column) => column.isSelected)
      .map((column) => column.id)

    if (selectedColumnList.length === columnList.length) {
      commit('SET_LAST_TIME_SELECTED_COLUMN_LIST', null)
      return
    }

    commit('SET_LAST_TIME_SELECTED_COLUMN_LIST', selectedColumnList)
  },
  setColumnListAndApplySavedSelectedList ({ state, commit }, columnList) {
    if (state.lastTimeSelectedColumnList !== null) {
      const columnIdMap = Object.fromEntries(
        state.lastTimeSelectedColumnList.map((columnId) => [columnId, true])
      )
      columnList.forEach((column) => column.isSelected = column.id in columnIdMap)
    }

    commit('SET_COLUMN_LIST', columnList)
  },
  setColumnList ({ state, commit, dispatch }, columnList) {
    commit('SET_COLUMN_LIST', columnList)
    dispatch('saveSelectedColumnList', columnList)
  },
  addCancelToken ({ state }, data) {
    state.askCancelTokenList[data.index] = data.token
  },
  resetCancelToken ({ state }) {
    state.askCancelTokenList.length = 0
  },
  cancelAllToken ({ state }) {
    state.askCancelTokenList.forEach((token) => {
      token?.cancel('cancel')
    })
  }
}
