import request from '@/utils/publicRequest'

/**
 * 取得 mini app 列表
 * @param {String} id - mini app ID
 * @returns {Promise<any[]>}
 */
export function getMiniAppList (groupId) {
  return request({
    url: '/app/list',
    method: 'GET',
    params: {
      groupId
    }
  })
}

/**
 * 取得 mini app 內容
 * @param {String} id - mini app ID
 */
export function getMiniAppInfo (id) {
  return request({
    url: `/app/${id}`,
    method: 'GET'
  })
}

/**
 * 刪除 mini app
 * @param {String} id - mini app ID
 */
export function deleteMiniApp (id) {
  return request({
    url: `/app/${id}`,
    method: 'DELETE'
  })
}

/**
 * 建立 mini app
 * @param {Object} data - mini app 資訊
 */
export function createApp (data) {
  return request({
    url: '/app/create',
    method: 'POST',
    data
  })
}

/**
 * 變更 mini app 資訊
 * @param {String} id - mini app ID
 * @param {Object} data - mini app 資訊
 */
export function updateAppSetting (id, data) {
  return request({
    url: `/app/${id}`,
    method: 'PATCH',
    data
  })
}

/**
 * 變更 mini app 名稱
 * @param {String} id - mini app ID
 * @param {Object} data - mini app 資訊
 */
export function updateAppName (id, data) {
  return request({
    url: `/app/${id}/name`,
    method: 'PATCH',
    data
  })
}

/**
 * 匯入應用程式-上傳規格檔案，並解析回傳資料設定資訊 只能上傳單一檔案
 * @param {FormData} formData
 * @param {(progress: number) => void} [onProgress]
 * @param {import('axios').CancelToken} [cancelToken]
 * @returns {Promise<import('./types/MiniApp').DataSettingsUploadResult>}
 */
export function uploadFile (formData, onProgress, cancelToken) {
  return request({
    url: '/app/import/upload',
    method: 'POST',
    data: formData,
    onUploadProgress (progressEvent) {
      let percentCompleted = Math.round((progressEvent.loaded * 100) /
        progressEvent.total)

      // execute the callback
      if (onProgress) onProgress(percentCompleted)
      return percentCompleted
    },
    cancelToken
  })
}

/**
 * 匯入應用程式-建置應用程式，回傳 AppId
 * @param {number} importTaskId
 * @param {import('./types/MiniApp').BuildAppData} data
 *
 * @returns {Promise<{ newAppId: number }>}
 */
export function buildAppByImport (importTaskId, data) {
  return request({
    url: `/app/import/build/${importTaskId}`,
    method: 'POST',
    data
  })
}

/**
 * 取得變更來源資料設定
 * @param {number} id
 * @returns {Promise<import('./types/MiniApp').AppDataSettings>}
 */
export function getAppDataSettings (id) {
  return request({
    url: `/app/${id}/dataSetting`,
    method: 'GET'
  })
}

/**
 * 匯出應用程式規格檔
 * @param {number} id
 * @returns Promise<{ data: Blob }>
 */
export function exportApp (id) {
  return request({
    url: `/app/${id}/export`,
    method: 'GET'
  })
}

/**
 * 複製應用程式，回傳 AppId
 * @param {number} id
 * @param {import('./types/MiniApp').BuildAppData} data
 * @returns {Promise<{ newAppId: number }>}
 */
export function copyApp (id, data) {
  return request({
    url: `/app/${id}/copy`,
    method: 'POST',
    data
  })
}

/**
 *
變更來源資料
 * @param {number} id
 * @param {import('./types/MiniApp').UpdateAppData} data
 * @returns {Promise<void>}
 */
export function updateAppDataSettings (id, data) {
  return request({
    url: `/app/${id}/dataSetting/change`,
    method: 'POST',
    data
  })
}

/**
 * 取得推薦資料源 - 最推薦一筆
 * @param {import('./types/MiniApp').GetRecommendDataSourceParam} data
 * @returns {Promise<import('./types/MiniApp').GetRecommendDataSourceResult>}
 */
export function getRecommendDataSource (data) {
  return request({
    url: '/app/dataSetting/recommend/datasource',
    method: 'POST',
    data
  })
}

/**
 * 取得推薦資料表列表
 * @param {import('./types/MiniApp').GetRecommendDataFrameListParam} data
 * @returns {Promise<import('./types/MiniApp').GetRecommendDataFrameListResult>}
 */
export function getRecommendDataFrameList (data) {
  return request({
    url: '/app/dataSetting/recommend/dataframe/list',
    method: 'POST',
    data
  })
}

/**
 * 取得推薦資料欄位列表
 * @param {import('./types/MiniApp').GetRecommendDataColumnListParam} data
 * @returns {Promise<import('./types/MiniApp').GetRecommendDataColumnListResult>}
 */
export function getRecommendDataColumnList (data) {
  return request({
    url: '/app/dataSetting/recommend/datacolumn/list',
    method: 'POST',
    data
  })
}

/**
 * 取得推薦模型 - 列表
 * @param {import('./types/MiniApp').GetRecommendModelListParam} data
 * @returns {Promise<import('./types/MiniApp').GetRecommendModelListResult>}
 */
export function getRecommendModelList (data) {
  return request({
    url: '/app/dataSetting/recommend/model/list',
    method: 'POST',
    data
  })
}

/**
 * 取得 mini app 示警條件
 * @param {String} id - mini app ID
 */
export function getMiniAppConditions (id) {
  return request({
    url: `/app/${id}/alert-conditions`,
    method: 'GET'
  })
}
