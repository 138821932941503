import moment from 'moment'
import momentTZ from 'moment-timezone'
import i18n from '@/lang/index.js'

const getDatePickerOptions = (timeScope) => {
  switch (timeScope) {
    case 'SECOND':
    case 'MINUTE':
    case 'HOUR':
      return {
        type: 'datetime',
        format: 'yyyy-MM-dd HH:mm:ss'
      }
    case 'DAY':
      return {
        type: 'date',
        format: 'yyyy-MM-dd'
      }
    case 'WEEK':
      return {
        type: 'week',
        format: 'yyyy-W WW'
      }
    case 'MONTH':
    case 'QUARTER':
      return {
        type: 'month',
        format: 'yyyy-MM'
      }
    case 'YEAR':
      return {
        type: 'year',
        format: 'yyyy'
      }
    default:
      return {
        type: 'datetime',
        format: 'yyyy-MM-dd HH:mm:ss'
      }
  }
}

const customerTimeFormatter = (time, timeScope, isRangeEnd = false) => {
  // 處理季
  if (timeScope === 'QUARTER' && isRangeEnd) {
    // 結束時間再加三個月
    const format = getDatePickerOptions(timeScope).format.replace('dd', 'DD').replace('yyyy', 'YYYY')
    return moment.utc(time).add(2, 'month').format(format)
  }

  if (timeScope === 'WEEK') {
    /* 當一年最後一週跨到下一年
      * moment js 與後端回傳的 week 不同
      * EX: 2018-12-30(日)禮拜天落在 2018年第52週，但後端會傳 2019年第一週
    */
    let weekCrossYear = moment.utc(time).format('YYYY') !== moment.utc(time).add(1, 'weeks').format('YYYY')
    let year = weekCrossYear ? moment.utc(time).add(1, 'weeks').format('YYYY') : moment.utc(time).format('YYYY')
    return `${year}-${i18n.tc('timeScopeUnit.allowArg.week', moment.utc(time).week())}`
  }
  const format = getDatePickerOptions(timeScope).format.replace('dd', 'DD').replace('yyyy', 'YYYY')
  return moment.utc(time).format(format)
}

const formatRelativeDateTime = (dataValue) => {
  const properties = {
    start: null,
    end: null
  }

  const currentTimeZone = this.timeZone ?? momentTZ.tz.guess()

  // update datetime range
  switch (dataValue) {
    case 'today':
      properties.start = momentTZ().tz(currentTimeZone).startOf('day').format('YYYY-MM-DD HH:mm')
      properties.end = momentTZ().tz(currentTimeZone).endOf('day').format('YYYY-MM-DD HH:mm')
      break
    // 注意！每週是從週一到週日，要使用 isoWeek
    case 'week':
      properties.start = momentTZ().tz(currentTimeZone).startOf('isoWeek').format('YYYY-MM-DD HH:mm')
      properties.end = momentTZ().tz(currentTimeZone).endOf('isoWeek').format('YYYY-MM-DD HH:mm')
      break
    case 'month':
    case 'quarter':
    case 'year':
      properties.start = momentTZ().tz(currentTimeZone).startOf(dataValue).format('YYYY-MM-DD HH:mm')
      properties.end = momentTZ().tz(currentTimeZone).endOf(dataValue).format('YYYY-MM-DD HH:mm')
      break
    default:
      if (RegExp('^.*hour.*$').test(dataValue)) {
        const hour = Number(dataValue.split('hour')[0])
        properties.start = momentTZ().tz(currentTimeZone).subtract(hour, 'hours').format('YYYY-MM-DD HH:mm')
        properties.end = momentTZ().tz(currentTimeZone).format('YYYY-MM-DD HH:mm')
      } else {
        properties.start = null
        properties.end = null
      }
  }

  return properties
}

export { getDatePickerOptions, customerTimeFormatter, formatRelativeDateTime }
