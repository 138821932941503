import { useAskingModuleStore } from './store'
import { ASKING_MODULE_NAMESPACE } from '@/modules/namespace'
import { defineContext } from '@/utils/composable/context'

export { ASKING_MODULE_NAMESPACE } from '@/modules/namespace'
export { askingModuleStore } from './store'

export const {
  registerContext: registerAskingModuleContext,
  useContext: useAskingModuleContext
} = defineContext({
  namespace: ASKING_MODULE_NAMESPACE,
  /** @param {{ extendNamespace: string }} */
  createContext ({ extendNamespace, shouldGetParserList = true }) {
    const store = useAskingModuleStore(extendNamespace, shouldGetParserList)
    return {
      ...store
    }
  }
})
