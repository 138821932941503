<template>
  <div
    v-if="info.length > 0"
    class="trend-root-cause"
  >
    <div class="block-title">
      {{ $t('resultDescription.trendInsight') }}
    </div>
    <div
      v-for="(singleInfo, index) in info"
      :key="index"
      :class="{'has-link': singleInfo.next_question}"
      class="single-root-cause"
      @click="linkTo(singleInfo.next_question)"
    >
      <span class="info-index">{{ index + 1 }}</span>
      <div class="info-content">
        {{ singleInfo.information }}
      </div>
    </div>
    <!-- <div
      v-if="info.length === 0"
      class="empty-info"
    >
      {{ $t('resultDescription.trendInsightNoData') }}
    </div> -->
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import { useAskingModuleContext } from '@/modules/shared/asking'

export default defineComponent({
  name: 'TrendRootCause',
  props: {
    info: {
      type: Array,
      default () {
        return []
      }
    }
  },
  setup () {
    const { setAppQuestion, updateResultRouter } = useAskingModuleContext()
    return {
      setAppQuestion,
      updateResultRouter
    }
  },
  methods: {
    linkTo (value) {
      if (!value) return
      this.setAppQuestion(value)
      // 區分使用者是點擊推薦問句或是點擊過往的問句
      this.updateResultRouter('trend_root_cause')
    }
  }
})
</script>
<style lang="scss" scoped>
.trend-root-cause {
  background-color: var(--color-bg-5);
  border-radius: 8px;
  padding: 20px;

  .block-title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
  }

  .single-root-cause {
    @include card();

    align-items: center;
    display: flex;
    padding: 12px;

    &.has-link {
      cursor: pointer;

      .info-content {
        text-decoration: underline;
      }
    }

    .info-index {
      border: 1px solid $theme-color-primary;
      border-radius: 50%;
      color: $theme-color-primary;
      height: 32px;
      line-height: 30px;
      margin-right: 12px;
      text-align: center;
      width: 32px;
    }

    .info-content {
      flex: 1;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  .empty-info {
    background: var(--color-bg-5);
    border-radius: 8px;
    padding: 20px;
    text-align: center;
  }
}
</style>
