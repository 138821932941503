<template>
  <div class="display-box-plot-chart">
    <v-echart
      :style="chartStyle"
      :option="chartOption"
      auto-resize
    />
    <insight-description-block
      v-if="dataset.warnings && dataset.warnings.length > 0 && isWarningEnable"
      :title="$t('resultDescription.warning')"
      :message-list="dataset.warnings"
      :sub-message-list="dataset.subWarnings"
      message-type="warning"
      icon-name="alert-circle"
    />
    <section
      v-if="dataset.staticInfo"
      class="feature-container"
    >
      <div
        v-for="info in statisticsData"
        :key="info.label"
        class="info__wrapper"
      >
        <div class="info__wrapper-label">
          {{ info.label }}
        </div>
        <div class="info__wrapper-value">
          {{ info.value }}
        </div>
      </div>
    </section>
    <div
      v-if="dataset.pValue"
      class="result-description"
    >
      {{ dataset.pValue >= 0.05 ? PValueGreaterDescription : PValueSmallerDescription }}
    </div>
  </div>
</template>
<script>
import chartVariable from '@/styles/chart/variables.scss'
import insightDescriptionBlock from './InsightDescriptionBlock'
import { chartOptions } from '@/components/display/common/chart-addon.js'
import { defineComponent } from '@vue/composition-api'
import { useAskingModuleContext } from '@/modules/shared/asking'

let boxPlotChartConfig = {
  tooltip: {
    trigger: 'item'
  },
  chartData: {
    name: null,
    type: 'boxplot',
    data: [],
    itemStyle: {
      color: chartVariable.lightestChartColor
    }
  },
  outlier: {
    name: null,
    type: 'scatter',
    data: []
  }
}

export default defineComponent({
  name: 'DisplayComputedBoxPlotChart',
  components: {
    insightDescriptionBlock
  },
  props: {
    dataset: {
      type: Object,
      default () {
        return {
          index: [
            '收入',
            '利潤'
          ],
          data: [
            {
              low: 655,
              q1: 850,
              q2: 940,
              q3: 980,
              high: 1000,
              outliers: [200, 300]
            },
            {
              low: 760,
              q1: 800,
              q2: 845,
              q3: 885,
              high: 960,
              outliers: [100, 1030, 1200]
            }
          ]
        }
      }
    },
    title: {
      type: Object,
      default: () => {
        return {
          xAxis: null,
          yAxis: null
        }
      }
    },
    isShowToolbox: {
      type: Boolean,
      default: true
    },
    customChartStyle: {
      type: Object,
      default: () => ({})
    },
    chartToolboxSetting: {
      type: Object,
      default: undefined
    },
    direction: {
      type: String,
      default: 'vertical'
    },
    isWarningEnable: {
      type: Boolean,
      default: true
    },
    height: {
      type: String,
      default: '420px'
    },
    width: {
      type: String,
      default: '100%'
    }
  },
  setup () {
    const { appQuestion } = useAskingModuleContext()
    return {
      appQuestion
    }
  },
  computed: {
    chartOption () {
      let chartAddon = JSON.parse(JSON.stringify(chartOptions(this.chartToolboxSetting)))
      if (this.$store.state.setting.locale === 'en-US') chartAddon.legend.itemGap = 40
      boxPlotChartConfig.chartData.name = this.$t('chart.bound')
      boxPlotChartConfig.outlier.name = this.$t('chart.outliers')
      let boxPlotConfig = JSON.parse(JSON.stringify(boxPlotChartConfig))
      let outlierIndex = this.direction === 'horizontal' ? 0 : 1

      chartAddon.xAxis = { ...chartAddon.xAxis, ...boxPlotConfig.xAxis }
      chartAddon.tooltip.trigger = boxPlotConfig.tooltip.trigger
      chartAddon.tooltip.formatter = (param) => {
        if (param.componentSubType === 'boxplot') {
          return [
            'upper: ' + this.formatComma(param.data[5]),
            'Q3: ' + this.formatComma(param.data[4]),
            'median: ' + this.formatComma(param.data[3]),
            'Q1: ' + this.formatComma(param.data[2]),
            'lower: ' + this.formatComma(param.data[1])
          ].join('<br/>')
        } else {
          return `outlier<br/>${param.name}: ${this.formatComma(param.data[outlierIndex])}`
        }
      }

      chartAddon.xAxis.data = this.dataset.index

      if (this.title.xAxis) chartAddon.xAxis.name = this.title.xAxis[0].display_name

      // 暫時先這樣改
      let notChangeDisplayName = this.isShowToolbox
      if (!notChangeDisplayName) chartAddon.xAxis.axisLabel.rotate = 0
      chartAddon.yAxis.name = this.title.yAxis.length > 0 ? !notChangeDisplayName ? this.$t('chart.deviation') : this.title.yAxis[0].display_name : null
      boxPlotConfig.chartData.data = this.dataset.data.map(element => {
        return [element.low, element.q1, element.q2, element.q3, element.high]
      })
      boxPlotConfig.outlier.data = this.dataset.data.reduce((acc, cur, index) => {
        if (cur.outliers.length > 0) {
          let outliers = cur.outliers.map(element => {
            return this.direction === 'horizontal' ? [element, index] : [index, element]
          })
          return acc.concat(outliers)
        } else {
          return acc
        }
      }, [])
      chartAddon.series[0] = boxPlotConfig.chartData
      chartAddon.series[0].itemStyle.borderColor = '#4DE2F0'
      chartAddon.series[0].itemStyle.color = '#000'
      chartAddon.series[1] = boxPlotConfig.outlier
      chartAddon.series[1].itemStyle = {
        color: chartVariable['chartColorList-2']
      }
      // 數據顯示
      chartAddon.toolbox.feature.dataView.optionToContent = (opt) => {
        let dataset = opt.series[0].data
        let table = `<div style="text-align: text;padding: 0 16px;"><button style="width: 100%;" class="btn btn-m btn-default" type="button" id="export-btn">${this.$t('chart.export')}</button></div>
          <table style="margin-top: 16px;width:100%;padding: 0 16px;"><tbody><tr style="background-color:#2B4D51"><td></td>`
        if (this.dataset.index) {
          for (let i = 0; i < this.dataset.index.length; i++) {
            table += `<td>${this.dataset.index[i]}</td>`
          }
        }
        table += '</tr>'
        for (let i = 0; i < 5; i++) {
          table += `<tr ${i % 2 === 0 ? 'style="background-color:rgba(50, 75, 78, 0.6)"' : ''}>`
          switch (i) {
            case 0:
              table += `<td>${this.$t('resultDescription.min')}</td>`
              break
            case 1:
              table += `<td>${this.$t('resultDescription.lowerQuartile')}</td>`
              break
            case 2:
              table += `<td>${this.$t('resultDescription.median')}</td>`
              break
            case 3:
              table += `<td>${this.$t('resultDescription.higherQuartile')}</td>`
              break
            case 4:
              table += `<td>${this.$t('resultDescription.max')}</td>`
              break
          }
          for (let j = 0; j < dataset.length; j++) {
            table += `<td>${dataset[j][i]}</<td>`
          }
          table += '</tr>'
        }
        table += '</tbody></table>'
        return table
      }
      // 是否顯示 toolbox
      chartAddon.toolbox.show = this.isShowToolbox || !!this.chartToolboxSetting
      // export data
      this.$nextTick(() => {
        this.$el.addEventListener('click', (e) => {
          if (e.target && e.target.id === 'export-btn' && this.dataset.index) {
            let exportData = []
            let indexArray = JSON.parse(JSON.stringify(this.dataset.index))
            indexArray.unshift('')
            exportData.push(indexArray)

            for (let i = 0; i < 5; i++) {
              let rowData
              switch (i) {
                case 0:
                  rowData = [this.$t('resultDescription.min')]
                  break
                case 1:
                  rowData = [this.$t('resultDescription.lowerQuartile')]
                  break
                case 2:
                  rowData = [this.$t('resultDescription.median')]
                  break
                case 3:
                  rowData = [this.$t('resultDescription.higherQuartile')]
                  break
                case 4:
                  rowData = [this.$t('resultDescription.max')]
                  break
              }
              for (let j = 0; j < boxPlotConfig.chartData.data.length; j++) {
                rowData.push(boxPlotConfig.chartData.data[j][i])
              }
              exportData.push(rowData)
            }
            this.exportToCSV(this.appQuestion, exportData)
          }
        })
      })

      const returnObj = {
        ...chartAddon,
        brush: {
          toolbox: [''],
          xAxisIndex: 0,
          yAxisIndex: 0
        }
      }

      if (this.direction === 'horizontal') {
        return {
          ...returnObj,
          xAxis: JSON.parse(JSON.stringify(returnObj.yAxis)),
          yAxis: JSON.parse(JSON.stringify(returnObj.xAxis))
        }
      }
      return returnObj
    },
    chartStyle () {
      return {
        width: this.dataset.pValue ? this.width : '100%',
        height: this.height,
        ...this.customChartStyle
      }
    },
    PValueGreaterDescription () {
      return this.$t('modelTesting.PValueIsGreaterDescription', {
        p_value: this.dataset.pValue
      })
    },
    PValueSmallerDescription () {
      return this.$t('modelTesting.PValueIsSmallerDescription', {
        p_value: this.dataset.pValue
      })
    },
    statisticsData () {
      if (this.dataset.staticInfo) {
        const staticInfo = this.dataset.staticInfo
        return [
          {
            label: this.$t('modelPerformance.statisticsDataLabel.dataCount'),
            value: this.roundNumber(staticInfo.count, 5)
          },
          {
            label: this.$t('modelPerformance.statisticsDataLabel.avg'),
            value: this.roundNumber(staticInfo.avg, 5)
          },
          {
            label: this.$t('modelPerformance.statisticsDataLabel.max'),
            value: this.roundNumber(staticInfo.max, 5)
          },
          {
            label: this.$t('modelPerformance.statisticsDataLabel.min'),
            value: this.roundNumber(staticInfo.min, 5)
          },
          {
            label: this.$t('modelPerformance.statisticsDataLabel.median'),
            value: this.roundNumber(staticInfo.median, 5)
          },
          {
            label: this.$t('modelPerformance.statisticsDataLabel.standard'),
            value: this.roundNumber(staticInfo.stddev, 5)
          }
        ]
      }
      return ''
    }
  }
})
</script>

<style lang="scss" scoped>
.display-box-plot-chart {
  position: relative;
}

.feature-container {
  display: grid;
  font-size: 13px;
  gap: 10px 20px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto auto auto;
  width: 100%;

  .info__wrapper {
    align-items: center;
    display: flex;
    flex: 1 1 100%;
    justify-content: space-between;

    &-label {
      color: #a7a7a7;
      font-weight: 600;
    }

    &-value {
      color: #ccc;
      margin: 0 auto;
    }
  }
}

.result-description {
  color: #ddd;
  font-size: 13px;
  line-height: 32px;
  padding: 40px 10px 0 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 35%;
}
</style>
