<template>
  <div class="no-result-block">
    {{ message || $t('message.noResult') }}
  </div>
</template>
<script>
export default {
  name: 'NoResult',
  props: {
    message: { type: String, default: '' }
  }
}
</script>
<style lang="scss" scoped>
.no-result-block {
  background-color: rgba(35, 61, 64, 0.6);
  border: 1px solid #05282c;
  color: #ccc;
  margin: 15px 35px;
  padding: 15px 20px;
  text-align: center;
}
</style>
