import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  state: {
    params: {
      askQuestion: {
        questionStructure: null,
        question: null,
        isAskQuestionSuccess: null,
        diagram: null,
        source: null
      }
    }
  },
  mutations,
  actions
}
