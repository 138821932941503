var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dropdown",on:{"mouseenter":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.toggleDropdownList('hover')},"mouseleave":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.toggleDropdownList('hover')}}},[_c('div',{staticClass:"dropdown__select",on:{"click":function($event){return _vm.toggleDropdownList('click')}}},[_vm._t("display")],2),_c('div',{staticClass:"dropdown__list-container",class:{
      'dropdown__list-container--show': _vm.isShowDropdownList,
      'dropdown__list-container--wider': _vm.isShowId
    }},[(_vm.$slots.inputBlock)?_c('div',{staticClass:"input-block"},[_vm._t("inputBlock")],2):_vm._e(),(_vm.dataList.length)?_c('ul',{staticClass:"dropdown__list"},_vm._l((_vm.dataList),function(item){return _c('li',{key:item.id,staticClass:"dropdown__item",class:{
          'dropdown__item--loading': _vm.isLoading,
          'dropdown__item--disabled': item.disabled
        }},[_c('a',{staticClass:"dropdown__link",class:{
            'dropdown__link--selected': _vm.isSelectedItem(item.id),
            'dropdown__link--point': _vm.hasBulletPoint,
            'dropdown__link--arrow': item.children
          },attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.selectItem(item)}}},[(item.icon)?_c('svg-icon',{attrs:{"icon-class":item.icon}}):_vm._e(),_vm._v(" "+_vm._s(item.name)+" "),(_vm.isShowId)?_c('span',{staticClass:"id-block"},[_vm._v(" (ID:"+_vm._s(item.id)+")")]):_vm._e()],1),(item.children && !item.disabled)?_c('div',{staticClass:"dropdown__list-children",class:{'dropdown__list-children--left': item.childrenPosition === 'left'}},[_c('ul',{staticClass:"dropdown__list"},_vm._l((item.children),function(children){return _c('li',{key:children.id,staticClass:"dropdown__item",class:{ 'dropdown__item--loading': _vm.isLoading }},[_c('a',{staticClass:"dropdown__link",class:{
                  'dropdown__link--selected': _vm.isSelectedItem(children.id),
                  'dropdown__link--point': _vm.hasBulletPoint
                },attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.selectItem(children)}}},[_vm._v(" "+_vm._s(children.name)+" ")])])}),0)]):_vm._e()])}),0):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.dataList.length),expression:"!dataList.length"}],staticClass:"empty-data"},[_vm._v(" "+_vm._s(_vm.$t('editing.emptyKey'))+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }