import Vue from 'vue'
import vGanttChart from 'v-gantt-chart'
import ScheduleButton from '@/schedule/components/ScheduleButton.vue'
import ScheduleInput from '@/schedule/components/ScheduleInput.vue'
import ScheduleSelect from '@/schedule/components/ScheduleSelect.vue'
import ScheduleDatePicker from '@/schedule/components/ScheduleDatePicker.vue'
import ScheduleRadioGroup from '@/schedule/components/ScheduleRadioGroup.vue'

Vue.use(vGanttChart)
Vue.component('ScheduleButton', ScheduleButton)
Vue.component('ScheduleInput', ScheduleInput)
Vue.component('ScheduleSelect', ScheduleSelect)
Vue.component('ScheduleDatePicker', ScheduleDatePicker)
Vue.component('ScheduleRadioGroup', ScheduleRadioGroup)
