<template>
  <!--CATEGORY or BOOLEAN-->
  <div
    v-if="inputData.statsType === 'CATEGORY' || inputData.statsType === 'BOOLEAN'"
    class="input-field"
  >
    <label class="input-field__label">{{ inputData.columnName }}</label>
    <div class="input-field__input">
      <default-select
        v-validate="'required'"
        :popper-append-to-body="true"
        :option-list="inputData.valueList"
        :placeholder="$t('miniApp.pleaseSelect')"
        :is-disabled="isProcessing"
        v-model="columnInfo.userInput"
        :name="inputId + '-' + inputData.columnName"
        filterable
        class="input-field__select"
      />
      <div
        v-show="errors.has('simulator-' + simulatorId + '.' + inputId + '-' + inputData.columnName)"
        class="error-text"
      >
        {{ errors.first('simulator-' + simulatorId + '.' + inputId + '-' + inputData.columnName) }}
      </div>
      <div
        v-if="isEmptyData"
        class="input-field__reminder"
      >
        {{ '*' + $t('miniApp.noResultCheckFilterAndDataSource') }}
      </div>
    </div>
  </div>
  <!--NUMERIC-->
  <div
    v-else-if="inputData.statsType === 'NUMERIC'"
    class="input-field"
  >
    <label class="input-field__label">{{ getNumericTitle }}</label>
    <div class="input-field__input">
      <input-verify
        v-validate="'required|decimal'"
        v-model.number="columnInfo.userInput"
        :is-disabled="isProcessing"
        :name="inputId + '-' + inputData.columnName"
        :validate-scope="'simulator-' + simulatorId"
        type="text"
      />
      <div
        v-if="isEmptyData"
        class="input-field__reminder"
      >
        {{ '*' + $t('miniApp.noResultCheckFilterAndDataSource') }}
      </div>
    </div>
  </div>
  <!-- DATETIME -->
  <div
    v-else-if="inputData.statsType === 'DATETIME'"
    class="input-field"
  >
    <label class="input-field__label">{{ getDateTimeTitle }}</label>
    <div class="input-field__input">
      <el-date-picker
        v-validate="'required'"
        v-model="columnInfo.userInput"
        :name="inputId + '-' + 'dateTime'"
        :format="inputData.datetimeInfo.datePattern"
        :value-format="inputData.datetimeInfo.datePattern"
        :clearable="false"
        :picker-options="pickerOptions"
        :disabled="isEmptyData"
        :editable="false"
        type="datetime"
      />
      <div
        v-show="errors.has('simulator-' + simulatorId + '.' + inputId + '-' + 'dateTime')"
        class="error-text"
      >
        {{ errors.first('simulator-' + simulatorId + '.' + inputId + '-' + 'dateTime') }}
      </div>
      <div
        v-if="isEmptyData"
        class="input-field__reminder"
      >
        {{ '*' + $t('miniApp.noResultCheckFilterAndDataSource') }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, computed } from '@vue/composition-api'
import { dataValueSearch } from '@/API/DataSource'
import { searchColumnDefaultValue, searchNumericColumnValueRange } from '@/API/Model'
import DefaultSelect from '@/components/select/DefaultSelect'
import EmptyInfoBlock from '@/components/EmptyInfoBlock'
import InputVerify from '@/components/InputVerify'
import { v4 as uuidv4 } from 'uuid'
import { useI18n } from '@/utils/composable/i18n'
import { customerTimeFormatter } from '@/utils/composable/dateTime'

export default defineComponent({
  inject: ['$validator'],
  name: 'SimulatorInput',
  components: {
    DefaultSelect,
    EmptyInfoBlock,
    InputVerify
  },
  props: {
    columnInfo: {
      type: Object,
      required: true
    },
    isProcessing: {
      type: Boolean,
      default: false
    },
    modelId: {
      type: Number,
      required: true
    },
    restrictions: {
      type: Array,
      default: () => ([])
    },
    simulatorId: {
      type: String,
      required: true
    }
  },
  setup (props, { emit }) {
    const { t } = useI18n()

    // ----- general -----
    const inputId = uuidv4()

    // ----- data fetch -----
    const inputData = ref({})

    const handleColumnValue = async (columnInfo, defaultValue) => {
      const _inputData = {}
      _inputData.statsType = props.columnInfo.statsType
      _inputData.columnName = props.columnInfo.originalName

      if (_inputData.statsType === 'CATEGORY' || _inputData.statsType === 'BOOLEAN') {
        _inputData.valueList = columnInfo.fuzzySearchResult
        _inputData.valueList = _inputData.valueList.map(element => ({
          value: element,
          name: element
        }))
        props.columnInfo.userInput = defaultValue
      } else if (_inputData.statsType === 'NUMERIC') {
        props.columnInfo.userInput = defaultValue
        _inputData.valueList = columnInfo
      } else if (_inputData.statsType === 'DATETIME') {
        props.columnInfo.userInput = defaultValue
        _inputData.datetimeInfo = {
          start: columnInfo.start,
          end: columnInfo.end,
          datePattern: 'yyyy-MM-dd HH:mm:ss' // 目前後端給的 datePattern 沒有用到，前端先暫定這種
        }
      }

      emit('done')
      inputData.value = _inputData
    }

    const searchValue = (columnId, searchString) => {
      return dataValueSearch(columnId, {
        page: 0,
        searchString,
        size: 200,
        // restrictions: props.restrictions.length > 0 ? props.restrictions : null
        restrictions: null
      })
    }

    const configInputData = () => {
      Promise.all([
        ...((props.columnInfo.statsType === 'NUMERIC' || props.columnInfo.statsType === 'DATETIME') ? [searchNumericColumnValueRange(props.modelId, props.columnInfo.columnId, {
          // restrictions: props.restrictions.length > 0 ? props.restrictions : null
          restrictions: null
        })] : []),
        ...((props.columnInfo.statsType === 'CATEGORY' || props.columnInfo.statsType === 'BOOLEAN') ? [searchValue(props.columnInfo.columnId, '')] : []),
        searchColumnDefaultValue(props.modelId, props.columnInfo.columnId, {
          restrictions: props.restrictions
        })
      ])
        .then(([columnValueInfo, defaultValue]) => {
          handleColumnValue(columnValueInfo, defaultValue)
        })
        .catch((err) => {
          emit('failed', err?.error?.message || null)
        })
    }

    onMounted(() => {
      configInputData()
    })

    // ----- computed -----
    const isEmptyData = computed(() => {
      if (!inputData.value.statsType) return false
      switch (inputData.value.statsType) {
        case 'NUMERIC':
          return inputData.value.valueList.min === null || inputData.value.valueList.max === null
        case 'DATETIME':
          return inputData.value.datetimeInfo.start === null || inputData.value.datetimeInfo.end === null
        case 'CATEGORY':
        case 'BOOLEAN':
          return !inputData.value.valueList || inputData.value.valueList.length === 0
      }
    })

    const getNumericTitle = computed(() => {
      if (!inputData.value.statsType || inputData.value.statsType !== 'NUMERIC') return
      if (isEmptyData.value) return inputData.value.columnName
      return inputData.value.columnName + '(' + t('miniApp.minAndMax', {
        min: Math.round(inputData.value.valueList.min * 100) / 100,
        max: Math.round(inputData.value.valueList.max * 100) / 100
      }) + ')'
    })

    const getDateTimeTitle = computed(() => {
      if (!inputData.value.statsType || inputData.value.statsType !== 'DATETIME') return
      if (isEmptyData.value) return inputData.value.columnName
      return inputData.value.columnName + '(' + t('miniApp.range') + ':' + customerTimeFormatter(inputData.value.datetimeInfo.start, 'SECOND') + ' - ' + customerTimeFormatter(inputData.value.datetimeInfo.end, 'SECOND') + ')'
    })

    const pickerOptions = computed(() => {
      return {
        disabledDate (time) {
          return time.getTime() > inputData.value.datetimeInfo.end || time.getTime() < inputData.value.datetimeInfo.start
        }
      }
    })

    return {
      // ----- general -----
      inputId,
      // ----- data fetch -----
      inputData,
      // ----- computed -----
      isEmptyData,
      getNumericTitle,
      getDateTimeTitle,
      pickerOptions
    }
  }
})
</script>

<style lang="scss" scoped>
.input-field {
  &__label {
    color: #aaa;
    font-size: 14px;
    font-weight: 600;
  }

  &__reminder {
    color: #ffdf6f;
    font-size: 12px;
  }

  ::v-deep .el-select-dropdown {
    width: 100%;
  }

  .el-input {
    width: 100%;
  }

  ::v-deep .sy-select.theme-dark {
    .el-input__inner {
      background: transparent;
      border-bottom: 1px solid #fff;
      border-radius: 0;
      font-size: 16px;
      padding-left: 0 !important; // 為了蓋掉 element-ui 樣式

      &::placeholder {
        color: #aaa;
        font-size: 16px;
        font-weight: normal;
      }
    }
  }

  ::v-deep .el-select {
    width: 100%;
  }

  ::v-deep .input-verify .input-verify-text {
    color: #fff;
    margin-bottom: 0;
  }

  ::v-deep .input-error.error-text {
    bottom: -17px;
    position: unset;
  }

  ::v-deep .el-date-editor .el-input__inner {
    background-color: transparent;
    border-bottom: 1px solid #fff;
    border-radius: 0;
    font-size: 16px;
    padding: 0 !important; // 為了蓋掉 element-ui 樣式
  }
}
</style>
