<template>
  <div class="management-info-page">
    <spinner
      v-if="isLoading"
      :title="$t('editing.loading')"
    />
    <result-board
      v-else
      :segmentation-payload="segmentationPayload"
      :result-id="resultId"
      :result-info="resultInfo"
      :restrictions="restrictions"
      :pinboard-group-id="pinboardGroupId"
      :pinboard-account-id="pinboardAccountId"
      @refresh="refreshPinboardData"
      @unPin="unPin"
    >
      <result-board-body slot="PageResultBoardBody">
        <template
          v-if="currentModelId && resultInfo.canDoList.length > 0"
          slot="multiAnalyPanel"
        >
          <ul class="multi-analysis__list">
            <div class="model-label">
              {{ $t('denotation.adoptModel') }}
            </div>
            <li
              class="multi-analysis__switch"
            >
              <custom-dropdown-select
                :data-list="modelListData()"
                :has-bullet-point="false"
                :selected-id="currentModelId"
                :is-show-id="true"
                trigger="hover"
                @select="switchModel($event)"
              >
                <template #display>
                  <span class="dropdown-label">
                    {{ modelName }}
                    <svg-icon
                      icon-class="triangle"
                      class="icon-triangle"
                    />
                  </span>
                </template>
              </custom-dropdown-select>
            </li>
            <li
              v-for="typeInfo in switchTypeList"
              :key="typeInfo.denotation"
              :class="{ 'is-active': activeTab === typeInfo.denotation }"
              class="multi-analysis__item"
              @click="switchTab(typeInfo.denotation)"
            >
              <span class="multi-analysis__item-label">
                <svg-icon :icon-class="typeInfo.icon" />
                {{ typeInfo.name }}
              </span>
              <div class="multi-analysis__item-status">
                <spinner
                  v-if="typeInfo.isProcessing"
                  size="16"
                />
                <div
                  v-if="activeTab === 'MODEL_PREDICTION' && typeInfo.denotation === 'MODEL_PREDICTION' && isOneNumericPredictSentence"
                  class="multi-analysis__item-dropdownlist"
                >
                  <svg-icon
                    icon-class="more"
                    class="more-icon"
                  />
                  <dropdown-select
                    :bar-data="barData[typeInfo.denotation]"
                    class="dropdown"
                    @switchDialogName="switchDialogName($event)"
                  />
                </div>
              </div>
            </li>
          </ul>
        </template>
        <template v-if="general">
          <template slot="PageResultBoardChart">
            <div class="general-result">
              <div class="general-result__histogram">
                {{ resultInfo.model_predict_basic_info ? $t('resultDescription.predictDistribution') : $t('resultDescription.predictResult') }}
                <remind-popover
                  v-if="resultInfo.model_predict_basic_info"
                  :content="$t('resultDescription.reminder.predictDistribution')"
                  icon-name="description"
                />
              </div>
              <div class="general-result__numeric">
                <!-- histogram or bar -->
                <task
                  v-if="resultInfo.model_predict.length"
                  :component-id="resultInfo.model_predict[0]"
                  :key="resultInfo.model_predict[0]"
                  :chart-toolbox-setting="chartToolboxSetting"
                  :class="{ 'diagram': resultInfo.model_predict_basic_info }"
                  intend="key_result"
                />
                <!-- model basic info -->
                <task
                  v-if="resultInfo.model_predict_basic_info"
                  :component-id="resultInfo.model_predict_basic_info[0]"
                  :key="resultInfo.model_predict_basic_info[0]"
                  :class="{ 'model-info': resultInfo.model_predict_basic_info }"
                  intend="key_result"
                />
              </div>
              <div class="panel">
                <el-tabs
                  v-if="modelPredictInfo"
                  ref="tabs"
                  v-model="activeTabName"
                  type="card"
                  @tab-click="handleClick"
                >
                  <el-tab-pane
                    :label="$t('resultDescription.tab.predictContent')"
                    :name="$t('resultDescription.tab.predictContent')"
                  >
                    <div class="panel__title">
                      {{ $t('resultDescription.predictContent') }}
                      <remind-popover
                        :content="$t('resultDescription.reminder.predictContent')"
                        icon-name="description"
                      />
                    </div>
                    <task
                      :component-id="resultInfo.model_predict[1]"
                      :key="resultInfo.model_predict[1]"
                      :is-show-model-table="true"
                      intend="key_result"
                    />
                  </el-tab-pane>
                  <el-tab-pane
                    :label="$t('resultDescription.tab.currentModelPerformance')"
                    :name="$t('resultDescription.tab.currentModelPerformance')"
                  >
                    <template v-if="!actualColumnId">
                      <no-result :message="$t('resultDescription.noActualColumn')" />
                    </template>
                    <template v-else>
                      <div
                        v-if="isShowPerformance"
                        class="info-block"
                      >
                        <model-performance :training-type="modelTrainingType">
                          <template slot="description">
                            <div class="description description-content">
                              <i18n
                                path="modelPerformance.performanceDescription"
                                tag="span"
                              >
                                <template #testing_data>
                                  <span class="description__highlight">{{ simulatedFrameName }}</span>
                                </template>
                                <template #testing_data_count>
                                  <span class="description__highlight">{{ testingResponse.simulateDataCount || testingResponse.modelPerformance.testingCount }}{{ $t('modelPerformance.record') }}</span>
                                </template>
                              </i18n>
                              <i18n
                                v-if="ignoreCount"
                                path="modelPerformance.ignoreDescription"
                                tag="span"
                              >
                                <template #ignore_count>
                                  <span class="description__highlight">{{ ignoreCount }}{{ $t('modelPerformance.record') }}</span>
                                </template>
                              </i18n>
                              <span
                                v-if="modelTrainingType === 'Binary'"
                                class="description__highlight"
                              >
                                {{ $t('modelInfo.threshold') }} = {{ autoRound(modelInfo.threshold) }}
                              </span>
                            </div>
                          </template>
                          <template slot="metrics">
                            <model-task
                              :component-id="testingResponse.metricsId"
                              :training-type="modelTrainingType"
                            />
                          </template>
                          <template
                            v-if="modelTrainingType === 'Regression'"
                            slot="chart"
                          >
                            <div class="group-title">
                              {{ $t('modelPerformance.modelDeviationDistribution') }}
                              <remind-popover
                                :content="$t('modelPerformance.reminder.modelDeviationDistributionExplain')"
                                icon-name="description"
                              />
                            </div>
                            <model-task
                              :component-id="testingResponse.residualDistributionId"
                              :is-show-toolbox="false"
                              :is-show-warning-enable="false"
                              height="275px"
                              direction="horizontal"
                            />
                          </template>
                          <template
                            v-else-if="modelTrainingType === 'Binary'"
                            slot="chart"
                          >
                            <div class="group-title">
                              {{ $t('model.confusionMatrix') }}
                              <el-popover
                                :offset="-60"
                                width="548"
                                trigger="hover"
                                placement="bottom-start"
                                popper-class="el-popover--training-reminder"
                              >
                                <svg-icon
                                  slot="reference"
                                  style="color: #A7A7A7"
                                  icon-class="description"
                                />
                                <metric-content
                                  :metrics-type="modelTrainingType"
                                  display-metric="matrix"
                                />
                              </el-popover>
                            </div>
                            <model-task
                              :component-id="testingResponse.confusionMatrixId"
                              :height="300"
                              class="confusion-matrix"
                            />
                          </template>
                        </model-performance>
                        <div
                          v-if="testingResponse.currentDataDistributionComparisonId"
                          class="info-block__group"
                        >
                          <div class="info-block__title">
                            {{ $t('modelPerformance.compareResultPerformance') }}
                            <remind-popover
                              :content="$t('modelPerformance.reminder.compareResultPerformance')"
                              icon-name="description"
                              width="250"
                            />
                          </div>
                          <div class="info-block__content">
                            <model-task
                              :component-id="testingResponse.currentDataDistributionComparisonId"
                              :is-show-toolbox="false"
                              :is-show-warning-enable="false"
                              intend="key_result"
                            />
                          </div>
                        </div>
                        <div class="info-block__group">
                          <div class="info-block__title">
                            {{ $t('modelTesting.compareResultDeviation') }}
                            <remind-popover
                              :content="$t('modelTesting.reminder.compareResultDeviation')"
                              icon-name="description"
                            />
                          </div>
                          <template v-if="modelTrainingType === 'Regression'">
                            <div class="info-block__content">
                              <model-task
                                v-if="testingResponse.residualDistributionComparisonId"
                                :component-id="testingResponse.residualDistributionComparisonId"
                                :is-show-toolbox="false"
                                width="70%"
                                intend="key_result"
                              />
                            </div>
                          </template>
                          <template v-else-if="modelTrainingType === 'Binary'">
                            <div class="curve-container">
                              <div class="info-block__content curve-container__left">
                                <div class="group-title">
                                  {{ $t('modelTesting.compareROC') }}
                                  <el-popover
                                    :offset="-60"
                                    width="548"
                                    trigger="hover"
                                    placement="bottom-start"
                                    popper-class="el-popover--training-reminder"
                                  >
                                    <svg-icon
                                      slot="reference"
                                      style="color: #A7A7A7"
                                      icon-class="description"
                                    />
                                    <metric-content
                                      :metrics-type="modelTrainingType"
                                      display-metric="ROC"
                                      description-type="Testing"
                                    />
                                  </el-popover>
                                </div>
                                <model-task
                                  v-if="testingResponse.ROCComparisonTcId"
                                  :component-id="testingResponse.ROCComparisonTcId"
                                  :is-smooth="true"
                                  :is-show-coefficients="true"
                                  :is-show-formula="false"
                                  :is-show-toolbox="false"
                                  height="260px"
                                />
                              </div>
                              <div class="info-block__content curve-container__right">
                                <div class="group-title">
                                  {{ $t('modelTesting.comparePR') }}
                                  <el-popover
                                    :offset="-60"
                                    width="548"
                                    trigger="hover"
                                    placement="bottom-start"
                                    popper-class="el-popover--training-reminder"
                                  >
                                    <svg-icon
                                      slot="reference"
                                      style="color: #A7A7A7"
                                      icon-class="description"
                                    />
                                    <metric-content
                                      :metrics-type="modelTrainingType"
                                      display-metric="PR"
                                      description-type="Testing"
                                    />
                                  </el-popover>
                                </div>
                                <model-task
                                  v-if="testingResponse.PRComparisonTcId"
                                  :component-id="testingResponse.PRComparisonTcId"
                                  :is-smooth="true"
                                  :is-show-coefficients="true"
                                  :is-show-formula="false"
                                  :is-show-toolbox="false"
                                  height="260px"
                                />
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </template>
                  </el-tab-pane>
                  <el-tab-pane
                    :label="$t('resultDescription.tab.modelInfo')"
                    :name="$t('resultDescription.tab.modelInfo')"
                  >
                    <div class="panel__title">
                      {{ $t('resultDescription.tab.modelInfo') }}
                      <span @click="goToModelInfo">
                        <svg-icon
                          icon-class="open-link"
                          class="link"
                        />
                      </span>
                    </div>
                    <div class="row-info">
                      <span class="row-info__label">ID :</span>
                      <span class="row-info__value">{{ modelInfo.id }}</span>
                    </div>
                    <div class="row-info">
                      <span class="row-info__label">{{ $t('modelInfo.name') }} :</span>
                      <span class="row-info__value">{{ modelInfo.name }}</span>
                    </div>
                    <div class="row-info">
                      <span class="row-info__label">{{ $t('modelInfo.modelTrainingType') }} :</span>
                      <span class="row-info__value">{{ $t(`modelInfo.${modelInfo.modelTrainingType.toLowerCase()}Model`) }}</span>
                    </div>
                    <div class="row-info">
                      <span class="row-info__label">{{ $t('modelInfo.modelGeneratedType') }} :</span>
                      <span class="row-info__value">{{ modelInfo.modelGeneratedType ? $t('model.modelTraining') : $t('editing.userUpload') }}</span>
                    </div>
                    <div class="row-info">
                      <span class="row-info__label">{{ $t('modelInfo.createdBy') }} :</span>
                      <span class="row-info__value">{{ modelInfo.createdBy }}</span>
                    </div>
                    <div class="row-info">
                      <span class="row-info__label">{{ $t('modelInfo.createdAt') }} :</span>
                      <span class="row-info__value">{{ timeToDateTime(modelInfo.createdAt) }}</span>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane
                    :label="$t('resultDescription.tab.modelExplanation')"
                    :name="$t('resultDescription.tab.modelExplanation')"
                  >
                    <div v-if="isShowExplanation">
                      <div class="panel__title">
                        {{ $t('modelTraining.featureImportance') }}
                        <remind-popover
                          :content="$t('modelExplanation.reminder.featureImportance')"
                          icon-name="description"
                        />
                      </div>
                      <model-task
                        :component-id="modelPredictExplanation.modelPredictExplanationChartId"
                        :is-show-toolbox="false"
                        :is-show-legend="false"
                        :is-warning-enable="false"
                        :is-show-label-data="true"
                      />
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </div>
          </template>
        </template>
        <template
          v-if="simulate && resultInfo.canDoList.includes('MODEL_SIMULATION')"
          slot="ModelSimulator"
        >
          <simulator
            :is-model-predict="true"
            :model-setting="modelPredictInfo"
            :data-column-map="dataColumnMap"
          />
        </template>
      </result-board-body>
      <template slot="dialogs">
        <histogram-bin-setting-dialog
          v-if="currentResultId && isShowHistogramBinSettingDialog"
          :is-model-predict="true"
          @re-analyze="reAnalyze"
          @close="closeDialog"
        />
      </template>
    </result-board>
  </div>
</template>

<script>
import { getModelInfo, getModelExplanation, getModelTestingTask } from '@/API/Model'
import Simulator from '@/pages/miniApp/miniApp/components/dashboard-components/Simulator'
import RemindPopover from '@/components/popover/RemindPopover.vue'
import DropdownSelect from '@/components/select/DropdownSelect'
import CustomDropdownSelect from '@/components/select/CustomDropdownSelect'
import HistogramBinSettingDialog from '@/components/dialog/HistogramBinSettingDialog'
import ModelPerformance from '@/pages/modelManagement/components/ModelPerformance'
import MetricContent from '@/pages/modelTraining/components/MetricContent'
import { defineComponent } from '@vue/composition-api'
import { useAskingModuleContext } from '@/modules/shared/asking'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default defineComponent({
  name: 'PredictResult',
  components: {
    DropdownSelect,
    CustomDropdownSelect,
    HistogramBinSettingDialog,
    Simulator,
    RemindPopover,
    ModelPerformance,
    MetricContent
  },
  props: {
    resultInfo: {
      type: Object,
      required: true
    },
    restrictions: {
      type: Array,
      default: () => []
    },
    resultId: {
      type: Number,
      default: null
    },
    dataFrameId: {
      type: Number,
      default: null
    },
    dataColumnMap: {
      type: Object,
      default: () => {}
    },
    intent: {
      type: String,
      default: null
    },
    segmentationPayload: {
      type: Object,
      default: () => {}
    },
    modelPredictInfo: {
      type: Object,
      default: () => {}
    },
    pinboardGroupId: {
      type: Number,
      default: null
    },
    pinboardAccountId: {
      type: Number,
      default: null
    }
  },
  setup () {
    const {
      setCurrentQuestionInfo,
      updateResultRouter,
      askSpecificType,
      filterRestrictionList
    } = useAskingModuleContext()
    return {
      setCurrentQuestionInfo,
      updateResultRouter,
      askSpecificType,
      filterRestrictionList
    }
  },
  data () {
    return {
      activeTab: null,
      switchTypeList: [],
      general: true,
      simulate: false,
      optimized: false,
      isLoading: true,
      isModelList: true,
      isShowHistogramBinSettingDialog: false,
      modelPredictExplanation: null,
      activeTabName: this.$t('resultDescription.tab.predictContent'),
      testingResponse: null,
      modelName: null,
      currentModelId: null,
      simulatedFrameName: null,
      modelInfo: null,
      modelTrainingType: null,
      ignoreCount: null,
      isShowPerformance: false,
      isShowExplanation: false,
      chartToolboxSetting: {
        show: true,
        dataZoom: false,
        magicType: false,
        restore: false,
        dataView: true,
        brush: false,
        saveAsImage: true,
        myShowLabel: false
      }
    }
  },
  computed: {
    ...mapState('result', ['currentResultId']),
    ...mapState('modelManagement', ['currentModelList']),
    ...mapState('dataSource', ['algoConfig', 'dataFrameList', 'actualColumnId']),
    ...mapGetters('userManagement', ['getCurrentAccountId', 'getCurrentGroupId']),
    barData () {
      return {
        MODEL_PREDICTION: [
          {
            title: 'editing.histogramBinSetting',
            icon: 'add-feature',
            dialogName: 'histogramBinSettingDialog'
          }
        ]
      }
    },
    isOneNumericPredictSentence () {
      const subjectList = this.segmentationPayload.transcript.subjectList[0]
      if (subjectList.predictedColumn?.statsType === 'NUMERIC' && !subjectList.dataColumn) {
        return true
      }
      return false
    }
  },
  mounted () {
    if (this.currentResultId) {
      this.activeTab = this.resultInfo.canDoList[0]
      this.switchTypeList = this.resultInfo.canDoList.map(type => ({
        denotation: type,
        ...this.getSwitchTypeInfoList(type),
        isProcessing: false,
        isFailed: false
      }))
    }
    this.fetchData()
  },
  destroyed () {
    this.setActualColumnId(null)
  },
  methods: {
    ...mapMutations('dataSource', ['setAlgoConfigModelId', 'setActualColumnId']),
    ...mapMutations('result', ['updateCurrentResultId']),
    ...mapActions('modelManagement', ['getModelList']),
    async fetchData () {
      this.isLoading = true
      try {
        if (this.modelPredictInfo) {
          await this.getModelList({ dataFrameId: this.dataFrameId, modelId: this.modelPredictInfo.modelId })
          let currentModel = this.currentModelList.find(model => model.id == this.algoConfig.modelPrediction.modelId)
          this.modelName = currentModel.name
          this.currentModelId = currentModel.id

          this.modelInfo = await getModelInfo(this.currentModelId)
          this.modelPredictExplanation = await getModelExplanation(this.currentModelId)
        }
        this.simulatedFrameName = this.dataFrameList.filter(frame => frame.id === this.dataFrameId)[0].primaryAlias

        if (this.actualColumnId) {
          getModelTestingTask(this.currentModelId, this.dataFrameId, {
            dataFrameId: this.dataFrameId,
            modelId: this.currentModelId,
            restrictions: this.filterRestrictionList
          })
            .then((response) => {
              this.modelTrainingType = this.lowercaseEachLetterExceptFirst(response.modelTrainingType)
              this.testingResponse = response[`testing${this.modelTrainingType}`]
              this.ignoreCount = this.modelTrainingType === 'Binary' ? this.testingResponse.modelPerformance.ignoreCount : null
            })
        }
      } finally {
        this.isLoading = false
      }
    },
    refreshPinboardData (refreshInfo) {
      this.$emit('refresh', refreshInfo)
    },
    unPin (pinBoardId) {
      this.$emit('unPin', pinBoardId)
    },
    switchTab (tabName) {
      this.activeTab = tabName
      switch (tabName) {
        case 'MODEL_PREDICTION':
          this.general = true
          this.simulate = false
          this.optimized = false
          break
        case 'MODEL_SIMULATION':
          this.general = false
          this.simulate = true
          this.optimized = false
          break
      }
    },
    switchDialogName (action) {
      if (action === 'histogramBinSettingDialog') {
        this.isShowHistogramBinSettingDialog = true
      }
    },
    fetchSpecificType (type, data) {
      const targetTypeInfo = this.switchTypeList[0]

      targetTypeInfo.isProcessing = true

      this.askSpecificType({
        resultId: this.currentResultId,
        type: type,
        settingConfig: {
          displayConfig: {
            histogramBinSize: data
          }
        }
      })
        .then(({ resultId }) => {
          this.switchTypeList[0] = {
            ...targetTypeInfo,
            isProcessing: false
          }
          this.updateCurrentResultId(resultId)
          this.$emit('fetch-new-components-list', resultId)
        })
        .catch(() => this.clearActiveTabProcessingStatus(this.activeTab))
    },
    closeDialog () {
      this.isShowHistogramBinSettingDialog = false
    },
    reAnalyze (type, data) {
      this.fetchSpecificType(type, data)
      this.closeDialog()
    },
    clearActiveTabProcessingStatus (tab) {
      this.switchTypeList.find(type => type.denotation === tab).isProcessing = false
    },
    switchModel (modelId) {
      this.setAlgoConfigModelId(modelId)
      this.setCurrentQuestionInfo(this.segmentationPayload)
      this.updateResultRouter('key_in')
    },
    modelListData () {
      return this.currentModelList
        .map(model => ({
          id: model.id,
          name: model.name
        }))
        .sort((mOne, mTwo) => (mOne.name.toLowerCase() > mTwo.name.toLowerCase()) ? 1 : -1)
    },
    goToModelInfo () {
      this.$router.push({
        name: 'ModelInfo',
        params: {
          account_id: this.getCurrentAccountId,
          group_id: this.getCurrentGroupId,
          model_id: this.currentModelId
        }
      })
    },
    handleClick (tab, event) {
      if (tab.name === this.$t('resultDescription.tab.currentModelPerformance')) {
        this.isShowPerformance = true
      } else if (tab.name === this.$t('resultDescription.tab.modelExplanation')) {
        this.isShowExplanation = true
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.management-info-page {
  .dropdown-label {
    .svg-icon {
      margin-left: 15px;
    }
    .icon-triangle {
      font-size: 9px;
      transform: rotate(180deg);
    }
  }

  ::v-deep .dropdown {
    &__list-container {
      left: calc(0% - 20px);
      top: calc(100% + 10px);
      text-align: left;
      z-index: 1;

      &::before {
        position: absolute;
        content: '';
        width: 86%;
        bottom: 100%;
        height: 12px;
        background: transparent;
      }
      &::after {
        position: absolute;
        content: '';
        bottom: 100%;
        left: 40%;
        border-bottom: 8px solid #2B3839;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
      }
    }

    &__list {
      max-height: 200px;
      overflow-y: auto;

      &::-webkit-scrollbar-track {
        border-radius: 5px;
      }
    }
  }

  .panel {
    &__title {
      margin: 20px 0 15px;
      font-weight: 600;
    }
    .description {
      font-size: 13px;
      font-weight: 400;
      color: #DDDDDD;
      &:not(:first-of-type) {
        margin-bottom: 16px;
      }
      &__highlight {
        color: #44D2FF;
      }
    }
    .curve-container {
      display: flex;

      &__left {
        flex: 1 1;
        margin-right: 16px;
      }
      &__right {
        flex: 1 1;
      }
    }
    .info-block{
        &__group {
          margin-bottom: 16px;
      }
    }
  }

  ::v-deep .el-tabs {
    &>.el-tabs__header {
      border: none;
      margin: 0;
      width: 100%;

      .el-tabs__nav {
        position: relative;
        width: 100%;
        border: none;
        overflow-x: auto;

        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 3px;
          background: #324B4E;
        }
      }
      .el-tabs__item {
        border: none;
        color:  #646464;
        font-size: 14px;
        font-weight: 600;
        border-bottom: 1px solid #3B4343;
        text-align: center;
        width: 130px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: top;

        [lang="en"] & {
          width: 260px;
        }
        &.is-active {
          color: $theme-text-color-primary;
          border-bottom: 3px solid $theme-text-color-primary;
        }

      }
    }
  }

  ::v-deep .group-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1em;
  }

  .row-info {
    display: flex;
    line-height: 36px;
    &__label {
      width: 200px;
      margin-right: 60px;
    }
    &__value {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

</style>
