export default {
  setEventParams (state, { event, params }) {
    state.params[event] = { ...state.params[event], ...params }
  },
  clearEventParams (state, event) {
    state.params[event] = {}
  },
  resetAskEvent (state, data) {
    const { askQuestion } = state.params
    for (let key in askQuestion) {
      askQuestion[key] = null
    }
  }
}
