// 標註千分位
export default function (str) {
  if (!str || isNaN(str)) return str

  // 如果進來的字串已經有帶千分位先拔除
  if (typeof str === 'string') {
    str = str.replace(/,/g, '')
    if (isNaN(Number(str))) return str
  }

  const regex = /\B(?=(\d{3})+(?!\d))/g
  // 只處理整數位，不處理小數點位
  return Number.isInteger(Number(str))
    ? str.toString().replace(regex, ',')
    : str.toString().split('.')[0].replace(regex, ',') + '.' + str.toString().split('.')[1]
}
