<template>
  <div class="display-multi-axis-line-chart">
    <v-echart
      :style="chartStyle"
      :option="options"
      auto-resize
    />
  </div>
</template>

<script>
import EchartAddon from './common/addon.js'
import { commonChartOptions } from '@/components/display/common/chart-addon.js'
import chartVariable from '@/styles/chart/variables.scss'
import {
  colorOnly1,
  colorOnly2,
  color5,
  color12,
  gridDefault,
  xAxisDefault,
  yAxisMultiple,
  seriesItemLine
} from './common/addons'
const echartAddon = new EchartAddon({
  'grid:default': gridDefault(),
  'xAxis:default': xAxisDefault(),
  'yAxis:multiple': yAxisMultiple(),
  'seriesItem:line': seriesItemLine()
})

export default {
  name: 'DisplayMultiXAxisLineChart',
  props: {
    dataset: {
      type: Object,
      default: () => ({})
    },
    title: {
      type: Object,
      default: () => {
        return {
          xAxis: null,
          yAxis: null
        }
      }
    },
    height: {
      type: String,
      default: '420px'
    },
    isShowToolbox: {
      type: Boolean,
      default: true
    },
    isShowCoefficients: {
      type: Boolean,
      default: true
    },
    handlerFunction: {
      type: String,
      default: null
    }
  },
  data () {
    echartAddon.mapping({
      'seriesItem:line': {
        large: true
      },
      'color:10': {},
      'grid:default': {},
      'xAxis:default': {},
      'yAxis:multiple': {}
    })
    return {
      addonOptions: JSON.parse(JSON.stringify(echartAddon.options)),
      addonSeriesItem: JSON.parse(JSON.stringify(echartAddon.seriesItem)),
      addonSeriesItems: JSON.parse(JSON.stringify(echartAddon.seriesItems))
    }
  },
  computed: {
    chartStyle () {
      return {
        width: '100%',
        height: this.height
      }
    },
    series () {
      if (this.dataset.display_columns) {
        return this.dataset.display_columns.map((element, colIndex) => this.composeColumn(element, colIndex))
      } else {
        return this.dataset.columns.map((element, colIndex) => this.composeColumn(element, colIndex))
      }
    },
    options () {
      let config = {
        ...this.addonOptions,
        ...JSON.parse(JSON.stringify(commonChartOptions())),
        dataset: [
          {
            source: this.composeSource(0)
          },
          {
            source: this.composeSource(1)
          }
        ],
        series: this.series,
        color: this.colorList
      }
      config.toolbox.show = this.isShowToolbox
      config.tooltip.formatter = this.tooltipFormatter
      config.tooltip.position = function (pos, params, dom, rect, size) {
        return {
          top: Math.max(pos[1] - 80, 0),
          left: ['left', 'right'][+(pos[0] < size.viewSize[0] / 2)] === 'left'
            ? pos[0] - (size.contentSize[0] + 20)
            : pos[0] + 20
        }
      }
      config.tooltip.transitionDuration = 0
      config.tooltip.extraCssText = 'max-height:200px;overflow:auto;'
      config.xAxis = this.title.xAxis.map((axis, index) => {
        return {
          ...config.xAxis
        }
      })
      config.xAxis.push({
        ...this.addonOptions.xAxis,
        axisLabel: {
          show: false
        }
      })
      config.yAxis = this.title.yAxis.map((axis, index) => {
        return {
          ...config.yAxis,
          min: 0,
          nameTextStyle: {
            color: chartVariable.textColor,
            align: index % 2 === 0 ? 'left' : 'right'
          },
          offset: Math.floor(index / 2) * 35,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        }
      })
      config.yAxis.push({
        ...this.addonOptions.yAxis,
        type: 'value',
        min: 0,
        nameTextStyle: {
          color: chartVariable.textColor,
          align: 'right'
        },
        offset: Math.floor(1 / 2) * 35,
        axisLine: {
          show: false
        },
        axisLabel: {
          show: false
        },
        axisTick: {
          show: false
        }
      })

      const coefficients = this.dataset.coeffs
      const lineData = []
      if (this.isShowCoefficients && this.dataset.coeffs) {
        let offset = coefficients[0]
        let gradient = coefficients[1]

        let store = []
        this.dataset.index.forEach((indexList) => {
          if (!store.includes(indexList[0])) {
            store.push(indexList[0])
          }
        })
        let x = 0
        let divideNumber = 1 / (store.length - 1)
        for (let i = 0; i < store.length; i++) {
          lineData.push(this.roundNumber(gradient * x + offset))
          x += divideNumber
        }
      }

      config.series.push({
        name: '',
        type: 'line',
        showSymbol: false,
        smooth: true,
        color: '#FF9559',
        symbol: 'none',
        data: lineData,
        lineStyle: {
          type: 'dashed'
        }
      })
      // automl用
      if (this.handlerFunction?.match(/MODEL_(PERFORMANCE|TESTING)/g) && this.dataset.index.length < 5) {
        config.xAxis.forEach((x) => {
          x.type = 'value'
        })
      }
      return config
    },
    colorList () {
      switch (this.series.length) {
        case 1:
          return colorOnly1
        case 2:
          return colorOnly2
        case 3:
        case 4:
        case 5:
          return color5
        default:
          return color12
      }
    }
  },
  methods: {
    composeColumn (element, colIndex) {
      const labelFormatter = this.chartLabelFormatter
      const maxValue = this.getChartMaxData(this.dataset.data)
      return {
        // 如果有 column 經過 Number() 後為數字 ，echart 會畫不出來，所以補個空格給他
        name: isNaN(Number(element)) ? element : ' ' + element,
        ...this.addonSeriesItem,
        ...this.addonSeriesItems[colIndex],
        connectNulls: true,
        xAxisIndex: colIndex,
        yAxisIndex: colIndex,
        datasetIndex: colIndex,
        ...(this.isShowLabelData && {
          label: {
            position: 'top',
            show: true,
            fontSize: 10,
            color: '#fff',
            formatter (value) {
              let num = value.data[colIndex + 1]
              return labelFormatter(num, maxValue[colIndex])
            }
          }
        })
      }
    },
    tooltipFormatter (datas) {
      const orderTarget = this.orderBy?.target || 'value'
      const orderDirection = this.orderBy?.direction || 'desc'

      return datas.sort((a, b) => {
        const targetA = orderDirection === 'desc' ? a : b
        const targetB = orderDirection === 'desc' ? b : a
        if (orderTarget === 'key') {
          return targetB.seriesName.localeCompare(targetA.seriesName)
        } else if (orderTarget === 'value') {
          return targetB.value[targetB.componentIndex + 1] - targetA.value[targetA.componentIndex + 1]
        }
      }).reduce((str, item) => {
        const componentIndex = item.componentIndex
        const marker = item.marker ? item.marker : `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${item.color.colorStops[0].color};"></span>`
        if (item.value[componentIndex] !== null && item.value[componentIndex] !== undefined) {
          return str += marker + item.seriesName + '：' + this.formatComma(item.value[componentIndex]) + '<br/>'
        }
        return str
      }, `${datas[0].name}<br/>`)
    },
    composeSource (index) {
      const indexList = this.dataset.index.map((indexList) => indexList[index]).filter((i) => i)
      const dataset = JSON.parse(JSON.stringify(this.dataset))
      dataset.data.forEach((dataList) => {
        dataList.splice(1 - index, 1)
      })
      dataset.index = indexList
      dataset.display_columns.splice(index, 1)
      dataset.columns.splice(index, 1)
      return this.datasetTransform(dataset)
    }
  }
}
</script>
