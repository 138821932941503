export default {
  SET_MINI_APP_LIST (state, newList) {
    state.miniAppList = newList
  },
  SET_IS_INITIALIZED (state, bool) {
    state.isInitialized = bool
  },
  SET_POLLING_TIMEOUT_ID (state, id) {
    state.pollingTimeoutId = id
  },
  ADD_PENDING_APP_INFO_TO_LIST (state, info) {
    state.pendingAppInfoList.push(info)
  },
  REMOVE_PENDING_APP_INFO_FROM_LIST_BY_ID (state, id) {
    const index = state.pendingAppInfoList.findIndex((info) => info.id === id)
    if (index === -1) return
    state.pendingAppInfoList.splice(index, 1)
  }
}
