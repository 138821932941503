<template>
  <div class="insight-wrapper">
    <div class="title">
      <svg-icon
        :icon-class="'len-with-line-chart'"
      />
      {{ $t('resultDescription.dataInsight') }}
    </div>
    <slot name="insight" />
  </div>
</template>

<script>
export default {
  name: 'ResultInsightWrapper'
}
</script>

<style lang="scss" scoped>
.insight-wrapper {
  background-color: #141c1d;
  border-radius: 8px;
  color: #ccc;
  line-height: 24px;
  padding: 20px;

  .title {
    color: #eee;
    font-size: 18px;
  }
}
</style>
