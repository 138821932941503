import { registerAskingModuleContext } from '@/modules/shared/asking'
import { MINI_APP_MODULE_NAMESPACE } from '@/modules/namespace'
import { useMiniAppModuleStore } from './store'
import { defineContext } from '@/utils/composable/context'

export { MINI_APP_MODULE_NAMESPACE } from '@/modules/namespace'
export { miniAppModuleStore, useMiniAppModuleStore } from './store'

export const {
  registerContext: registerMiniAppModuleContext,
  useContext: useMiniAppModuleContext
} = defineContext({
  namespace: MINI_APP_MODULE_NAMESPACE,
  createContext () {
    const askingModuleContext = registerAskingModuleContext({
      extendNamespace: MINI_APP_MODULE_NAMESPACE
    })
    const store = useMiniAppModuleStore()
    return {
      askingModuleContext,
      ...store
    }
  }
})
