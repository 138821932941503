import { newPlan, reSimulate, checkSimulationProgress, cancelSimulationPlan, deleteSimulatedSolution } from '@/schedule/API/Simulation'
import { DEFAULT_JOB_TABLE_COLUMN } from '@/schedule/utils/enum'
import { stringToFloat } from '@/schedule/utils/utils'

export default {
  newPlan ({ state, rootState, rootGetters }) {
    // formate orders data
    const priorityKeyName = DEFAULT_JOB_TABLE_COLUMN.PRIORITY.toLowerCase()

    const orders = Object
      .values(state.simulationJobs)
      .filter(job => job.frontendInfo.checked)
      .map(job => ({
        delayed: job.delayed,
        fullData: job.fullData,
        id: job.id,
        jobName: job.job,
        [priorityKeyName]: job.priority
      }))

    // format solutions data
    const solutions = state.solutions.map(solution => {
      // NOTICE 邏輯重複，在 sp2 已整進 actions 中
      // 不是鈺齊排程，要還原回僅有 ranges 的 worktimes
      const formattedWorktimes = {}
      if (!rootGetters['scheduleSetting/isYKSchedule']) {
        for (const [weekday, shiftInfo] of Object.entries(solution.worktimes)) {
          formattedWorktimes[weekday] = shiftInfo.ranges
        }
      }

      return {
        ...solution,
        worktimes: rootGetters['scheduleSetting/isYKSchedule'] ? solution.worktimes : formattedWorktimes,
        splitMergeSetting: {
          splitMergeType: solution.splitMergeSetting.splitMergeType,
          splitMergeProductExcludeList: solution.splitMergeSetting.productSplitMergeBlacklist,
          operationSplitMergeSettings: solution.splitMergeSetting.operationSplitMergeRules,
          productSplitThreshold: stringToFloat(solution.splitMergeSetting.splitThreshold),
          productMergeThreshold: stringToFloat(solution.splitMergeSetting.mergeThreshold)
        }
      }
    })
    return newPlan({
      projectId: rootState.scheduleSetting.scheduleProjectId,
      orders,
      solutions
    })
  },
  yukiNewPlan ({ state, rootState }) {
    return newPlan({
      projectId: rootState.scheduleSetting.scheduleProjectId,
      orders: state.scheduledJobs,
      solutions: state.solutions,
      allOrder: state.selectAllOrders,
      ...(state.searchOrderNumber ? {
        orderNumber: state.searchOrderNumber
      } : ''),
      ...(state.orderPeriod.length > 0 ? {
        startDate: state.orderPeriod[0],
        endDate: state.orderPeriod[1]
      } : ''),
      returnOrders: false
    })
  },
  checkSimulationProgress ({ state }) {
    return checkSimulationProgress(state.planId)
  },
  cancelSimulationPlan ({ state }) {
    return cancelSimulationPlan(state.planId)
  },
  reSimulate ({ state }) {
    return reSimulate({
      planId: state.planId,
      solutions: state.solutions.filter(s => !s.simulated)
    })
  },
  deleteSimulatedSolution ({ state }, solutionId) {
    return deleteSimulatedSolution(state.planId, solutionId)
  },
  resetSimulationProgress ({ commit }) {
    commit('setSolutions', [])
    commit('setPlanId', null)
    commit('setSimulationResult', {
      completedSolutionIds: [],
      failedSolutionIds: [],
      inProgressIds: []
    })
  }
}
