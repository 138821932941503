export default {
  updateSetting (state, newSetting) {
    state.defaultSetting = newSetting
  },
  setEquipments (state, equipments) {
    state.equipments = equipments
  },
  setProjects (state, projects) {
    state.scheduleProjects = projects
  },
  setCurrentProjectId (state, id) {
    state.scheduleProjectId = id
  },
  setIsShowScheduleHelper (state, value) {
    state.isShowScheduleHelper = value
  },
  setGlobalJobStatusRestriction (state, property) {
    state.globalJobStatusRestriction = property ? property.toLowerCase() : null
  },
  setDynamicJobTableColumns (state, dynamicJobTableColumns) {
    state.dynamicJobTableColumns = dynamicJobTableColumns
  },
  setHasCurrentPlan (state, hasCurrentPlan) {
    state.hasCurrentPlan = hasCurrentPlan
  }
}
