import { registerAskingModuleContext } from '@/modules/shared/asking'
import { DATA_MANAGEMENT_MODULE_NAMESPACE } from '@/modules/namespace'
import { defineContext } from '@/utils/composable/context'

export { DATA_MANAGEMENT_MODULE_NAMESPACE } from '@/modules/namespace'
export { dataManagementModuleStore } from './store'

export const {
  registerContext: registerDataManagementModuleContext,
  useContext: useDataManagementModuleContext
} = defineContext({
  namespace: DATA_MANAGEMENT_MODULE_NAMESPACE,
  createContext () {
    const askingModuleContext = registerAskingModuleContext({
      extendNamespace: DATA_MANAGEMENT_MODULE_NAMESPACE
    })
    return {
      askingModuleContext
    }
  }
})
