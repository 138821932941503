export const DATA_CATEGORY = {
  JOB: 'JOB',
  ORDER: 'ORDER',
  RAW_DATA: 'RAW_DATA',
  CONSTRAINT: 'CONSTRAINT',
  ROLLING: 'ROLLING'
}

export const DATAFRAME_STATUS = {
  BOUND: 'BOUND',
  UNBOUND: 'UNBOUND',
  DELETE: 'DELETE'
}

export const ALERT_TYPE = {
  ALERT_OFF: 'ALERT_OFF',
  ALERT_QUANTITY: 'ALERT_QUANTITY',
  ALERT_PERCENTAGE: 'ALERT_PERCENTAGE'
}

export const UPDATE_TYPE = {
  PRESET: 'PRESET',
  CUSTOM: 'CUSTOM',
  OFF: 'OFF'
}

export const SPLIT_MERGE_TYPE = {
  OFF: 'OFF',
  PRODUCT: 'PRODUCT',
  OPERATION: 'OPERATION'
}

export const NOTIFICATION = {
  DELAY: 'DELAY'
}

export const JOB_STATUS = {
  UNSCHEDULED: 'UNSCHEDULED',
  SCHEDULED: 'SCHEDULED',
  GENERAL: 'GENERAL',
  OVERDUE: 'OVERDUE',
  DELAY: 'DELAYED',
  OVERDUE_DELAY: 'OVERDUE_DELAY'
}

export const SIMULATION_RESULT = {
  FAILED: 'FAILED',
  COMPLETED: 'COMPLETED'
}

export const SOLUTION_RESULT_TYPE = {
  DELIVERY: 'DELIVERY',
  DETAIL: 'DETAIL',
  GANTT: 'GANTT',
  WITHIN_SCHEDULE_COMPARE: 'WITHIN_SCHEDULE_COMPARE',
  ADJUST_HISTORY: 'ADJUST_HISTORY'
}

export const DEFAULT_JOB_TABLE_COLUMN = {
  JOB: 'Job',
  ORDER: 'Order',
  PRODUCT: 'Product',
  QUANTITY: 'Quantity',
  PRIORITY: 'Priority',
  DEADLINE: 'Deadline'
}
