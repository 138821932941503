<template>
  <div class="info-block__group">
    <div class="model-performance">
      <div class="info-block__title model-performance__title">
        {{ $t('modelPerformance.performance') }}
        <slot name="description" />
      </div>
      <div class="model-performance__metrics">
        <slot name="metrics" />
      </div>
      <div class="info-block__content model-performance__chart">
        <slot name="chart" />
      </div>
    </div>
    <div
      v-if="curveInfoList.length"
      class="curve-container"
    >
      <div class="info-block__content curve-container__left">
        <div class="group-title">
          {{ $t(`modelPerformance.${curveInfoList[0]}`) }}
          <el-popover
            :offset="-60"
            width="548"
            trigger="hover"
            placement="bottom-start"
            popper-class="el-popover--training-reminder"
          >
            <svg-icon
              slot="reference"
              style="color: #A7A7A7"
              icon-class="description"
            />
            <metric-content
              :metrics-type="trainingType"
              :display-metric="curveInfoList[0]"
            />
          </el-popover>
        </div>
        <model-task
          :component-id="curveInfo[curveInfoList[0]]"
          :is-smooth="true"
          :is-show-coefficients="true"
          :is-show-formula="false"
          :is-show-toolbox="false"
          height="260px"
        />
      </div>
      <div class="info-block__content curve-container__right">
        <div class="group-title">
          {{ $t(`modelPerformance.${curveInfoList[1]}`) }}
          <el-popover
            :offset="-60"
            width="548"
            trigger="hover"
            placement="bottom-start"
            popper-class="el-popover--training-reminder"
          >
            <svg-icon
              slot="reference"
              style="color: #A7A7A7"
              icon-class="description"
            />
            <metric-content
              :metrics-type="trainingType"
              :display-metric="curveInfoList[1]"
            />
          </el-popover>
        </div>
        <model-task
          :component-id="curveInfo[curveInfoList[1]]"
          :is-smooth="true"
          :is-show-coefficients="true"
          :is-show-toolbox="false"
          height="260px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MetricContent from '../../modelTraining/components/MetricContent'

export default {
  name: 'ModelPerformance',
  components: {
    MetricContent
  },
  props: {
    trainingType: {
      type: String,
      required: true
    },
    curveInfo: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    curveInfoList () {
      return Object.keys(this.curveInfo)
    }
  }
}
</script>

<style lang="scss" scoped>
.model-performance {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto 1fr;
  grid-gap: 16px;
  margin-bottom: 16px;
  .description-content {
    position: relative;
    left: -8px;
    top: 12px;
  }
  .tooltip {
    padding: 12px;
    width: 200px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    white-space: normal;
    color: #DDDDDD;
  }
  &__title {
    grid-area: 1 / 1 / 2 / 3;
  }
  &__metrics {
    grid-area: 2 / 1 / 3 / 2;
  }
  &__chart {
    grid-area: 2 / 2 / 3 / 3;
  }
  .confusion-matrix {
    margin-top: 16px;
  }
}

.curve-container {
  display: flex;

  &__left {
    flex: 1 1;
    margin-right: 16px;
  }
  &__right {
    flex: 1 1;
  }
}
</style>
