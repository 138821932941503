<template>
  <div class="display-line-chart">
    <v-echart
      :style="chartStyle"
      :option="options"
      auto-resize
    />
  </div>
</template>

<script>
import { chartOptions } from '@/components/display/common/chart-addon.js'

export default {
  name: 'DisplayDistributionLineChart',
  props: {
    dataset: {
      type: Object,
      default: () => ({})
    },
    title: {
      type: Object,
      default: () => {
        return {
          xAxis: null,
          yAxis: null
        }
      }
    },
    height: {
      type: String,
      default: '420px'
    },
    isShowToolbox: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      lineChartPointAmount: 130,
      minimal: null,
      maximal: null
    }
  },
  computed: {
    chartStyle () {
      return {
        width: '100%',
        height: this.height
      }
    },
    // 折線對應軸刻度
    lineChartAxisTick () {
      const xAxisMin = this.dataset.rangeMin
      const xAxisMax = this.dataset.rangeMax
      const getSingleAxisTick = (currentIndex) => {
        return xAxisMin + currentIndex * ((xAxisMax - xAxisMin) / this.lineChartPointAmount)
      }
      const axisTickList = [...Array(this.lineChartPointAmount).keys()].map(getSingleAxisTick)
      return [
        // 依照給定要畫的點數量依序產生的 x 軸 index
        ...axisTickList,
        // 適時在最後補值來確保 x 軸最後一個值對齊資料最大值
        ...((axisTickList[axisTickList.length - 1] !== xAxisMax) && [xAxisMax])
      ]
    },

    options () {
      let chartAddon = JSON.parse(JSON.stringify(chartOptions()))
      if (this.$store.state.setting.locale === 'en-US') {
        chartAddon.legend.itemGap = 40
        chartAddon.legend.selectorButtonGap = 30
      }
      chartAddon.toolbox.show = this.isShowToolbox

      const max = this.roundNumber(this.dataset.rangeMax)
      const min = this.roundNumber(this.dataset.rangeMin)
      let interval = this.roundNumber(this.floatSub(max, min) / 15)

      chartAddon.color = ['#FF9559', '#C64DFF']

      // set Axis
      chartAddon.xAxis.type = 'value'
      chartAddon.xAxis.max = max
      chartAddon.xAxis.min = min
      chartAddon.xAxis.interval = interval
      chartAddon.xAxis.axisLabel.formatter = this.roundNumber
      chartAddon.xAxis.name = this.title.xAxis[0].display_name

      chartAddon.yAxis.name = this.title.yAxis[0].display_name

      const actialAvg = this.dataset.avg[0]
      const predictedAvg = this.dataset.avg[1]
      const actialSigma = this.dataset.stddev[0]
      const predictSigma = this.dataset.stddev[1]

      const actialChartData = this.getChartDatas(actialAvg, actialSigma)
      const predictChartData = this.getChartDatas(predictedAvg, predictSigma)
      const concatData = [...actialChartData, ...predictChartData]
      this.getBoundaryValue(concatData)

      // data min跟max都太小y軸會縮成一條線 這時候再給最大最小
      if (this.minimal < Math.pow(10, -10) && this.maximal < Math.pow(10, -10)) {
        chartAddon.yAxis.min = this.minimal
        chartAddon.yAxis.max = this.maximal
      }

      chartAddon.series.push(
        {
          type: 'line',
          name: this.$t('chart.predictNormalDistribution'),
          smooth: this.dataset.smooth,
          symbol: 'none',
          data: actialChartData,
          tooltip: {
            show: false
          },
          lineStyle: {
            type: this.dataset.actualLineTypes.toLowerCase()
          }
        },
        {
          type: 'line',
          name: this.$t('chart.predictTrainingNormalDistribution'),
          smooth: this.dataset.smooth,
          symbol: 'none',
          data: predictChartData,
          tooltip: {
            show: false
          },
          lineStyle: {
            type: this.dataset.predictedLineTypes.toLowerCase()
          }
        }
      )

      return { ...chartAddon }
    }
  },
  methods: {
    calculateProbability (mean, sigma, xAxisIndex) {
      return [xAxisIndex, (1 / (Math.sqrt(2 * Math.PI) * sigma)) * Math.exp(-1 * (Math.pow((xAxisIndex - mean), 2) / (2 * Math.pow(sigma, 2))))]
    },
    getChartDatas (avg, sigma) {
      return this.lineChartAxisTick.map(tick => this.calculateProbability(avg, sigma, tick))
    },
    getBoundaryValue (data) {
      let min = data[0][1]
      let max = data[0][1]
      data.forEach((item) => {
        if (item[1] < min) {
          min = item[1]
        }
        if (item[1] > max) {
          max = item[1]
        }
      })
      this.minimal = min
      this.maximal = max
    }
  }
}
</script>
