import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import i18n from '@/lang/index.js'
import { DEFAULT_JOB_TABLE_COLUMN } from '@/schedule/utils/enum'

export default {
  namespaced: true,
  state: {
    scheduleProjects: [],
    scheduleProjectId: null,
    defaultSetting: null,
    equipments: null,
    isShowScheduleHelper: false,
    globalJobStatusRestriction: '',
    refErrorResultSizeLimit: 100, // 檢查資料時，預設向後端取的最多 refErrorValue 數量
    // 紀錄客製化設定資訊，spec 討論中
    profile: {
      isDefault: true
    },
    dynamicJobTableColumns: [
      // 預設的工單表格欄位，之後會改為讀取 Profile 提供的欄位
      ...Object.values(DEFAULT_JOB_TABLE_COLUMN).map(column => ({
        title: column,
        name: i18n.t(`schedule.simulation.table.${column.toLocaleLowerCase()}`)
      }))
    ],
    hasCurrentPlan: false
  },
  actions,
  mutations,
  getters
}
