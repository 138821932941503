<template>
  <div
    v-show="isTaskComplete"
    class="recommended-insights__card"
    @click="learnMore()"
  >
    <div class="card__header">
      <div class="header__title">
        {{ question }}
      </div>
    </div>
    <div class="card__body">
      <task
        :key="componentId"
        :component-id="componentId"
        :is-show-toolbox="false"
        :custom-chart-style="{ width: '100%', height: '240px' }"
        :arrow-btn-right="20"
        intend="recommended_insight"
        @setQuestion="setQuestion"
        @failed="setTaskStatus(false)"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { useAskingModuleContext } from '@/modules/shared/asking'

export default defineComponent({
  name: 'RecommendedInsight',
  props: {
    componentId: {
      type: Number,
      default: null
    }
  },
  setup () {
    const { setAppQuestion, updateResultRouter } = useAskingModuleContext()
    return {
      setAppQuestion,
      updateResultRouter
    }
  },
  data () {
    return {
      question: '',
      isTaskComplete: true
    }
  },
  methods: {
    learnMore () {
      this.setAppQuestion(this.question)
      this.updateResultRouter('recommend_insight')
    },
    setQuestion (question) {
      this.question = question
    },
    setTaskStatus (value) {
      this.isTaskComplete = value
    }
  }
})
</script>

<style lang="scss" scoped>
.recommended-insights__card {
  @include card();

  background: rgba(35, 61, 64, 0.6);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  flex-basis: calc(50% - 10px);
  height: 100%;
  height: auto;
  overflow: hidden;
  width: 0; // 讓卡片能響應式縮小

  &:first-child,
  &:nth-child(2) {
    margin-bottom: 20px;
  }

  .card {
    &__header {
      align-items: center;
      background: rgba(50, 75, 78, 0.6);
      display: flex;
      justify-content: space-between;
      padding: 14px 20px;
    }

    &__body {
      padding: 20px;

      .task {
        pointer-events: none;
      }
    }
  }
}
</style>
