import request from '@/schedule/utils/publicRequest.js'

/**
 * 取得後台設定
 * @param {Number} projectId - 子專案 ID
 */
export function getBaseSetting (projectId) {
  return request({
    url: '/setting/info',
    method: 'GET',
    params: {
      projectId
    }
  })
}

/**
 * 更新後台設定
 *  @param {Object} data - 設定資訊
 */
export function setBaseSetting (data) {
  return request({
    url: '/setting/info',
    method: 'PUT',
    data
  })
}

/**
 * 取得子專案拆併單設定
 * @param {Number} projectId - 子專案 ID
 */
export function getSplitMergeSetting (projectId) {
  return request({
    url: '/setting/splitMerge',
    method: 'GET',
    params: {
      projectId
    }
  })
}

/**
 * 更新子專案拆併單設定
 * @param {Object} data - 設定資訊
 */
export function setSplitMergeSetting (data) {
  return request({
    url: '/setting/splitMerge',
    method: 'PUT',
    data
  })
}

/**
 * 取得子專案下排除拆併產品清單
 * @param {Number} projectId - 子專案 ID
 */
export function getProductSplitMergeBlacklist (projectId) {
  return request({
    url: '/setting/splitMerge/product/blacklist',
    method: 'GET',
    params: {
      projectId
    }
  })
}

/**
 * 新增子專案下排除拆併產品清單
 * @param {Number} data.projectId - 子專案 ID
 * @param {String} data.productName - 產品名稱
 */
export function addProductToSplitMergeBlacklist (data) {
  return request({
    url: '/setting/splitMerge/product/blacklist',
    method: 'POST',
    data
  })
}

/**
 * 刪除子專案下排除拆併產品清單
 * @param {Number} data.projectId - 子專案 ID
 * @param {String} data.productName - 產品名稱
 */
export function deleteProductFromSplitMergeBlacklist (data) {
  return request({
    url: '/setting/splitMerge/product/blacklist',
    method: 'DELETE',
    data
  })
}

/**
 * 取得子專案下by工序拆併單條件清單
 * @param {Number} projectId - 子專案 ID
 */
export function getOperationSplitMergeRules (projectId, operation = null) {
  return request({
    url: '/setting/splitMerge/operation',
    method: 'GET',
    params: {
      projectId,
      operation
    }
  })
}

/**
 * 新增或更新by工序拆併單條件清單
 * @param {String} data.operationName - 工序名稱
 * @param {Number} data.operationSplitMergeId - 工序拆併條件 id（有帶為更新、沒帶為新增）
 * @param {Number} data.quantityMergeThreshold - 工序 by數量 合併閥值
 * @param {Number} data.quantitySplitThreshold - 工序 by數量 拆單閥值
 * @param {Number} data.timeMergeThreshold - 工序 by時間 合併閥值
 * @param {Number} data.timeSplitThreshold - 工序 by時間 拆單閥值
 */
export function setOperationSplitMergeRule (data) {
  return request({
    url: '/setting/splitMerge/operation',
    method: 'POST',
    data
  })
}

/**
 * 刪除工序拆併條件by工序拆併單條件id
 * @param {Number} ruleId - 工序拆併單條件id
 */
export function deleteOperationSplitMergeRule (ruleId) {
  return request({
    url: `/setting/splitMerge/operation/${ruleId}`,
    method: 'DELETE'
  })
}

export function getUploadFileList (projectId) {
  return request({
    url: '/uploadfile/list',
    method: 'GET',
    params: {
      projectId
    }
  })
}

export function downloadCurrentSetting (fileId) {
  return request({
    url: `/uploadfile/download/${fileId}`,
    method: 'GET'
  })
}

export function uploadOrder (formData, cancelFunction) {
  return request({
    url: '/uploadfile/upload/rawdata/order',
    method: 'POST',
    data: formData,
    cancelToken: cancelFunction
  })
}

export function getYKFactorySiteUpdatable (projectId) {
  return request({
    url: '/setting/factorySite/updatable',
    method: 'GET',
    params: {
      projectId
    }
  })
}
