export default (data) => {
  const returnData = JSON.parse(JSON.stringify(data))

  // 增加 custom setting 設定
  returnData.settings.editModeData.dashboards.forEach((dashboard) => {
    dashboard.components.forEach((component) => {
      if (['parallel_bar_chart', 'bar_chart', 'line_chart'].includes(component.diagram)) {
        component.displayConfig.isStack = false
      }
    })
  })

  return returnData
}
