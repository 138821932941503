import getters from './getters'
import mutations from './mutations'
import actions from './actions'

import { ASKING_MODULE_NAMESPACE, askingModuleStore } from '@/modules/shared/asking'

export const dataManagementModuleStore = {
  namespaced: true,
  modules: {
    [ASKING_MODULE_NAMESPACE]: askingModuleStore
  },
  state () {},
  getters,
  mutations,
  actions
}
