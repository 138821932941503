import Vue from 'vue'
import VeeValidate, { Validator } from 'vee-validate'
import moment from 'moment'
import validateRules from '@/utils/validateRules.js'

/** Custom Rule */
Validator.extend('requireOneNumeric', function (value) {
  return /.*[0-9].*/i.test(value)
})

Validator.extend('positiveInt', function (value) {
  return /^[1-9]+[0-9]*$/i.test(value)
})

Validator.extend('letterSpace', function (value) {
  // 含簡繁體、英文、注音符號、四聲、空格
  return /^[\u4e00-\u9fa5_a-zA-Z0-9\u3105-\u3129\u02CA\u02C7\u02CB\u02D9\s]*$/i.test(value) && !Number(value)
})

Validator.extend('letterDashUnderscore', function (value) {
  // 含繁簡體、英文、數字及 '-', '_'
  return /^[\u4e00-\u9fa5_a-zA-Z0-9\u002D\u005F\s]*$/i.test(value)
})

Validator.extend('validUpperBound', (upperBoundValue, [lowerBoundValue]) => {
  return Number(upperBoundValue) > Number(lowerBoundValue)
}, {
  hasTarget: true
})

Validator.extend('validLowerBound', (lowerBoundValue, [upperBoundValue]) => {
  return Number(lowerBoundValue) < Number(upperBoundValue)
}, {
  hasTarget: true
})

Validator.extend('validDatetimeUpperBound', (upperBoundValue, [lowerBoundValue]) => {
  const getTimestamp = (time) => {
    const ISOTime = new Date(time).toISOString()
    return moment(ISOTime).format('x')
  }
  return Number(getTimestamp(upperBoundValue)) > Number(getTimestamp(lowerBoundValue))
}, {
  hasTarget: true
})

Validator.extend('validDatetimeLowerBound', (lowerBoundValue, [upperBoundValue]) => {
  const getTimestamp = (time) => {
    const ISOTime = new Date(time).toISOString()
    return moment(ISOTime).format('x')
  }
  return Number(getTimestamp(lowerBoundValue)) < Number(getTimestamp(upperBoundValue))
}, {
  hasTarget: true
})

Validator.extend('eitherOneIsRequired', (value, [otherValue]) => {
  return {
    valid: `${value}`.length > 0 || `${otherValue}`.length > 0,
    data: {
      required: true
    }
  }
}, {
  hasTarget: true,
  computesRequired: true
})

Vue.use(VeeValidate, {
  // 避免自動 inject 到所有 component
  inject: false,
  // 語系
  locale: 'zh_TW',
  // 驗證字串
  dictionary: {
    zh_TW: {
      messages: validateRules
    },
    zh_CN: {
      messages: validateRules
    },
    vi: {
      messages: validateRules
    }
  }
})
