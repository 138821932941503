import { render, staticRenderFns } from "./QuestionNameTokenV2.vue?vue&type=template&id=3603b607&scoped=true&"
import script from "./QuestionNameTokenV2.vue?vue&type=script&lang=js&"
export * from "./QuestionNameTokenV2.vue?vue&type=script&lang=js&"
import style0 from "./QuestionNameTokenV2.vue?vue&type=style&index=0&id=3603b607&lang=scss&scoped=true&"
import style1 from "./QuestionNameTokenV2.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3603b607",
  null
  
)

export default component.exports