import { computed, provide, inject } from '@vue/composition-api'
import { mapState, mapGetters, mapActions, mapMutations, createNamespacedHelpers } from 'vuex'
import store from '@/store'

function mappingComputedWrapper (mapComputed) {
  return function (...args) {
    const getters = mapComputed(...args)
    const result = Object.fromEntries(
      Object.entries(getters)
        .map(([key, value]) => [key, computed(value)])
    )
    return result
  }
}

function mappingFunctionWrapper (mapFunction) {
  return function (...args) {
    const functions = mapFunction(...args)
    const result = Object.fromEntries(
      Object.entries(functions)
        .map(([key, value]) => [key, value.bind({ $store: store })])
    )
    return result
  }
}

/**
 * @typedef {import('@vue/composition-api').ComputedRef} ComputedRef
 * @typedef {import('vuex').Mapper<ComputedRef>} ComputedRefMapper
 * @typedef {import('vuex').MapperWithNamespace<ComputedRef>} ComputedRefMapperWithNamespace
 * @typedef {import('vuex').MapperForState} MapperForState
 * @typedef {import('vuex').MapperForStateWithNamespace} MapperForStateWithNamespace
 * @typedef {import('vuex').MutationMethod} MutationMethod
 * @typedef {import('vuex').Mapper<MutationMethod>} MutationMethodMapper
 * @typedef {import('vuex').MapperWithNamespace<MutationMethod>} MutationMethodMapperWithNamespace
 * @typedef {import('vuex').MapperForMutation} MapperForMutation
 * @typedef {import('vuex').MapperForMutationWithNamespace} MapperForMutationWithNamespace
 * @typedef {import('vuex').ActionMethod} ActionMethod
 * @typedef {import('vuex').Mapper<ActionMethod>} ActionMethodMapper
 * @typedef {import('vuex').MapperWithNamespace<ActionMethod>} ActionMethodMapperWithNamespace
 * @typedef {import('vuex').MapperForAction} MapperForAction
 * @typedef {import('vuex').MapperForActionWithNamespace} MapperForActionWithNamespace
 */

function createComposableMethods ({ mapState, mapGetters, mapMutations, mapActions }) {
  /** @type {ComputedRefMapper & ComputedRefMapperWithNamespace & MapperForState & MapperForStateWithNamespace} */
  const useMapState = mappingComputedWrapper(mapState)
  /** @type {ComputedRefMapper & ComputedRefMapperWithNamespace} */
  const useMapGetters = mappingComputedWrapper(mapGetters)
  /** @type {MutationMethodMapper & MutationMethodMapperWithNamespace & MapperForMutation & MapperForMutationWithNamespace} */
  const useMapMutations = mappingFunctionWrapper(mapMutations)
  /** @type {ActionMethodMapper & ActionMethodMapperWithNamespace & MapperForAction & MapperForActionWithNamespace} */
  const useMapActions = mappingFunctionWrapper(mapActions)

  return {
    useMapState,
    useMapGetters,
    useMapMutations,
    useMapActions
  }
}

export const {
  useMapState,
  useMapGetters,
  useMapMutations,
  useMapActions
} = createComposableMethods({
  mapState,
  mapGetters,
  mapMutations,
  mapActions
})

export function useNamespacedHelpers (namespace) {
  return createComposableMethods(
    createNamespacedHelpers(namespace)
  )
}

/**
 * @typedef {import('vuex').Module} Module
 * @typedef {import('vuex').GetterTree} GetterTree
 * @typedef {import('vuex').ActionTree} ActionTree
 */

/**
 * @template M
 * @typedef {M['state'] extends Function ? keyof ReturnType<M['state']> : keyof M['state']} StateKeys
 */

/**
 * @template M
 * @typedef {keyof M['getters']} GettersKeys
 */

/**
 * @template M
 * @typedef {keyof M['actions']} ActionsKeys
 */

/**
 * @template M
 * @typedef {Record<StateKeys<M>, ComputedRef>} MappedState
 */

/**
 * @template M
 * @typedef {Record<GettersKeys<M>, ComputedRef>} MappedGetters
 */

/**
 * @template M
 * @typedef {Record<ActionsKeys<M>, ActionMethod>} MappedActions
 */

/**
 * @template M
 * @typedef {MappedState<M> & MappedGetters<M> & MappedActions<M>} MappedResult
 */

/** @type {<M extends Module>(namespace?: string, module: M) => () => MappedResult<M>} */
export const createUseStore = (namespace = '', module) => () => ({
  ...useMapState(
    namespace,
    Object.keys(
      typeof module.state === 'function'
        ? module.state()
        : module.state
    )
  ),
  ...useMapGetters(namespace, Object.keys(module.getters)),
  ...useMapActions(namespace, Object.keys(module.actions))
})

const INJECTION_KEY = Symbol('vuex-store')
export function provideVuexStore () {
  provide(INJECTION_KEY, store)
}
export function injectVuexStore () {
  const store = inject(INJECTION_KEY) ?? null
  if (store === null) {
    throw new Error('Can not inject vuex-store before provided it.')
  }
  return store
}
