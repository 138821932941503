<template>
  <div
    :style="cssProps"
    class="confusion-metrix"
  >
    <div class="confusion-metrix__title">
      <div>{{ $t('model.confusionMatrix') }}</div>
    </div>
    <div class="confusion-metrix__predict">
      <div>{{ $t('chart.forecastValue') }}</div>
    </div>
    <div class="confusion-metrix__label">
      <div>{{ `${positiveName}(Positive)` }}</div>
    </div>
    <div class="confusion-metrix__label">
      <div>{{ `${negativeName}(Negative)` }}</div>
    </div>
    <div class="confusion-metrix__label">
      <div>{{ $t('aggregation.count') }}</div>
    </div>

    <div class="confusion-metrix__actial">
      <div>{{ $t('chart.actialValue') }}</div>
    </div>

    <template v-for="(data, index) in matrixData">
      <div
        :key="`title-${index}`"
        class="confusion-metrix__label"
      >
        <div>{{ data.label }}</div>
      </div>
      <div
        :key="`positive-${index}`"
        class="confusion-metrix__value"
      >
        <div>{{ formatComma(data.v1) }}</div>
      </div>
      <div
        :key="`negative-${index}`"
        class="confusion-metrix__value"
      >
        <div>{{ formatComma(data.v2) }}</div>
      </div>
      <div
        :key="`total-${index}`"
        class="confusion-metrix__value"
      >
        <div>{{ formatComma(data.v3) }}</div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'DisplayConfusionMatrix',
  props: {
    info: {
      type: Object,
      required: true
    },
    height: {
      type: Number,
      default: 300
    },
    fontSize: {
      type: Number,
      default: 24
    }
  },
  data () {
    return {
      positiveName: '',
      negativeName: '',
      matrixData: null
    }
  },
  computed: {
    cssProps () {
      return {
        '--height': `${this.height}px`,
        '--font-size': `${this.fontSize}px`
      }
    }
  },
  mounted () {
    this.formateMatrix(this.info.confusionMatrix)
  },
  methods: {
    formateMatrix (matrix) {
      let TP, FP, FN, TN
      this.positiveName = matrix.POSITIVE_NAME
      this.negativeName = matrix.NEGATIVE_NAME
      TP = Number(matrix.TRUE_POSITIVE)
      FP = Number(matrix.FALSE_POSITIVE)
      FN = Number(matrix.FALSE_NEGATIVE)
      TN = Number(matrix.TRUE_NEGATIVE)

      this.matrixData = [
        {
          label: `${this.positiveName}\n(Positive)`,
          v1: TP,
          v2: FN,
          v3: TP + FN
        }, {
          label: `${this.negativeName}\n(Negative)`,
          v1: FP,
          v2: TN,
          v3: FP + TN
        }, {
          label: this.$t('aggregation.count'),
          v1: TP + FP,
          v2: FN + TN,
          v3: TP + FN + FP + TN
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.confusion-metrix {
  width: 100%;
  height: var(--height);
  margin-right: 6px;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  white-space: pre-wrap;
  background: #192323;
  display: grid;
  grid-template-columns: minmax(80px, 1fr) minmax(112px, 2fr) repeat(2, 3fr) 2fr;
  grid-template-rows: repeat(2, minmax(24px, 1fr)) repeat(3, 2fr);
  &__title {
    grid-area: 1 / 1 / 3 / 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #2B4D51;
    border: 1px solid #52696A;
  }
  &__predict {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-area: 1 / 3 / 2 / 6;
    background: rgba(50, 75, 78, 0.6);
    border: 1px solid #52696A;
  }
  &__actial {
    grid-area: 3 / 1 / 6 / 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: rgba(50, 75, 78, 0.6);
    border: 1px solid #52696A;
  }
  &__label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: rgba(35, 61, 64, 0.6);
    border: 1px solid #52696A;
  }
  &__value {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: var(--font-size);
    font-weight: 400;
    color: #DDDDDD;
    border: 1px solid #52696A;
  }
}
</style>
