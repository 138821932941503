import { registerAskingModuleContext } from '@/modules/shared/asking'
import { AUTO_ML_MODULE_NAMESPACE } from '@/modules/namespace'
import { defineContext } from '@/utils/composable/context'

export { AUTO_ML_MODULE_NAMESPACE } from '@/modules/namespace'
export { autoMLModuleStore } from './store'

export const {
  registerContext: registerAutoMLModuleContext,
  useContext: useAutoMLModuleContext
} = defineContext({
  namespace: AUTO_ML_MODULE_NAMESPACE,
  createContext () {
    const askingModuleContext = registerAskingModuleContext({
      extendNamespace: AUTO_ML_MODULE_NAMESPACE
    })
    return {
      askingModuleContext
    }
  }
})
