import { getMiniAppList } from '@/API/MiniApp'
import { MiniAppStatus } from '../utils'
import { Message } from 'element-ui'
import i18n from '@/lang/index.js'

export default {
  async fetchMiniAppList ({ commit, dispatch, getters, rootGetters }) {
    const oldProcessingAppIdList = getters.processingAppIdList

    const groupId = rootGetters['userManagement/getCurrentGroupId']
    const miniAppList = await getMiniAppList(groupId)
    commit('SET_MINI_APP_LIST', miniAppList)

    const appMap = getters.appMap
    const newProcessingAppIdList = getters.processingAppIdList
    const removedList = [...new Set([
      ...oldProcessingAppIdList.filter((id) => !newProcessingAppIdList.includes(id)),
      ...getters.pendingAppIdList
    ])]

    const typeMap = getters.pendingAppTypeMap
    removedList.forEach((id) => {
      if (!(id in appMap)) return
      const app = appMap[id]

      if (app.status === MiniAppStatus.Process) return

      const pendingType = typeMap[id] ?? 'default'
      const showMessage = {
        [MiniAppStatus.Enable] () {
          Message({
            message: {
              default: i18n.t('miniApp.notificationMessage.buildSuccessfully', { appName: app.name }),
              import: i18n.t('miniApp.notificationMessage.importSuccessfully', { appName: app.name }),
              copy: i18n.t('miniApp.notificationMessage.copySuccessfully', { appName: app.name }),
              update: i18n.t('miniApp.notificationMessage.updateSuccessfully', { appName: app.name })
            }[pendingType],
            type: 'success',
            duration: 3 * 1000,
            showClose: true
          })
        },
        [MiniAppStatus.Fail] () {
          Message({
            message: {
              default: i18n.t('miniApp.notificationMessage.buildFailed', { appName: app.name }),
              import: i18n.t('miniApp.notificationMessage.importFailed', { appName: app.name }),
              copy: i18n.t('miniApp.notificationMessage.copyFailed', { appName: app.name }),
              update: i18n.t('miniApp.notificationMessage.updateFailed', { appName: app.name })
            }[pendingType],
            type: 'error',
            duration: 3 * 1000,
            showClose: true
          })
        }
      }[app.status] ?? (() => {})
      showMessage()
      commit('REMOVE_PENDING_APP_INFO_FROM_LIST_BY_ID', id)
    })

    if (newProcessingAppIdList.length === 0) {
      dispatch('stopPolling')
    } else {
      dispatch('startPolling')
    }
  },
  startPolling ({ state, commit, dispatch }) {
    if (state.pollingTimeoutId !== -1) return
    const period = 5000
    const execute = async () => {
      await dispatch('fetchMiniAppList')
      if (state.pollingTimeoutId === -1) return
      commit('SET_POLLING_TIMEOUT_ID', setTimeout(execute, period))
    }
    commit('SET_POLLING_TIMEOUT_ID', setTimeout(execute, period))
  },
  stopPolling ({ state, commit }) {
    clearTimeout(state.pollingTimeoutId)
    commit('SET_POLLING_TIMEOUT_ID', -1)
  },
  async init ({ state, commit, dispatch }) {
    if (state.isInitialized) return
    // TEMP: 目前沒有需要在一進入 App 就檢查是否有處理中的 MiniApp
    // await dispatch('fetchMiniAppList')
    commit('SET_IS_INITIALIZED', true)
  }
}
