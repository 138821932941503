import {
  getDataSourceList,
  getDataSourceColumnInfoById,
  getDataSourceDataValueById,
  getDataFrameById,
  getDataFrameData,
  dataFrameColumnSummary,
  getColumnCorrelationMatrix,
  triggerColumnDataCalculation
} from '@/API/DataSource'
import router from '@/router'
import { Message } from 'element-ui'
import i18n from '@/lang/index.js'
import axios from 'axios'
import { ASKING_MODULE_NAMESPACE } from '@/modules/shared/asking'

const CancelToken = axios.CancelToken
let popupCancelFunction
let displayCancelFunction

export default {
  async init ({ commit, dispatch, state, getters, rootGetters }) {
    if (state.isInit) return
    let queryDataSource = parseInt(router.app.$route.query.dataSourceId)
    let queryDataFrame = router.app.$route.query.dataFrameId
    const currentGroupId = rootGetters['userManagement/getCurrentGroupId']
    if (currentGroupId) {
      await dispatch('getDataSourceList', {
        dataSourceId: queryDataSource,
        dataFrameId: queryDataFrame === 'all' ? 'all' : parseInt(queryDataFrame)
      })
    }
    commit('setIsInit', true)
  },
  async getDataSourceList ({ dispatch, commit, state, rootGetters }, { dataSourceId, dataFrameId }) {
    const res = await getDataSourceList()
    commit('setDataSourceList', res)
    // 找出第一個可以使用的 dataSourceId
    let firstEnableDataSourceIndex = res.findIndex(element => element.enableDataFrameCount)
    if (dataSourceId) {
      // 判斷路由的 DataSource 是否存在，且該 DataSource 是有可使用的 DataFrame
      if (res.some(element => element.id === dataSourceId && element.enableDataFrameCount)) {
        try {
          await dispatch('changeDataSourceById', { dataSourceId, dataFrameId })
        } catch (err) {
          console.log(err)
        }
        return
      }

      dataSourceId = firstEnableDataSourceIndex > -1 ? res[firstEnableDataSourceIndex].id : null
      try {
        await dispatch('changeDataSourceById', { dataSourceId, dataFrameId: 'all' })
      } catch (err) {
        console.log(err)
      }
      if (firstEnableDataSourceIndex < 0) await dispatch('handleEmptyDataSource')
      const currentGroupId = rootGetters['userManagement/getCurrentGroupId']
      router.push({
        name: 'PageIndex',
        params: {
          group_id: currentGroupId
        },
        query: {
          ...(dataSourceId && {
            dataSourceId: dataSourceId,
            dataFrameId: 'all'
          })
        }
      })

      Message({
        message: i18n.t('message.dataSourceNotExist'),
        type: 'success',
        duration: 3 * 1000,
        showClose: true
      })
    } else {
      if (firstEnableDataSourceIndex < 0) {
        await dispatch('handleEmptyDataSource')
      } else if (!state.dataSourceId || res.findIndex(element => element.id === state.dataSourceId) < 0) {
        // 如果沒有 dataSourceId 或是 dataSourceId 被刪掉了，就設第一個可使用的 dataSource
        try {
          await dispatch('changeDataSourceById', { dataSourceId: res[firstEnableDataSourceIndex].id, dataFrameId: 'all' })
        } catch (err) {
          console.log(err)
        }
      }
    }
  },
  async changeDataSourceById ({ dispatch, commit, state, rootGetters }, { dataSourceId, dataFrameId }) {
    if (state.dataSourceId === dataSourceId && state.dataFrameId === dataFrameId) return

    // 清空對話紀錄
    if (state.dataSourceId) dispatch('clearChatbot')
    // 更新 DataSource 資料
    commit('setDataSourceId', dataSourceId)
    dispatch('dispatchAskingModule', { action: 'setIsInit', data: false })
    dispatch('dispatchAskingModule', { action: 'clearColumnList', data: null })

    // 避免切換 dataSource 但 dataFrame 皆為 all 沒有觸發到 dataFrame id 變化
    commit('setDataFrameId', null)

    if (!dataSourceId || !state.dataSourceList.some(elm => elm.id === dataSourceId)) {
      let firstEnableDataSourceIndex = state.dataSourceList.findIndex(element => element.enableDataFrameCount)

      const returnSourceId = firstEnableDataSourceIndex > -1 ? state.dataSourceList[firstEnableDataSourceIndex].id : null
      commit('setDataSourceId', returnSourceId)
      commit('setDataFrameId', 'all')

      let err = new Error('DataSource is not exist')
      err.dataSourceId = returnSourceId
      err.dataFrameId = 'all'
      throw err
    }

    // 更新 DataFrame 資料
    const dataFrameList = await dispatch('getDataSourceTables')
    commit('setDataFrameList', dataFrameList)

    // 當指定 DataFrame 時，確認是否存在
    if (dataFrameId !== 'all' && !dataFrameList.some(element => element.id === dataFrameId)) {
      dispatch('changeDataFrameById', 'all')

      Message({
        message: i18n.t('message.dataFrameNotExist'),
        type: 'success',
        duration: 3 * 1000,
        showClose: true
      })

      commit('setDataSourceId', dataSourceId)
      commit('setDataFrameId', 'all')
      let err = new Error('DataSource or DataFrame not exist')
      err.dataSourceId = dataSourceId
      err.dataFrameId = 'all'
      throw err
    }

    return dispatch('changeDataFrameById', dataFrameId)
  },
  async changeDataFrameById ({ dispatch, commit, state }, dataFrameId) {
    if (state.dataFrameId === dataFrameId) return Promise.resolve(state)
    await dispatch('clearChatbot')
    dispatch('dispatchAskingModule', { action: 'setIsInit', data: true })
    dispatch('dispatchAskingModule', { action: 'clearColumnList', data: null })
    dispatch('dispatchAskingModule', { action: 'setShouldAdvanceDataFrameSettingRefetchDataColumn', data: true })

    // 更新 DataFrame 資料
    commit('setDataFrameId', dataFrameId)
    await Promise.all([
      dispatch('dispatchAskingModule', { action: 'getHistoryQuestionList', data: null }),
      dispatch('getDataSourceColumnInfo'),
      dispatch('getDataSourceDataValue')
    ])
  },
  async clearChatbot ({ dispatch }) {
    // 清空篩選條件
    dispatch('dispatchAskingModule', { action: 'clearFilterList', data: null })
    // 清除 question id
    dispatch('dispatchAskingModule', { action: 'clearCurrentQuestionId', data: null })
    // 關閉演算法
    dispatch('dispatchAskingModule', { action: 'setIsUseAlgorithm', data: false })
  },
  async handleEmptyDataSource ({ dispatch, commit }) {
    dispatch('clearChatbot')
    commit('setDataSourceId', null)
    commit('setDataFrameList', [])
    commit('setDataFrameId', null)
  },
  async getDataSourceTables ({ state }) {
    if (state.dataSourceId === null) return []
    return await getDataFrameById(state.dataSourceId)
  },
  async getDataFrameColumnSummary ({ state }, { id, selectedColumnList = null, restrictions, page, cancelToken }) {
    if (page > 0) return
    return await dataFrameColumnSummary(id, selectedColumnList, restrictions, cancelToken)
  },
  async getDataFrameData ({ state }, { id, selectedColumnList = null, restrictions, page = 0, cancelToken, sortOrders = null }) {
    return await getDataFrameData(id, selectedColumnList, restrictions, page, cancelToken, sortOrders)
  },
  async getDataFrameIntro ({ dispatch, state, getters, rootGetters }, { id, page, mode, isOnlyFetchSummary, sortOrders, shouldCancelToken }) {
    shouldCancelToken && await dispatch('cancelRequest', mode)
    let selectedColumnList = null
    let restrictions = []
    const cancelToken = new CancelToken(function executor (c) {
      // An executor function receives a cancel function as a parameter
      if (mode === 'popup') popupCancelFunction = c
      if (mode === 'display') {
        displayCancelFunction = c
        selectedColumnList = rootGetters[`${state.currentAskingModuleBelongsTo}/${ASKING_MODULE_NAMESPACE}/selectedColumnList`]
        restrictions = rootGetters[`${state.currentAskingModuleBelongsTo}/${ASKING_MODULE_NAMESPACE}/filterRestrictionList`]
      }
    })
    // 依照條件取得部分或全部的資料表
    return await Promise.all([
      ...(!isOnlyFetchSummary ? [dispatch('getDataFrameData', { id, selectedColumnList, restrictions, page, cancelToken, sortOrders })] : []),
      dispatch('getDataFrameColumnSummary', { id, selectedColumnList, restrictions, page, cancelToken })
    ])
  },
  async getDataFrameColumnCorrelation ({ state }, { id, selectedColumnList = null, restrictions = [] }) {
    return await getColumnCorrelationMatrix(id, selectedColumnList, restrictions)
  },
  async getDataSourceColumnInfo ({ commit, state, getters, rootGetters }, { shouldStore = true } = {}) {
    if (!state.dataSourceId) return Promise.reject()
    const dataFrameId = getters.currentDataFrameId
    const columns = rootGetters[`${state.currentAskingModuleBelongsTo}/${ASKING_MODULE_NAMESPACE}/selectedColumnList`]
    const restrictions = rootGetters[`${state.currentAskingModuleBelongsTo}/${ASKING_MODULE_NAMESPACE}/filterRestrictionList`]
    const response = await getDataSourceColumnInfoById(state.dataSourceId, dataFrameId, columns, restrictions)
    return shouldStore ? commit('setDataSourceColumnInfoList', response) : response
  },
  async getDataSourceDataValue ({ commit, state, getters, rootGetters }, { shouldStore = true, size = 50 } = {}) {
    if (!state.dataSourceId) return Promise.reject()
    const dataFrameId = getters.currentDataFrameId
    const columns = rootGetters[`${state.currentAskingModuleBelongsTo}/${ASKING_MODULE_NAMESPACE}/selectedColumnList`]
    const restrictions = rootGetters[`${state.currentAskingModuleBelongsTo}/${ASKING_MODULE_NAMESPACE}/filterRestrictionList`]
    const response = await getDataSourceDataValueById(state.dataSourceId, dataFrameId, columns, restrictions, size)
    return shouldStore ? commit('setDataSourceDataValueList', response) : response
  },
  cancelRequest ({ state }, mode) {
    if (mode === 'popup' && typeof popupCancelFunction === 'function') {
      popupCancelFunction('cancel')
    }
    if (mode === 'display' && typeof displayCancelFunction === 'function') {
      displayCancelFunction('cancel')
    }
  },
  async updateDataFrameList ({ commit, state, dispatch }) {
    // 取得 dataFrame 資料
    const dataFrameList = await dispatch('getDataSourceTables')

    // 如果沒有 dataFrame 則重新 assign store 中當前的 dataSource
    if (dataFrameList.length === 0) {
      commit('setDataSourceId', null)
      return await dispatch('getDataSourceList', {})
    }

    // 更新 dataFrame list
    commit('setDataFrameList', dataFrameList)

    // 確認當前 dataFrame 是否存在
    const hasCurrentDataFrame = dataFrameList.some(element => element.id === state.dataFrameId)
    if (state.dataFrameId === 'all' || hasCurrentDataFrame) return

    // 如果 dataFrame 被刪掉則恢復預設 all
    return await dispatch('changeDataFrameById', 'all')
  },
  async triggerColumnDataCalculation ({ state, rootGetters }) {
    const restrictions = rootGetters[`${state.currentAskingModuleBelongsTo}/${ASKING_MODULE_NAMESPACE}/filterRestrictionList`]
    return await triggerColumnDataCalculation(state.dataFrameId, restrictions)
  },
  setCurrentAskingModuleBelongsTo ({ commit }, value) {
    commit('SET_CURRENT_ASKING_MODULE_BELONGS_TO', value)
  },
  dispatchAskingModule ({ dispatch, state }, { action, data }) {
    if (state.currentAskingModuleBelongsTo === null) return
    return dispatch(
      `${state.currentAskingModuleBelongsTo}/${ASKING_MODULE_NAMESPACE}/${action}`,
      data,
      { root: true }
    )
  }
}
