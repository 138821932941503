<template>
  <el-tooltip
    class="beginner-guide"
    slot="span"
    :enterable="false"
    :content="content"
    popper-class="beginner-guide__menu"
    :force-show="true"
  >
    <!-- :value="true" -->
    <svg-icon
      icon-class="guide-icon"
    />
  </el-tooltip>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.beginner-guide {
  left: 0;
  position: absolute;
  top: 0;
  transform: translate(-50%, -50%);
}
</style>

<style lang="scss">
.el-tooltip__popper.beginner-guide__menu {
  background-color: #233131 !important;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: 16px;
  width: 204px;

  &[x-placement^=bottom] .popper__arrow {
    &,
    &::after {
      border-bottom-color: #233131 !important;
    }
  }

  &[x-placement^=top] .popper__arrow {
    &,
    &::after {
      border-top-color: #233131 !important;
    }
  }

  &[x-placement^=left] .popper__arrow {
    &,
    &::after {
      border-left-color: #233131 !important;
    }
  }

  &[x-placement^=right] .popper__arrow {
    &,
    &::after {
      border-right-color: #233131 !important;
    }
  }
}
</style>
